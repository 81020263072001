import React from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import { RODialog } from '../../../common';

const DeleteGroups = (props) => {
    return (
        <RODialog
            maxWidth='sm'
            fullWidth={true}
            open={props.open}
            dialogTitle={props.title}
            cancelText='Cancel'
            confirmText='Yes'
            handleCancel={() => {
                props.handleClick('CANCEL');
            }}
            handleConfirm={() => {
                props.handleClick('DELETE');
            }}
        >
            <DialogContentText>
                Are you sure you want to delete group: <b>{props.groupTarget.Name}</b> ?
            </DialogContentText>
        </RODialog>
    );
};

export default DeleteGroups;
