import axios from 'axios';
import { axiosDefault } from '../_helpers/auth-header';
import { config } from '../_helpers/config';

export const getUsers = async () => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'Users';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const addUser = async (postData) => {
    const url = config.ROApi + 'Users';
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.post(url, postData, { headers });
};

export const updateUser = async (id, postData) => {
    const url = config.ROApi + 'Users/' + id;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.put(url, postData, { headers });
};

export const deleteUser = async (id) => {
    const url = config.ROApi + 'Users/' + id;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const getRoles = async () => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'userRoles/AssignableRoles';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getRoleAssignedFunctions = async (roleId) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + `userRoles/${roleId}/assignableFunctions/`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getRoleAssignedRoles = async (roleId) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + `userRoles/${roleId}/AssignableRoles/`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getRoleAssignedGroups = async (roleId) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + `userRoles/${roleId}/AssignableGroups/`;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getAssignableFunctions = async () => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'pagefunctions/AssignableFunctions/';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getAssignableRoles = async () => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'userRoles/AssignableRoles/';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const getAssignableGroups = async () => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'UserGroups/AssignableGroups/';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const addRole = async (postData) => {
    const url = config.ROApi + 'UserRoles';
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.post(url, postData, { headers });
};

export const updateRole = async (id, postData) => {
    const url = config.ROApi + 'UserRoles/' + id;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.put(url, postData, { headers });
};

export const deleteRole = async (id) => {
    const url = config.ROApi + 'UserRoles/' + id;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const getGroups = async () => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'usergroups/assignableGroups';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const addGroup = async (postData) => {
    const url = config.ROApi + 'UserGroups';
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.post(url, postData, { headers });
};

export const updateGroup = async (id, postData) => {
    const url = config.ROApi + 'UserGroups/' + id;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.put(url, postData, { headers });
};

export const deleteGroup = async (id) => {
    const url = config.ROApi + 'UserGroups/' + id;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const getUserDataForFunction = async (userId, functionId) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'users/' + userId + '/userData/?page_func_id=' + functionId;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};

export const addUserDataForFunction = async (userId, data) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'users/' + userId + '/userData/';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.post(url, data, { headers });
};

export const replicateUserDataForFunction = async (userId, sourceFunc, targetFunc) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'users/' + userId + '/functions/' + sourceFunc + '/userData/';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
        'content-type': 'application/json; charset=utf-8',
    };
    return axios({
        method: 'POST',
        url,
        data: JSON.stringify(targetFunc),
        headers,
    });
};

export const updateUserDataForFunction = async (userId, id, data) => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let url = config.ROApi + 'users/' + userId + '/userData/' + id;
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.put(url, data, { headers });
};

export const deleteUserData = async (userId, id) => {
    const url = config.ROApi + 'users/' + userId + '/userData/' + id;
    let headers = {
        Authorization: 'Bearer ' + JSON.parse(window.sessionStorage['userInfo']).accessToken,
    };
    return axiosDefault.delete(url, { headers });
};

export const checkWorkspaceAccess = (id) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    const url = config.ROApi + 'workspaces/canAccessAll?funcId=' + id;
    const headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return axiosDefault.get(url, { headers });
};
