import React from 'react';
import { defaultServiceDayList, getFormattedTimeSeconds, weekList } from '../../_helpers/common';
import { PaperComponent } from '../common';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import MaterialReactTable from 'material-react-table';
import VisibilityIcon from '@mui/icons-material/Visibility';

export const SelectionDetailPanel = (props) => {
    const {
        serviceDay,
        selectedServiceLocationIds,
        serviceLocationData,
        swapRouteSettings,
        handleCreateSwapRoutes,
        swapServicePatternSettings,
        selectedServicePattern,
        swapRouteList,
        siteMode,
        selectionDetailOpen,
        handleSelectionDetailClose,
        setSelectedServiceLocationIds,
        setSelectedServicePattern,
        updateSwapRouteSettings,
        setSwapConfirmDialogOpen,
        setSlFilter,
        servicePatternView,
        servicePatternData,
        updateSwapServicePatternSettings,
        setSwapServicePatternSettings,
        routeViewTabCategorization,
        autoCollapse,
        setAutoCollapse,
    } = props;

    const service_day_column = {
        header: 'Service Day',
        accessorKey: 'ServiceDay',
        size: 100,
        muiTableHeadCellProps: {
            align: 'center',
            sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
        },
        muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
        muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        Cell: ({ cell, row }) => {
            if (cell.getValue()) {
                if (sessionStorage.getItem('mdr') === 'true') {
                    return <span>{defaultServiceDayList[cell.getValue()].label}</span>;
                } else {
                    return (
                        <>
                            <span
                                style={{ width: '20px' }}
                                dangerouslySetInnerHTML={{ __html: row.original.Icon }}
                            ></span>
                            <span>{defaultServiceDayList[cell.getValue()].label}</span>
                        </>
                    );
                }
            } else {
                return '';
            }
        },
        Footer: () => {
            if ((serviceDay.value === 0 && routeViewTabCategorization.value.startsWith('S')) || (serviceDay.value !== 0 && servicePatternView)) {
                //need adjustment here
                return <>Total</>;
            } else {
                return <></>;
            }
        },
    };

    const route_column = {
        header: 'Route',
        accessorKey: 'Route',
        size: 100,
        muiTableHeadCellProps: {
            align: 'center',
            sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
        },
        muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
        muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        Cell: ({ cell, row }) => {
            if (cell.getValue() && cell.getValue() !== '0') {
                return (
                    <>
                        <span
                            style={{ width: '20px' }}
                            dangerouslySetInnerHTML={{ __html: row.original.Icon }}
                        ></span>
                        <span>{cell.getValue()}</span>
                    </>
                );
            } else {
                return (
                    <>
                        <span style={{ width: '20px' }}>
                            <svg
                                height='10'
                                width='13'
                            >
                                {' '}
                                <circle
                                    cx='5'
                                    cy='5'
                                    r='4'
                                    stroke='black'
                                    stroke-width='1'
                                    fill='black'
                                />
                            </svg>
                        </span>
                        <span>{'No Route'}</span>
                    </>
                );
            }
        },
        Footer: () => {
            if ((serviceDay.value === 0 && routeViewTabCategorization.value.startsWith('R')) || (serviceDay.value !== 0 && !servicePatternView)) {
                //need adjustment here
                return <>Total</>;
            } else {
                return <></>;
            }
        },
    };

    const stop_column = {
        header: 'Stops',
        accessorKey: 'Stops',
        size: 60,
        muiTableHeadCellProps: {
            align: 'center',
            sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
        },
        muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
        muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        Footer: () => {
            const total = summary.reduce((acc, curr) => acc + curr.Stops, 0);
            return <>{total}</>;
        },
    };

    const lift_column = {
        header: 'Lifts',
        accessorKey: 'Quantity',
        size: 60,
        muiTableHeadCellProps: {
            align: 'center',
            sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
        },
        muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
        muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        Footer: () => {
            const total = summary.reduce((acc, curr) => acc + curr.Quantity, 0);
            return <>{total}</>;
        },
    };

    const demand_column = {
        header: 'Demand',
        accessorKey: 'Demand',
        size: 60,
        muiTableHeadCellProps: {
            align: 'center',
            sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
        },
        muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
        muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        Cell: ({ cell, row }) => {
            if (cell.getValue()) {
                return <>{cell.getValue().toFixed(2)}</>;
            } else {
                return '';
            }
        },
        Footer: () => {
            const total = summary.reduce((acc, curr) => acc + curr.Demand, 0);
            return <>{total.toFixed(2)}</>;
        },
    };

    const service_time_column = {
        header: 'Service Time',
        accessorKey: 'ServiceTime',
        size: 60,
        muiTableHeadCellProps: {
            align: 'center',
            sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
        },
        muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
        muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        Cell: ({ cell, row }) => {
            if (cell.getValue()) {
                return getFormattedTimeSeconds(cell.getValue());
            } else {
                return '';
            }
        },
        Footer: () => {
            const total = summary.reduce((acc, curr) => acc + curr.ServiceTime, 0);
            return <>{getFormattedTimeSeconds(total)}</>;
        },
    };

    const non_mdr_action_column = {
        header: '',
        accessorKey: 'actions',
        size: 50,
        muiTableHeadCellProps: { align: 'center', sx: { fontSize: '10px', color: 'white', backgroundColor: '#369cd9' } },
        muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px' } },
        muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        Cell: ({ cell, row }) => {
            return (
                <Box>
                    <IconButton
                        onClick={() => setSlFilter(row.original.Route)}
                        sx={{ padding: 0 }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            let filtered = [];
                            if (serviceDay.value === 0) {
                                const serviceDay = row.original.ServiceDay;
                                const route = row.original.Route;
                                const selected = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                                filtered = selected.filter((s) => !(s.ServiceDay === serviceDay && s.Route === route));
                            } else {
                                const route = row.original.Route;
                                const selected = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                                filtered = selected.filter((s) => s.Route !== route);
                            }
                            const Ids = [...new Set(filtered.map((item) => item.Id))];
                            setSelectedServiceLocationIds(Ids);
                        }}
                        sx={{ padding: 0 }}
                    >
                        <i
                            className='RS_CLEAR_SELECTION'
                            style={{ fontSize: '16px' }}
                        />
                    </IconButton>
                </Box>
            );
        },
        Footer: () => {
            return (
                <Box>
                    <IconButton
                        onClick={() => setSlFilter(null)}
                        sx={{ padding: 0 }}
                    >
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton
                        onClick={() => {
                            setSelectedServiceLocationIds([]);
                        }}
                        sx={{ padding: 0 }}
                    >
                        <i
                            className='RS_CLEAR_SELECTION'
                            style={{ fontSize: '16px' }}
                        />
                    </IconButton>
                </Box>
            );
        },
    };

    const mdr_action_column = {
        header: '',
        accessorKey: 'actions',
        size: 50,
        muiTableHeadCellProps: { align: 'center', sx: { fontSize: '10px', color: 'white', backgroundColor: '#369cd9' } },
        muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px' } },
        muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        Cell: ({ cell, row }) => {
            return (
                <Box>
                    {!servicePatternView && (
                        <IconButton
                            onClick={() => setSlFilter(row.original.Route)}
                            sx={{ padding: 0 }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    )}
                    <IconButton
                        onClick={() => {
                            let filtered = [];
                            if (servicePatternView && selectedServicePattern.length > 0) {
                                const serviceDay = row.original.ServiceDay;
                                const route = row.original.Route;
                                const selected = selectedServicePattern.filter(
                                    (s) => !(s.service_pattern.substring(1).includes(serviceDay) && s.route === route),
                                );
                                setSelectedServicePattern(selected);
                            } else if (selectedServiceLocationIds.length > 0) {
                                if (serviceDay.value === 0) {
                                    const serviceDay = row.original.ServiceDay;
                                    const route = row.original.Route;
                                    const selected = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                                    filtered = selected.filter((s) => !(s.ServiceDay === serviceDay && s.Route === route));
                                } else {
                                    const route = row.original.Route;
                                    const selected = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                                    filtered = selected.filter((s) => s.Route !== route);
                                }
                                const Ids = [...new Set(filtered.map((item) => item.Id))];
                                setSelectedServiceLocationIds(Ids);
                            }
                        }}
                        sx={{ padding: 0 }}
                    >
                        <i
                            className='RS_CLEAR_SELECTION'
                            style={{ fontSize: '16px' }}
                        />
                    </IconButton>
                </Box>
            );
        },
        Footer: () => {
            return (
                <Box>
                    {!servicePatternView && (
                        <IconButton
                            onClick={() => setSlFilter(null)}
                            sx={{ padding: 0 }}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    )}
                    <IconButton
                        onClick={() => {
                            setSelectedServiceLocationIds([]);
                            setSelectedServicePattern([]);
                        }}
                        sx={{ padding: 0 }}
                    >
                        <i
                            className='RS_CLEAR_SELECTION'
                            style={{ fontSize: '16px' }}
                        />
                    </IconButton>
                </Box>
            );
        },
    };

    const singleDayColumns = [route_column, stop_column, lift_column, demand_column, service_time_column, non_mdr_action_column];
    const allDaysColumns = [service_day_column, route_column, stop_column, lift_column, demand_column, service_time_column, non_mdr_action_column];
    const mdrSingleDayColumns = [route_column, stop_column, lift_column, demand_column, service_time_column, mdr_action_column];
    const mdrAllDaysColumns = [service_day_column, route_column, stop_column, lift_column, demand_column, service_time_column, mdr_action_column];

    let selected = [],
        sumResult = [];

    const [patternList, setPatternList] = React.useState([]);
    const [summary, setSummary] = React.useState([]);
    const [disablePatternChange, setDisablePatternChange] = React.useState(false);
    const [disableWeek, setDisableWeek] = React.useState(false);
    const [showReducedWeekList, setShowReducedWeekList] = React.useState(false);

    React.useEffect(() => {
        let pattern = [];
        servicePatternData.forEach((p) => {
            let sp = p.ServicePattern;
            let patternDict = { 1: 'M', 2: 'T', 3: 'W', 4: 'R', 5: 'F', 6: 'S', 7: 'U' };
            let patternLabel = sp.substring(1).replace(/[1-7]/gi, (s) => patternDict[s]);
            pattern.push({ label: patternLabel, value: p.ServicePattern, available: p.Available });
        });
        setPatternList(pattern);
    }, [servicePatternData]);

    React.useEffect(() => {
        if (selectedServicePattern.length === 1) {
            let s = {
                servicePattern: selectedServicePattern[0].service_pattern,
                week: selectedServicePattern[0].week,
                targetRoute: selectedServicePattern[0].route,
                lockPattern: selectedServicePattern[0].service_pattern_group === 'Lock Pattern',
            };
            setDisablePatternChange(false);
            if (selectedServicePattern[0].week == null && selectedServicePattern[0].month == null) {
                setDisableWeek(true);
            } else {
                setDisableWeek(false);
            }
            if (selectedServicePattern[0].week != null && selectedServicePattern[0].month == null) {
                //s.week = null;
                setShowReducedWeekList(true);
            } else {
                setShowReducedWeekList(false);
            }
            setSwapServicePatternSettings(s);
        } else if (selectedServicePattern.length > 1) {
            let diffFrequencyPattern = selectedServicePattern.filter((s) => s.frequency !== selectedServicePattern[0].frequency);
            let nullWeekMonth = selectedServicePattern.filter((s) => s.week == null && s.month == null);
            let nullMonthOnly = selectedServicePattern.filter((s) => s.week != null && s.month == null);
            let notNull = selectedServicePattern.filter((s) => s.week != null && s.month != null);

            let s = {
                servicePattern: null,
                week: null,
                targetRoute: null,
                lockPattern: false,
            };
            if (nullWeekMonth.length > 0) {
                setDisableWeek(true);
            } else {
                if (
                    (nullMonthOnly.length > 0 && nullMonthOnly.length < selectedServicePattern.length) ||
                    (notNull.length > 0 && notNull.length < selectedServicePattern.length)
                ) {
                    setDisableWeek(true);
                } else {
                    setDisableWeek(false);
                }
            }
            if (nullMonthOnly.length === selectedServicePattern.length) {
                setShowReducedWeekList(true);
            } else {
                setShowReducedWeekList(false);
            }
            if (diffFrequencyPattern.length > 0) {
                setDisablePatternChange(true);
            } else {
                setDisablePatternChange(false);
            }
            setSwapServicePatternSettings(s);
        } else {
            setDisableWeek(false);
            setShowReducedWeekList(false);
            setDisablePatternChange(false);
        }
    }, [selectedServicePattern]);

    React.useEffect(() => {
        calculateSummary();
    }, [selectedServicePattern, selectedServiceLocationIds, servicePatternView]);

    const renderPatternList = () => {
        let lock = swapServicePatternSettings.lockPattern;
        let selectedFrequency = [];
        selectedServicePattern.forEach((ssp) => {
            if (!selectedFrequency.includes(ssp.frequency.toString())) {
                selectedFrequency.push(ssp.frequency.toString());
            }
        });
        let pl = patternList.filter((p) => selectedFrequency.includes(p.value[0]));
        if (!lock) pl = pl.filter((p) => p.available === true);
        return pl;
    };

    const renderColumns = () => {
        if (sessionStorage.getItem('mdr') === 'true') {
            if (serviceDay.value === 0 || servicePatternView) {
                return mdrAllDaysColumns;
            } else {
                return mdrSingleDayColumns;
            }
        } else {
            if (serviceDay.value === 0) {
                return allDaysColumns;
            } else {
                return singleDayColumns;
            }
        }
    };

    const renderDefaultOrder = () => {
        if (serviceDay.value === 0) {
            if (routeViewTabCategorization.value.startsWith('R')) {
                //need adjustment here
                return [
                    { id: 'Route', desc: false },
                    { id: 'ServiceDay', desc: false },
                ];
            } else {
                return [
                    { id: 'ServiceDay', desc: false },
                    { id: 'Route', desc: false },
                ];
            }
        } else {
            if (servicePatternView) {
                return [
                    { id: 'ServiceDay', desc: false },
                    { id: 'Route', desc: false },
                ];
            } else {
                return [{ id: 'Route', desc: false }];
            }
        }
    };

    const renderColumnOrder = () => {
        if (serviceDay.value === 0) {
            if (routeViewTabCategorization.value.startsWith('R')) {
                //need adjustment here
                return ['Route', 'ServiceDay', 'Stops', 'Quantity', 'Demand', 'ServiceTime', 'actions'];
            } else {
                return ['ServiceDay', 'Route', 'Stops', 'Quantity', 'Demand', 'ServiceTime', 'actions'];
            }
        } else {
            if (servicePatternView) {
                return ['ServiceDay', 'Route', 'Stops', 'Quantity', 'Demand', 'ServiceTime', 'actions'];
            } else {
                return ['Route', 'Stops', 'Quantity', 'Demand', 'ServiceTime', 'actions'];
            }
        }
    };

    const renderForm = () => {
        if (sessionStorage.getItem('mdr') === 'true') {
            if (!servicePatternView && siteMode === 'PLANNER') {
                return (
                    <>
                        <Grid container>
                            <Grid
                                container
                                alignItems={'baseline'}
                            >
                                <Grid
                                    item
                                    xs={2}
                                >
                                    {serviceDay.value === 0 ? 'Truck Territory' : 'Route'}
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <CreatableSelect
                                        className='basic-single'
                                        classNamePrefix='select'
                                        isSearchable={true}
                                        isClearable={false}
                                        isDisabled={false}
                                        value={
                                            (swapRouteList.filter((s) => s.value === '0').length > 0
                                                ? [...swapRouteList]
                                                      .filter((r) => r.value !== 'No Route')
                                                      .find((s) => s.value === swapRouteSettings.targetRoute)
                                                : [{ label: 'Unassign', value: '0' }, ...swapRouteList]
                                                      .filter((r) => r.value !== 'No Route')
                                                      .find((s) => s.value === swapRouteSettings.targetRoute)) || '0'
                                        }
                                        onChange={(e) => updateSwapRouteSettings(e, 'targetRoute')}
                                        options={
                                            swapRouteList.filter((s) => s.value === '0').length > 0
                                                ? [...swapRouteList].filter((r) => r.value !== 'No Route')
                                                : [{ label: 'Unassign', value: '0' }, ...swapRouteList].filter((r) => r.value !== 'No Route')
                                        }
                                        onCreateOption={(e) => handleCreateSwapRoutes(e)}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                padding: 5,
                                            }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                >
                                    {
                                        //serviceDay.value !== 0 &&
                                        <IconButton
                                            sx={{ fontSize: '14px', marginLeft: '10px', padding: 0 }}
                                            component='label'
                                            onClick={() => {
                                                props.setMapInfoMessage('Choose target territory from map');
                                                props.setMapSelectionMode('TARGET_ROUTE');
                                                props.pickXYSketch.create('point', { mode: 'click' });
                                            }}
                                        >
                                            <i className={'RS_ZOOM'}></i>
                                        </IconButton>
                                    }
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                >
                                    <Button
                                        sx={{ padding: 0, height: '30px' }}
                                        variant='outlined'
                                        disabled={
                                            selectedServiceLocationIds.length === 0 ||
                                            (serviceDay.value !== 0 ? !swapRouteSettings.targetRoute : false)
                                        }
                                        onClick={() => setSwapConfirmDialogOpen(true)}
                                    >
                                        Swap
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                    </>
                );
            } else if (servicePatternView && siteMode === 'PLANNER') {
                return (
                    <>
                        <Grid
                            container
                            alignItems={'baseline'}
                            sx={{ marginBottom: '10px' }}
                        >
                            <Grid
                                item
                                xs={1}
                                sx={{ marginRight: '20px' }}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={swapServicePatternSettings.lockPattern}
                                            onChange={(e) => updateSwapServicePatternSettings(e, 'lockPattern')}
                                        />
                                    }
                                    label={<span style={{ fontSize: '10px', whiteSpace: 'nowrap' }}>Lock Pattern(s)</span>}
                                    labelPlacement='top'
                                />
                            </Grid>

                            <Grid
                                item
                                xs={3}
                                sx={{ marginRight: '5px' }}
                            >
                                <FormControlLabel
                                    control={
                                        <ReactSelect
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isClearable={true}
                                            placeholder={'- -'}
                                            isSearchable={true}
                                            isDisabled={disablePatternChange}
                                            name='Pattern_Select'
                                            id='Pattern_Select'
                                            value={
                                                swapServicePatternSettings.servicePattern
                                                    ? renderPatternList().find((r) => r.value === swapServicePatternSettings.servicePattern)
                                                    : null
                                            }
                                            onChange={(e) => updateSwapServicePatternSettings(e, 'servicePattern')}
                                            options={renderPatternList()}
                                            menuPortalTarget={document.body}
                                            width={'100%'}
                                            styles={{
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '10px',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    padding: 5,
                                                    fontSize: '10px',
                                                }),
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: 'max-content',
                                                    minWidth: '100%',
                                                }),
                                            }}
                                        />
                                    }
                                    label={<span style={{ fontSize: '10px' }}>Pattern</span>}
                                    labelPlacement='top'
                                    sx={{ width: '100%' }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={2}
                                sx={{ marginRight: '5px' }}
                            >
                                <FormControlLabel
                                    control={
                                        <ReactSelect
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isClearable={true}
                                            placeholder={'- -'}
                                            isSearchable={true}
                                            name='Week_Select'
                                            id='Week_Select'
                                            isDisabled={disablePatternChange || disableWeek}
                                            value={
                                                swapServicePatternSettings.week
                                                    ? showReducedWeekList
                                                        ? weekList.slice(0, 2).find((r) => r.value == swapServicePatternSettings.week)
                                                        : weekList.find((r) => r.value == swapServicePatternSettings.week)
                                                    : null
                                            }
                                            onChange={(e) => updateSwapServicePatternSettings(e, 'week')}
                                            options={showReducedWeekList ? weekList.slice(0, 2) : weekList}
                                            menuPortalTarget={document.body}
                                            styles={{
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '10px',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    padding: 5,
                                                    fontSize: '10px',
                                                }),
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    width: 'max-content',
                                                    minWidth: '100%',
                                                }),
                                            }}
                                        />
                                    }
                                    label={<span style={{ fontSize: '10px' }}>Week</span>}
                                    labelPlacement='top'
                                    sx={{ width: '100%' }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={3}
                                sx={{ marginRight: '5px' }}
                            >
                                <FormControlLabel
                                    value='Route'
                                    control={
                                        <CreatableSelect
                                            className='basic-single'
                                            classNamePrefix='select'
                                            isSearchable={true}
                                            isClearable={false}
                                            placeholder={'- -'}
                                            value={
                                                (swapRouteList.filter((s) => s.value === '0').length > 0
                                                    ? [...swapRouteList]
                                                          .filter((r) => r.value !== 'No Route')
                                                          .find((s) => s.value === swapServicePatternSettings.targetRoute)
                                                    : [{ label: 'Unassign', value: '0' }, ...swapRouteList]
                                                          .filter((r) => r.value !== 'No Route')
                                                          .find((s) => s.value === swapServicePatternSettings.targetRoute)) || null
                                            }
                                            onChange={(e) => updateSwapServicePatternSettings(e, 'targetRoute')}
                                            onCreateOption={(e) => handleCreateSwapRoutes(e)}
                                            options={
                                                swapRouteList.filter((s) => s.value === '0').length > 0
                                                    ? [...swapRouteList].filter((r) => r.value !== 'No Route')
                                                    : [{ label: 'Unassign', value: '0' }, ...swapRouteList].filter((r) => r.value !== 'No Route')
                                            }
                                            menuPortalTarget={document.body}
                                            styles={{
                                                valueContainer: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    fontSize: '10px',
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    padding: 5,
                                                    fontSize: '10px',
                                                }),
                                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                container: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    minWidth: '100%',
                                                }),
                                            }}
                                        />
                                    }
                                    label={<span style={{ fontSize: '10px' }}>{serviceDay.value === 0 ? 'Territory' : 'Route'}</span>}
                                    labelPlacement='top'
                                    sx={{ width: '100%' }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={1}
                            >
                                <FormControlLabel
                                    value=''
                                    control={
                                        <Button
                                            sx={{ padding: 0 }}
                                            variant='outlined'
                                            //disabled={(selectedServiceLocationIds.length === 0 || (serviceDay.value !== 0 ? !swapRouteSettings.targetRoute : false))}
                                            onClick={() => setSwapConfirmDialogOpen(true)}
                                        >
                                            Apply
                                        </Button>
                                    }
                                    label=''
                                    labelPlacement='top'
                                    sx={{ margin: '25px 0 0 25px' }}
                                />
                            </Grid>
                        </Grid>
                    </>
                );
            }
        } else {
            if (siteMode === 'PLANNER') {
                return (
                    <>
                        <Grid container>
                            <Grid
                                container
                                alignItems={'baseline'}
                            >
                                <Grid
                                    item
                                    xs={2}
                                >
                                    Service Day
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <ReactSelect
                                        className='basic-single'
                                        classNamePrefix='select'
                                        isClearable={true}
                                        isSearchable={false}
                                        isDisabled={serviceDay.value !== 0}
                                        value={
                                            serviceDay.value !== 0
                                                ? defaultServiceDayList.filter((s) => s.value !== 0).find((s) => s.value === serviceDay.value)
                                                : defaultServiceDayList
                                                      .filter((s) => s.value !== 0)
                                                      .find((s) => s.value === swapRouteSettings.serviceDay)
                                        }
                                        onChange={(e) => updateSwapRouteSettings(e, 'serviceDay')}
                                        options={defaultServiceDayList.filter((s) => s.value !== 0)}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                padding: 5,
                                            }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <br /> <br />
                            <Grid
                                container
                                alignItems={'baseline'}
                            >
                                <Grid
                                    item
                                    xs={2}
                                >
                                    {'Route'}
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <CreatableSelect
                                        className='basic-single'
                                        classNamePrefix='select'
                                        isSearchable={true}
                                        isClearable={false}
                                        isDisabled={serviceDay.value !== 0 ? false : swapRouteSettings.serviceDay === ''}
                                        value={
                                            swapRouteList.filter((s) => s.value === '0').length > 0
                                                ? [...swapRouteList]
                                                      .filter((r) => r.value !== 'No Route')
                                                      .find((s) => s.value === swapRouteSettings.targetRoute) || '0'
                                                : [{ label: 'Unassign', value: '0' }, ...swapRouteList]
                                                      .filter((r) => r.value !== 'No Route')
                                                      .find((s) => s.value === swapRouteSettings.targetRoute) || '0'
                                        }
                                        onChange={(e) => updateSwapRouteSettings(e, 'targetRoute')}
                                        options={
                                            swapRouteList.filter((s) => s.value === '0').length > 0
                                                ? [...swapRouteList].filter((r) => r.value !== 'No Route')
                                                : [{ label: 'Unassign', value: '0' }, ...swapRouteList].filter((r) => r.value !== 'No Route')
                                        }
                                        onCreateOption={(e) => handleCreateSwapRoutes(e)}
                                        menuPortalTarget={document.body}
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                padding: 5,
                                            }),
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                >
                                    {serviceDay.value !== 0 && (
                                        <IconButton
                                            sx={{ fontSize: '14px', marginLeft: '10px', padding: 0 }}
                                            component='label'
                                            onClick={() => {
                                                props.setMapInfoMessage('Choose target route from map');
                                                props.setMapSelectionMode('TARGET_ROUTE');
                                                props.pickXYSketch.create('point', { mode: 'click' });
                                            }}
                                        >
                                            <i className={'RS_ZOOM'}></i>
                                        </IconButton>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                >
                                    <Button
                                        sx={{ padding: 0, height: '30px' }}
                                        variant='outlined'
                                        disabled={
                                            selectedServiceLocationIds.length === 0 ||
                                            (serviceDay.value !== 0 ? !swapRouteSettings.targetRoute : false)
                                        }
                                        onClick={() => setSwapConfirmDialogOpen(true)}
                                    >
                                        Swap
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <br />
                    </>
                );
            }
        }
    };

    const calculateSummary = () => {
        if (servicePatternView) {
            if (selectedServicePattern.length > 0) {
                selectedServicePattern.forEach((p) => {
                    let sp = p.service_pattern;
                    let days = sp.substring(1).split('');
                    days.forEach((d) => {
                        sumResult.push({
                            ServiceDay: d,
                            Route: p.route,
                            ServiceTime: p.service_time,
                            Quantity: p.quantity,
                            Demand: p.demand,
                            Stops: 1,
                            gid: p.gid,
                        });
                    });
                });
                const result = sumResult.reduce((r, { Route, ServiceDay, Quantity, Demand, ServiceTime }) => {
                    var item = r.find((o) => o.ServiceDay === ServiceDay && o.Route === Route);
                    if (!item) {
                        item = { Route, ServiceDay, Quantity: 0, Demand: 0, ServiceTime: 0 };
                        r.push(item);
                    }
                    item.Quantity += Quantity;
                    item.Demand += Demand;
                    item.ServiceTime += ServiceTime;
                    return r;
                }, []);
                if (result && result.length > 0) {
                    sumResult = result.map((r) => {
                        const count = sumResult.filter((ss) => ss.ServiceDay === r.ServiceDay && ss.Route === r.Route);
                        r.Stops = count.length;
                        return r;
                    });
                    //sumResult = sumResult.sort((a, b) => a.ServiceDay - b.ServiceDay);
                }
            } else {
                sumResult = [];
            }
        } else {
            if (selectedServiceLocationIds.length > 0) {
                selected = serviceLocationData.filter((s) => selectedServiceLocationIds.includes(s.Id));
                if (selected && selected.length > 0) {
                    if (serviceDay.value === 0) {
                        const result = selected.reduce((r, { Route, ServiceDay, Demand, ServiceTime, Quantity }) => {
                            var item = r.find((o) => o.Route === Route && o.ServiceDay === ServiceDay);
                            if (!item) {
                                item = { Route, ServiceDay, Demand: 0, ServiceTime: 0, Quantity: 0 };
                                r.push(item);
                            }
                            item.Demand += Demand;
                            item.ServiceTime += ServiceTime;
                            item.Quantity += Quantity;
                            return r;
                        }, []);
                        if (result && result.length > 0) {
                            sumResult = result.map((r) => {
                                let rt = {};
                                if (routeViewTabCategorization.value.startsWith('R')) {
                                    let currentRoute = props.routeViewTableData[routeViewTabCategorization.value].find((rs) => rs.Id === r.Route); //need adjustment here
                                    if (currentRoute) {
                                        rt = currentRoute.subRows.find((cr) => cr.Id === r.ServiceDay);
                                    }
                                } else {
                                    let currentServiceDay = props.routeViewTableData[routeViewTabCategorization.value].find(
                                        (rs) => rs.Id === r.ServiceDay,
                                    ); //need adjustment here
                                    if (currentServiceDay) {
                                        rt = currentServiceDay.subRows.find((cr) => cr.Id === r.Route);
                                    }
                                }
                                if (rt && rt.Svg) {
                                    r.Icon = rt.Svg;
                                }
                                const count = selected.filter((ss) => ss.Route === r.Route && ss.ServiceDay === r.ServiceDay);
                                r.Stops = count.length;
                                return r;
                            });
                        }
                    } else {
                        const result = selected.reduce((r, { Route, Demand, ServiceTime, Quantity }) => {
                            var item = r.find((o) => o.Route === Route);
                            if (!item) {
                                item = { Route, Demand: 0, ServiceTime: 0, Quantity: 0 };
                                r.push(item);
                            }
                            item.Demand += Demand;
                            item.ServiceTime += ServiceTime;
                            item.Quantity += Quantity;
                            return r;
                        }, []);
                        if (result && result.length > 0) {
                            sumResult = result.map((r) => {
                                let rt = props.routeViewTableData['R'].find((rl) => rl.Id === r.Route); //need adjustment here
                                if (rt && rt.Svg) {
                                    r.Icon = rt.Svg;
                                }
                                const count = selected.filter((ss) => ss.Route === r.Route);
                                r.Stops = count.length;
                                return r;
                            });
                            sumResult = sumResult.sort((a, b) => a.Route.localeCompare(b.Route, 'en', { numeric: true }));
                        }
                    }
                } else {
                    sumResult = [];
                }
            } else {
                sumResult = [];
            }
        }
        setSummary(sumResult);
    };

    return (
        <Dialog
            id={'SelectionDetailDialog'}
            open={selectionDetailOpen}
            onClose={handleSelectionDetailClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            disableEnforceFocus
            sx={{
                '& .MuiDialog-container': {
                    '& .MuiPaper-root': {
                        width: '100%',
                        maxWidth: '730px',
                    },
                },
                pointerEvents: 'none',
            }}
            PaperProps={{
                style: {
                    pointerEvents: 'auto',
                    position: 'absolute',
                    bottom: '225px',
                    right: '20px',
                },
            }}
        >
            <DialogTitle
                style={{ cursor: 'move' }}
                id='draggable-dialog-title'
            >
                Selection Statistics
                <FormControlLabel
                    control={
                        <Switch
                            checked={autoCollapse}
                            onChange={(e) => {
                                setAutoCollapse(e.target.checked);
                                sessionStorage.setItem('autoCollapse', e.target.checked);
                            }}
                        />
                    }
                    label='Auto Collapse '
                    sx={{ float: 'right' }}
                />
            </DialogTitle>
            <DialogContent>
                {renderForm()}
                <div
                    id='selectionDetailDiv'
                    style={{ width: '680px', height: '200px', background: 'white' }}
                >
                    <MaterialReactTable
                        columns={renderColumns()}
                        data={summary}
                        enableTopToolbar={false}
                        enableStickyHeader={true}
                        enableStickyFooter={true}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableColumnOrdering={false}
                        enableColumnResizing={false}
                        enableRowSelection={false}
                        enableHiding={false}
                        positionToolbarAlertBanner='none'
                        initialState={{
                            density: 'compact',
                            columnOrder: renderColumnOrder(),
                            sorting: renderDefaultOrder(),
                        }}
                        state={{
                            columnOrder: renderColumnOrder(),
                            //sorting: renderDefaultOrder()
                        }}
                        muiTableContainerProps={{
                            sx: { maxHeight: '200px' },
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export const SwapConfirmDialog = (props) => {
    const {
        swapConfirmDialogOpen,
        handleSwapClose,
        swapRouteSettings,
        swapServicePatternSettings,
        selectedServiceLocationIds,
        selectedServicePattern,
        servicePatternView,
    } = props;

    return (
        <Dialog
            open={swapConfirmDialogOpen}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>{servicePatternView ? 'Swap Service Patterns' : 'Swap Stops'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {servicePatternView
                        ? `Are you sure you want to update ${selectedServicePattern.length} service pattern record(s)?`
                        : swapRouteSettings.targetRoute === '0'
                          ? `Are you sure you wish to clear route assignment for ${selectedServiceLocationIds.length} stops?`
                          : `Are you sure you wish to swap ${selectedServiceLocationIds.length} stops to route: ${swapRouteSettings.targetRoute}?`}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleSwapClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleSwapClose('SAVE')}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
