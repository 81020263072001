import React from 'react';
import { updateUserActivity } from '../../_actions/Global.actions';
import { Switch } from '@mui/material';

export const LayerToggle = (props) => {
    const {
        siteMode,
        workspace,
        mapMode,
        selDispatchTool,
        facilityLayerVisibility,
        RAASFacilityLayerVisibility,
        streetLayerVisibility,
        trafficLayerVisibility,
        setFacilityLayerVisibility,
        setRAASFacilityLayerVisibility,
        setStreetLayerVisibility,
        setTrafficLayerVisibility,
        zoneFacilityLayerVisibility,
        zoneServiceLocationLayerVisibility,
        setZoneFacilityLayerVisibility,
        setZoneServiceLocationLayerVisibility,
        projectFacilityLayerVisibility,
        setProjectFacilityLayerVisibility,
        blockFaceLayerVisbility,
        setBlockFaceLayerVisbility,
    } = props;
    return (
        <>
            {siteMode === 'DISPATCHER' && (
                <>
                    {mapMode === 2 && (
                        <div style={{ whiteSpace: 'nowrap', paddingTop: '5px' }}>
                            Facilities&nbsp;
                            <Switch
                                size='small'
                                checked={zoneFacilityLayerVisibility}
                                onChange={(e) => {
                                    if (mapMode === 2) {
                                        setZoneFacilityLayerVisibility(e.target.checked);
                                    }
                                }}
                            />
                            &nbsp;&nbsp; Stops&nbsp;
                            <Switch
                                size='small'
                                checked={zoneServiceLocationLayerVisibility}
                                onChange={(e) => {
                                    if (mapMode === 2) {
                                        setZoneServiceLocationLayerVisibility(e.target.checked);
                                    }
                                }}
                            />
                            &nbsp;&nbsp;
                        </div>
                    )}
                    {mapMode !== 2 && (
                        <>
                            <div style={{ whiteSpace: 'nowrap', paddingTop: '5px' }}>
                                Facilities&nbsp;
                                <Switch
                                    size='small'
                                    checked={
                                        mapMode === 0
                                            ? facilityLayerVisibility
                                            : mapMode === 1 && selDispatchTool === 'SavedScenarios'
                                            ? RAASFacilityLayerVisibility
                                            : false
                                    }
                                    disabled={mapMode === 1 && selDispatchTool !== 'SavedScenarios'}
                                    onChange={(e) => {
                                        if (mapMode === 0) {
                                            setFacilityLayerVisibility(e.target.checked);
                                        } else if (mapMode === 1 && selDispatchTool === 'SavedScenarios') {
                                            setRAASFacilityLayerVisibility(e.target.checked);
                                        }
                                        if (e.target.checked) {
                                            updateUserActivity({
                                                ActionId: 220,
                                                WorkspaceId: workspace.value,
                                                Mode: siteMode,
                                                Metadata: 'on|Facility Layer',
                                            });
                                        } else {
                                            updateUserActivity({
                                                ActionId: 220,
                                                WorkspaceId: workspace.value,
                                                Mode: siteMode,
                                                Metadata: 'off|Facility Layer',
                                            });
                                        }
                                    }}
                                />
                                &nbsp;&nbsp; Streets&nbsp;
                                <Switch
                                    size='small'
                                    checked={streetLayerVisibility}
                                    disabled={mapMode === 1 && selDispatchTool !== 'SavedScenarios'}
                                    onChange={(e) => {
                                        if (mapMode !== 2) {
                                            setStreetLayerVisibility(e.target.checked);
                                            if (e.target.checked) {
                                                updateUserActivity({
                                                    ActionId: 220,
                                                    WorkspaceId: workspace.value,
                                                    Mode: siteMode,
                                                    Metadata: 'on|Street Layer',
                                                });
                                            } else {
                                                updateUserActivity({
                                                    ActionId: 220,
                                                    WorkspaceId: workspace.value,
                                                    Mode: siteMode,
                                                    Metadata: 'off|Street Layer',
                                                });
                                            }
                                        }
                                    }}
                                />
                                &nbsp;&nbsp; Traffic&nbsp;
                                <Switch
                                    size='small'
                                    checked={trafficLayerVisibility}
                                    disabled={mapMode === 1 && selDispatchTool !== 'SavedScenarios'}
                                    onChange={(e) => {
                                        setTrafficLayerVisibility(e.target.checked);
                                        updateUserActivity({
                                            ActionId: 229,
                                            WorkspaceId: workspace.value,
                                            Mode: siteMode,
                                            Metadata: 'Traffic Layer',
                                        });
                                    }}
                                />
                            </div>
                            {mapMode === 0 && (
                                <div style={{ whiteSpace: 'nowrap', paddingTop: '5px' }}>
                                    Blockface&nbsp;
                                    <Switch
                                        size='small'
                                        checked={blockFaceLayerVisbility}
                                        onChange={(e) => {
                                            setBlockFaceLayerVisbility(e.target.checked);
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
            {siteMode === 'PLANNER' && (
                <>
                    {mapMode === 2 && (
                        <div style={{ whiteSpace: 'nowrap', paddingTop: '5px' }}>
                            Facilities&nbsp;
                            <Switch
                                size='small'
                                checked={zoneFacilityLayerVisibility}
                                onChange={(e) => {
                                    if (mapMode === 2) {
                                        setZoneFacilityLayerVisibility(e.target.checked);
                                    }
                                }}
                            />
                            &nbsp;&nbsp; Stops&nbsp;
                            <Switch
                                size='small'
                                checked={zoneServiceLocationLayerVisibility}
                                onChange={(e) => {
                                    if (mapMode === 2) {
                                        setZoneServiceLocationLayerVisibility(e.target.checked);
                                    }
                                }}
                            />
                            &nbsp;&nbsp;
                        </div>
                    )}
                    {mapMode === 0 && (
                        <>
                            <div style={{ whiteSpace: 'nowrap', paddingTop: '5px' }}>
                                Facilities&nbsp;
                                <Switch
                                    size='small'
                                    checked={projectFacilityLayerVisibility}
                                    onChange={(e) => {
                                        setProjectFacilityLayerVisibility(e.target.checked);
                                        if (e.target.checked) {
                                            updateUserActivity({
                                                ActionId: 220,
                                                WorkspaceId: workspace.value,
                                                Mode: siteMode,
                                                Metadata: 'on|Facility Layer',
                                            });
                                        } else {
                                            updateUserActivity({
                                                ActionId: 220,
                                                WorkspaceId: workspace.value,
                                                Mode: siteMode,
                                                Metadata: 'off|Facility Layer',
                                            });
                                        }
                                    }}
                                />
                                &nbsp;&nbsp; Streets&nbsp;
                                <Switch
                                    size='small'
                                    checked={streetLayerVisibility}
                                    onChange={(e) => {
                                        setStreetLayerVisibility(e.target.checked);
                                        if (e.target.checked) {
                                            updateUserActivity({
                                                ActionId: 220,
                                                WorkspaceId: workspace.value,
                                                Mode: siteMode,
                                                Metadata: 'on|Street Layer',
                                            });
                                        } else {
                                            updateUserActivity({
                                                ActionId: 220,
                                                WorkspaceId: workspace.value,
                                                Mode: siteMode,
                                                Metadata: 'off|Street Layer',
                                            });
                                        }
                                    }}
                                />
                                &nbsp;&nbsp; Traffic&nbsp;
                                <Switch
                                    size='small'
                                    checked={trafficLayerVisibility}
                                    onChange={(e) => {
                                        setTrafficLayerVisibility(e.target.checked);
                                        updateUserActivity({
                                            ActionId: 229,
                                            WorkspaceId: workspace.value,
                                            Mode: siteMode,
                                            Metadata: 'Traffic Layer',
                                        });
                                    }}
                                />
                            </div>
                            <div style={{ whiteSpace: 'nowrap', paddingTop: '5px' }}>
                                Blockface&nbsp;
                                <Switch
                                    size='small'
                                    checked={blockFaceLayerVisbility}
                                    onChange={(e) => {
                                        setBlockFaceLayerVisbility(e.target.checked);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};
