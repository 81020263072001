import React from 'react';
import { TextBox, RadioGroup } from 'devextreme-react';
import { Grid, Button, Typography, CircularProgress } from '@mui/material';

export const PrintDialog = (props) => {
    return (
        <div id='printDiv'>
            <Grid
                container
                spacing={'10px'}
            >
                <Grid
                    item
                    xs={12}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Title
                    </Typography>
                    <TextBox
                        name='printTitle'
                        defaultValue={props.selPrintTitle}
                        onValueChanged={(e) => {
                            props.setSelPrintTitle(e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        File format
                    </Typography>
                    <RadioGroup
                        items={['PDF', 'JPG']}
                        defaultValue={props.selPrintFormat}
                        layout='horizontal'
                        onValueChanged={(e) => {
                            props.setSelPrintFormat(e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'center' }}
                >
                    <Button
                        style={!props.printButtonLoading ? { backgroundColor: '#ee8a1d', color: '#fff' } : { backgroundColor: '#eee' }}
                        disabled={props.printButtonLoading}
                        onClick={() => props.printMap()}
                    >
                        Print
                    </Button>
                    {props.printButtonLoading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                top: '132px',
                                left: '50%',
                                marginTop: '10px',
                                marginLeft: '-12px',
                            }}
                        />
                    )}
                </Grid>
            </Grid>
        </div>
    );
};
