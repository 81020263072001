import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { resetPassword } from '../../_actions/Login.actions';
import { GlobalROContext } from '../common';

export const ResetPasswordDialog = (props) => {
    const { resetPasswordDialogOpen, setResetPasswordDialogOpen } = props;

    const [resetPasswordForm, setResetPasswordForm] = useState({ userName: '' });

    const { setShowROWarningDialog, setShowApiROErrorDialog } = useContext(GlobalROContext);

    useEffect(() => {
        setResetPasswordForm({ userName: '' });
    }, [resetPasswordDialogOpen]);

    const updateResetPasswordForm = (e) => {
        let f = { ...resetPasswordForm };
        f.userName = e.target.value;
        setResetPasswordForm(f);
    };

    const handleResetPasswordDialogClose = (mode) => {
        if (mode === 'CANCEL') {
            setResetPasswordDialogOpen(false);
            setResetPasswordForm({ userName: '' });
        } else if (mode === 'SAVE') {
            resetPassword(resetPasswordForm.userName).then(
                (res) => {
                    setResetPasswordDialogOpen(false);
                    setResetPasswordForm({ userName: '' });
                    setShowROWarningDialog(
                        'An email has been sent to the email address associated with your RouteSmart account. If you do not receive the e-mail within 10 minutes, please check your spam folder.',
                    );
                },
                (err) => {
                    console.log(err);
                    if (err?.response?.data?.Message) {
                        setShowApiROErrorDialog(err?.response?.data?.Message);
                    } else {
                        setShowApiROErrorDialog("The info you provided doesn't match our record, please verify your input and try again later!");
                    }
                },
            );
        }
    };

    return (
        <Dialog
            open={resetPasswordDialogOpen}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>
                {'Reset Password'}
                <IconButton
                    aria-label='close'
                    onClick={() => handleResetPasswordDialogClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                >
                    <p>Please enter your user name and we will send you an e-mail to reset your password.</p>
                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            autoFocus
                            margin='dense'
                            id='ResetPasswordForm_TextField_UserName'
                            name='ResetPasswordForm_TextField_UserName'
                            label='User Name'
                            type='text'
                            fullWidth
                            variant='outlined'
                            value={resetPasswordForm.userName}
                            onChange={updateResetPasswordForm}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={!resetPasswordForm.userName ? { backgroundColor: '#fff' } : { backgroundColor: '#16a8a6', color: '#fff' }}
                    id={'ResetPasswordForm_Button_RequestRecoveryEmail'}
                    disabled={!resetPasswordForm.userName}
                    onClick={() => handleResetPasswordDialogClose('SAVE')}
                >
                    Request Recovery Email
                </Button>
            </DialogActions>
        </Dialog>
    );
};
