import React from 'react';
import MaterialReactTable from 'material-react-table';
import { checkFunction } from '../../_actions/Global.actions';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import ReactSelect from 'react-select';
import { ROUTE_VIEW_TAB_CATEGORIES } from '../../_helpers/common';

export const RouteViewTab = (props) => {
    const {
        serviceDay,
        bottomPaneVisibility,
        bottomPaneGrid,
        servicePatternView,
        alleyAssignmentMode,
        showTravelPathLabelBtnTooltip,
        showTravelPathLabel,
        handleZoomToRoute,
        handleZoomToSelectedRoutes,
        travelPathLabelTooltip,
        selTravelPathRoute,
        showSequenceLabel,
        selSequenceRoute,
        selMapToolChangeHandler,
        setShowTravelPathLabel,
        setShowSequenceLabel,
        toggleMapTravelPathLayer,
        toggleMapSequenceLayer,
        siteMode,
        routeViewTabCategorization,
    } = props;

    const tableInstanceRef = React.createRef(null);

    let tableRowLength = tableInstanceRef?.current?.refs?.tableContainerRef?.current?.clientWidth || 307;
    let subRowCarrotW = 0;
    // if (props.serviceDay?.value === 0) subRowCarrotW = 30;
    let checkboxW = 30;
    let svgW = 15;
    let zoomW = 30;
    let travelPathW = 30;
    let sequenceW = 30;

    let sumOfOtherColumns = subRowCarrotW + checkboxW + svgW + zoomW + travelPathW + sequenceW;
    let calculatedLabelWidth = tableRowLength - sumOfOtherColumns;

    let widthOfScroll = 15;

    //value of the
    let tableData = props.routeViewTableData['R/S'];
    let tableDataShown = tableData;

    if (routeViewTabCategorization.value) {
        tableData = props.routeViewTableData[routeViewTabCategorization.value];
        tableDataShown = tableData;
        if (routeViewTabCategorization.value === 'S' || routeViewTabCategorization.value === 'R') {
            tableDataShown = tableData.map((v) => {
                return {
                    Id: v.Id,
                    Label: v.Label,
                    UnmatchedCount: v.UnmatchedCount,
                    Svg: v.Svg,
                    SvgColor: v.SvgColor,
                    SvgStyle: v.SvgStyle,
                    checked: v.checked,
                    indeterminate: v.indeterminate,
                };
            });
        }
    }

    if (props.serviceDay?.value !== 0) {
        tableData = props.routeViewTableData['R'];
        tableDataShown = tableData.map((v) => {
            return {
                Id: v.Id,
                Label: v.Label,
                UnmatchedCount: v.UnmatchedCount,
                Svg: v.Svg,
                SvgColor: v.SvgColor,
                SvgStyle: v.SvgStyle,
                checked: v.checked,
                indeterminate: v.indeterminate,
            };
        });
    }

    let columHeaderChecked = tableData.every((v) => v.checked === true);
    let columHeaderIndeterminate = tableData.every((v) => v.checked === true) || tableData.every((v) => v.checked === false) ? false : true;

    /*
    handler for the ALL toggle (header). 
    will make all the rows' and subrows' checked value true or false.
    the indeterminate will be false because its one or the other
    */
    const handleAllToggle = (e) => {
        let d = tableData.map((v) => {
            return {
                ...v,
                checked: e.target.checked,
                indeterminate: false,
                subRows: v.subRows.map((el) => {
                    return {
                        ...el,
                        checked: e.target.checked,
                        indeterminate: false,
                    };
                }),
            };
        });

        let key = 'R';
        if (routeViewTabCategorization.value && props.serviceDay?.value === 0) {
            key = routeViewTabCategorization.value;
        }

        props.handleToggleUpdate({
            ...props.routeViewTableData,
            [key]: d,
        });
    };
    /* */

    /*
    handler for the rows and subrow toggle. 
    will make the individual the rows or subrows' checked value true or false.
    the parents' indeterminate will be calculated should there be a parent. Otherwise its always false
    */
    const handleToggle = (ind, parentRowInfo) => {
        let d = structuredClone(tableData);

        if (parentRowInfo) {
            d[parentRowInfo.index]['subRows'][ind].checked = !d[parentRowInfo.index]['subRows'][ind].checked;
            let parentCheckedDict = {};

            d[parentRowInfo.index]['subRows'].forEach((v) => {
                parentCheckedDict = { ...parentCheckedDict, [v.checked]: 1 };
            });

            //now that the subrow has been checked, check to see if parent checkboxes need to be updated
            if (Object.keys(parentCheckedDict).length > 1) {
                d[parentRowInfo.index].checked = false;
                d[parentRowInfo.index].indeterminate = true;
            } else if (Object.keys(parentCheckedDict).length === 1) {
                d[parentRowInfo.index].checked = Object.keys(parentCheckedDict)[0] === 'false' ? false : true;
                d[parentRowInfo.index].indeterminate = false;
            }
        } else {
            let checkedValue = !d[ind].checked;

            d[ind].checked = checkedValue;
            d[ind].indeterminate = false;
            if (d[ind]['subRows']) {
                d[ind]['subRows'] = d[ind]['subRows'].map((v) => {
                    return {
                        ...v,
                        checked: checkedValue,
                    };
                });
            }
        }

        let key = 'R';
        if (routeViewTabCategorization.value && props.serviceDay?.value === 0) {
            key = routeViewTabCategorization.value;
        }

        props.handleToggleUpdate({
            ...props.routeViewTableData,
            [key]: d,
        });
    };

    /* */
    const showZoomLabel = (isSubRow) => {
        if (serviceDay.value === 0) {
            if (routeViewTabCategorization.value === 'R/S') {
                if (isSubRow) {
                    return 'Zoom to service day';
                } else {
                    return 'Zoom to route';
                }
            } else {
                if (isSubRow) {
                    return 'Zoom to route';
                } else {
                    return 'Zoom to service day';
                }
            }
        } else {
            return 'Zoom to route';
        }
    };

    let columns = [
        {
            header: (
                <Checkbox
                    title='All'
                    checked={columHeaderChecked}
                    indeterminate={columHeaderIndeterminate}
                    onClick={handleAllToggle}
                    color='default'
                    style={{ padding: '4px' }}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                />
            ),
            id: 'Checkbox',
            size: checkboxW,
            minSize: checkboxW,
            maxSize: checkboxW,
            enableGlobalFilter: false,
            Cell: ({ row }) => {
                let isSubRow;
                if (row.getParentRow()) isSubRow = true;
                return (
                    <Checkbox
                        checked={row.original.checked}
                        indeterminate={row.original.indeterminate}
                        onClick={() => handleToggle(row.index, row.getParentRow())}
                        color='default'
                        style={{
                            padding: isSubRow ? '1px' : '4px',
                            marginLeft: isSubRow ? '8px' : 'auto',
                        }}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                    />
                );
            },
        },
        {
            header: '',
            accessorKey: 'Svg',
            size: svgW,
            minSize: svgW,
            maxSize: svgW,
            enableGlobalFilter: false,
            Cell: ({ cell, row }) => {
                if (!servicePatternView) {
                    return (
                        <div
                            style={{ width: '10px' }}
                            dangerouslySetInnerHTML={{ __html: cell.getValue() }}
                        ></div>
                    );
                } else {
                    return <div style={{ width: '10px' }}></div>;
                }
            },
        },
        {
            header: serviceDay.value !== 0 ? `Route - ${serviceDay.label}` : '',
            accessorKey: 'Label',
            // size: calculatedLabelWidth,
            minSize: calculatedLabelWidth - widthOfScroll,
            maxSize: calculatedLabelWidth,
            Cell: ({ cell, row }) => {
                if (siteMode === 'DISPATCHER') {
                    return (
                        <span>
                            {row.original.Label} <span title='Unpinned'>({row.original.UnmatchedCount})</span>
                        </span>
                    );
                } else {
                    return <span>{cell.getValue()}</span>;
                }
            },
        },

        {
            header: '',
            accessorKey: 'travelPath',
            size: travelPathW,
            minSize: travelPathW,
            maxSize: travelPathW,
            enableGlobalFilter: false,
            visible: serviceDay.value !== 0,
            Header: ({ column }) => (
                <div
                    title={showTravelPathLabelBtnTooltip}
                    style={{ cursor: 'pointer', marginTop: '3px' }}
                    onClick={() => setShowTravelPathLabel(!showTravelPathLabel)}
                >
                    <i
                        className={
                            showTravelPathLabel
                                ? showTravelPathLabelBtnTooltip === travelPathLabelTooltip
                                    ? 'RS_LABEL'
                                    : 'RS_LABEL_RED'
                                : 'RS_LABEL_GR'
                        }
                    ></i>
                </div>
            ),
            Cell: ({ cell, row }) => {
                if (props.serviceDay.value === 0 && !row.getParentRow()) return null; //do not show the icons if its a parent row
                return (
                    row.original.Id !== 'No Route' && (
                        <div
                            title='Show arrow head travel path'
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!row.original.checked) {
                                    handleToggle(row.index, row.getParentRow());
                                }
                                toggleMapTravelPathLayer(row.original.Id, row.original.fullLabel);
                            }}
                        >
                            <i
                                className={
                                    serviceDay.value === 0
                                        ? selTravelPathRoute === row.original.fullLabel + '|1'
                                            ? 'RS_TRAVEL_PATH'
                                            : 'RS_TRAVEL_PATH_GR'
                                        : selTravelPathRoute === row.original.Id + '|1'
                                        ? 'RS_TRAVEL_PATH'
                                        : 'RS_TRAVEL_PATH_GR'
                                }
                            ></i>
                        </div>
                    )
                );
            },
        },
        {
            header: '',
            accessorKey: 'sequence',
            size: sequenceW,
            minSize: sequenceW,
            maxSize: sequenceW,
            enableGlobalFilter: false,
            visible: serviceDay.value !== 0,
            Header: ({ column }) => (
                <div
                    title='Show sequence labels'
                    style={{ cursor: 'pointer', marginTop: '3px' }}
                    onClick={() => setShowSequenceLabel(!showSequenceLabel)}
                >
                    <i className={showSequenceLabel ? 'RS_LABEL' : 'RS_LABEL_GR'}></i>
                </div>
            ),
            Cell: ({ cell, row }) => {
                if (props.serviceDay.value === 0 && !row.getParentRow()) return null; //do not show the icons if its a parent row
                return (
                    row.original.Id !== 'No Route' && (
                        <div
                            title='Show sequence'
                            style={{ cursor: 'pointer' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                if (!row.original.checked) {
                                    handleToggle(row.index, row.getParentRow());
                                }
                                toggleMapSequenceLayer(row.original.Id, row.original.fullLabel);
                            }}
                        >
                            <i
                                className={
                                    serviceDay.value === 0
                                        ? selSequenceRoute === row.original.fullLabel
                                            ? 'RS_SEQUENCE'
                                            : 'RS_SEQUENCE_GR'
                                        : selSequenceRoute === row.original.Id
                                        ? 'RS_SEQUENCE'
                                        : 'RS_SEQUENCE_GR'
                                }
                            ></i>
                        </div>
                    )
                );
            },
        },
        {
            header: '',
            accessorKey: 'zoom',
            enableGlobalFilter: false,
            size: zoomW,
            minSize: zoomW,
            maxSize: zoomW,
            Cell: ({ cell, row }) => {
                return (
                    <div
                        title={showZoomLabel(!!row.getParentRow())}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!row.original.checked) {
                                handleToggle(row.index, row.getParentRow());
                            }
                            handleZoomToRoute(row.original.Id === 'No Route' ? '0' : row.original.Id, row.original.fullLabel);
                        }}
                    >
                        <i className='RS_ZOOM'></i>
                    </div>
                );
            },
        },
    ];

    const renderRouteViewTabCategorizationSelect = () => {
        //only render the drop down if the service day is all day
        if (props.serviceDay?.value === 0) {
            return (
                <ReactSelect
                    isClearable={false}
                    isSearchable
                    value={props.routeViewTabCategorization}
                    onChange={(e) => props.handleRouteTabViewCategorySelection(e)}
                    options={ROUTE_VIEW_TAB_CATEGORIES}
                    menuPortalTarget={document.body}
                    styles={{
                        control: (base) => ({
                            ...base,
                            minHeight: '30px',
                        }),
                        container: (base) => ({
                            ...base,
                            width: 170,
                        }),
                        valueContainer: (baseStyles) => ({
                            ...baseStyles,
                            padding: '0px 8px',
                        }),
                        dropdownIndicator: (base) => ({
                            ...base,
                            padding: `4px !important`,
                        }),
                        menuPortal: (provided) => ({ ...provided, zIndex: 5 }),
                    }}
                />
            );
        } else {
            return null;
        }
    };

    const renderSelectedRouteCountLabel = () => {
        if (serviceDay?.value === 0) {
            return null;
        } else {
            let selected = tableData.filter((r) => r.checked === true);
            return (
                <label>
                    <b>Routes: </b>
                    {selected.length}
                    {` selected of `}
                    {tableData.length}
                    {` routes `}
                </label>
            );
        }
    };

    return (
        <>
            <ToggleButtonGroup
                style={{ paddingTop: '5px' }}
                exclusive
                onChange={(e, v) => selMapToolChangeHandler(v)}
            >
                {checkFunction(208) && (
                    <ToggleButton
                        value='RouteSummary'
                        title='View Summary'
                        style={
                            bottomPaneVisibility && bottomPaneGrid === 'RouteSummary'
                                ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 18, padding: '5px' }
                                : { fontSize: 18, padding: '5px', filter: 'grayscale(1)' }
                        }
                        disabled={alleyAssignmentMode}
                    >
                        <span className='RS_ROUTE_SUMMARY' />
                    </ToggleButton>
                )}
                {checkFunction(211) && (
                    <ToggleButton
                        value='GeocodeServiceLocations'
                        title='Stops'
                        style={
                            bottomPaneVisibility && bottomPaneGrid === 'ServiceLocationList'
                                ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 18, padding: '5px' }
                                : { fontSize: 18, padding: '5px', filter: 'grayscale(1)' }
                        }
                        disabled={alleyAssignmentMode}
                    >
                        <span className='RS_GEOCODE_SERVICE_LOCATION' />
                    </ToggleButton>
                )}
                {checkFunction(212) && (
                    <ToggleButton
                        value='GeocodeFacilities'
                        title='Facility Management'
                        style={
                            bottomPaneVisibility && bottomPaneGrid === 'FacilityList'
                                ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 18, padding: '5px' }
                                : { fontSize: 18, padding: '5px', filter: 'grayscale(1)' }
                        }
                        disabled={alleyAssignmentMode}
                    >
                        <span className='RS_MANAGE_FACILITY' />
                    </ToggleButton>
                )}
                {siteMode === 'PLANNER' && (
                    <ToggleButton
                        value='ProjectGrid'
                        title='Project Management'
                        style={
                            bottomPaneVisibility && bottomPaneGrid === 'ProjectGrid'
                                ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 18, padding: '5px' }
                                : { fontSize: 18, padding: '5px', filter: 'grayscale(1)' }
                        }
                        disabled={alleyAssignmentMode}
                    >
                        <span className='RS_MANAGE_PROJECTS' />
                    </ToggleButton>
                )}
                {siteMode === 'PLANNER' && (
                    <ToggleButton
                        value='ScenarioGrid'
                        title='Scenario Management'
                        style={
                            bottomPaneVisibility && bottomPaneGrid === 'ScenarioGrid'
                                ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 18, padding: '5px' }
                                : { fontSize: 18, padding: '5px', filter: 'grayscale(1)' }
                        }
                        disabled={alleyAssignmentMode}
                    >
                        <span className='RS_MANAGE_SCENARIOS' />
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
            <div style={{ whiteSpace: 'nowrap', paddingTop: '5px', paddingBottom: '5px' }}>{renderSelectedRouteCountLabel()}</div>
            <Paper>
                <Box
                    sx={{
                        padding: '2px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                ></Box>
                <div style={{ maxWidth: '100%' }}>
                    {/* <Box sx={{ display: 'flex', }}>
                       
                    {tableInstanceRef.current && <MRT_GlobalFilterTextField table={tableInstanceRef.current} />}
                    </Box> */}
                    <MaterialReactTable
                        state={{
                            isLoading: props.isRouteViewTabLoading,
                            expanded: true, //all subrow need to be in an expanded state
                            columnVisibility: { 'mrt-row-expand': false }, //remove expanding carrot
                        }}
                        filterFromLeafRows
                        columns={columns}
                        data={tableDataShown}
                        enableStickyHeader={true}
                        enableSorting={false}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableColumnOrdering={false}
                        enableColumnResizing={false}
                        enableHiding={false}
                        enableFilterMatchHighlighting={false}
                        positionToolbarAlertBanner='none'
                        initialState={{
                            density: 'compact',
                            showGlobalFilter: true,
                        }}
                        defaultColumn={{
                            maxSize: 30,
                            minSize: 30,
                            size: 30,
                        }}
                        muiTopToolbarProps={{
                            sx: {
                                height: '40px',
                                minHeight: '40px',
                            },
                        }}
                        muiTableBodyRowProps={({ row }) => ({
                            onClick: row.getToggleSelectedHandler(),
                        })}
                        muiTableHeadCellProps={{
                            sx: {
                                fontWeight: 'normal',
                                fontSize: '12px',
                                padding: 0,
                            },
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                fontWeight: 'normal',
                                fontSize: '12px',
                                padding: 0,
                                paddingLeft: 0,
                            },
                        }}
                        muiSearchTextFieldProps={{
                            sx: { maxWidth: '100px' },
                            InputProps: {
                                sx: { fontSize: '12px', height: '30px' },
                            },
                            variant: 'outlined',
                        }}
                        muiTableProps={{
                            sx: { tableLayout: 'fixed' },
                        }}
                        muiTableContainerProps={{
                            sx: { maxHeight: '205px', minHeight: '205px', overflowX: 'hidden' },
                        }}
                        muiTablePaperProps={{
                            sx: {
                                boxShadow: 'initial',
                                borderRadius: 'initial',
                                // border-radius: 4px;
                                // box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                            },
                        }}
                        tableInstanceRef={tableInstanceRef} //get access to the underlying table instance ref
                        //add custom top tool bar
                        renderTopToolbarCustomActions={() => {
                            return (
                                <Box sx={{ display: 'flex', gap: '5px', marginRight: '5px' }}>
                                    <IconButton
                                        value='ZoomToSelRoutes'
                                        title={serviceDay.value !== 0 ? 'Zoom to selected routes' : 'Zoom to selected days'}
                                        style={{ padding: '2px', fontSize: '12px' }}
                                        onClick={handleZoomToSelectedRoutes}
                                    >
                                        <span className='RS_ZOOM_TO_SELECT' />
                                    </IconButton>
                                    {renderRouteViewTabCategorizationSelect()}
                                </Box>
                            );
                        }}
                    />
                </div>
            </Paper>
        </>
    );
};
