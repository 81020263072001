import React from 'react';
import { Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Button } from '@mui/material';
import TerrainOutlinedIcon from '@mui/icons-material/Terrain';

export const ServiceAreaBoundaryWidget = (props) => {
    const { serviceAreaBoundary, isServiceAreaBoundaryWidgetClicked, openServiceAreaBoundaryTool, closeServiceAreaBoundaryTool } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (data) => {
        setAnchorEl(null);
        closeServiceAreaBoundaryTool(data);
    };

    return (
        <>
            <div
                id='serviceAreaBoundaryWidget'
                className={
                    isServiceAreaBoundaryWidgetClicked
                        ? `esri-draw esri-widget--button disable-select serviceAreaBoundaryWidget esri-component serviceAreaBoundaryWidget--state-selected`
                        : `esri-draw esri-widget--button disable-select serviceAreaBoundaryWidget esri-component`
                }
                role={'button'}
                tabIndex={0}
                title={'Service Area Boundary tool'}
                onClick={(e) => {
                    if (!isServiceAreaBoundaryWidgetClicked) {
                        openServiceAreaBoundaryTool(e);
                        handleClick(e);
                    } else {
                        handleClose('CANCEL');
                    }
                }}
            >
                <div
                    style={
                        isServiceAreaBoundaryWidgetClicked
                            ? {
                                  backgroundColor: `#16a8a6 !important`,
                                  color: `#fff !important`,
                                  cursor: 'pointer',
                              }
                            : { cursor: 'pointer' }
                    }
                >
                    <TerrainOutlinedIcon />
                    <span className='esri-icon-font-fallback-text'>{'Service Area Boundary tool'}</span>
                </div>
            </div>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: -215,
                }}
                keepMounted
                open={anchorEl ? true : false}
                onClose={() => handleClose('CANCEL')}
            >
                <MenuItem disabled>Service Area Boundary</MenuItem>
                {!serviceAreaBoundary && <MenuItem onClick={() => handleClose('CREATE')}>Create New Boundary</MenuItem>}
                {serviceAreaBoundary && <MenuItem onClick={() => handleClose('SHOW')}>Show Current Boundary</MenuItem>}
                {serviceAreaBoundary && <MenuItem onClick={() => handleClose('EDIT')}>Edit Current Boundary</MenuItem>}
                {serviceAreaBoundary && <MenuItem onClick={() => handleClose('DELETE')}>Delete Current Boundary</MenuItem>}
            </Menu>
        </>
    );
};

export const ReshapeDialogServiceAreaBoundary = (props) => {
    const { open, yes, back, cancel } = props;
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            scroll={'paper'}
            className={'disable-select'}
            open={open}
        >
            <DialogTitle id='customized-dialog-title'>RESHAPE SERVICE AREA BOUNDARY</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to reshape this Service Area Boundary?</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={yes}>Yes</Button>
                <Button onClick={back}>Back</Button>
                <Button onClick={cancel}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export const DeleteDialogServiceAreaBoundary = (props) => {
    const { open, yes, no } = props;
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            scroll={'paper'}
            className={'disable-select'}
            open={open}
        >
            <DialogTitle id='customized-dialog-title'>DELETE CURRENT WAYPOINT BOUNDARY</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you want to delete the current Service Area Boundary for the workspace?</Typography>
            </DialogContent>

            <DialogActions>
                <Button onClick={no}>No</Button>
                <Button onClick={yes}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};
