import React from 'react';
import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';
import { ProtectedRoutes, handlePublicRoute, handlePrivateRoute } from './ProtectedRoutes';
import { Login } from '../Login/Login';
import { Main } from '../Main/Main';
import { MapPage } from '../Map/MapController';
import { Manage } from '../Manage/ManageController';
import { AccountManagement } from '../AccountManagement/AccountManagementController';
import { Administration } from '../Administration/AdministrationController';

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path='/'>
            <Route
                path='/'
                element={<ProtectedRoutes />}
            >
                <Route
                    path='/'
                    element={<Main />}
                    loader={() => handlePrivateRoute()}
                >
                    <Route
                        path='/'
                        element={
                            <Navigate
                                replace
                                to='/map'
                            />
                        }
                        loader={() => handlePrivateRoute('Map')}
                    />
                    <Route
                        path='map'
                        element={<MapPage />}
                        loader={() => handlePrivateRoute('Map')}
                    />
                    <Route
                        path='manage'
                        element={<Manage />}
                        loader={() => handlePrivateRoute('Manage')}
                    />
                    <Route
                        path='account_management'
                        element={<AccountManagement />}
                        loader={() => handlePrivateRoute('Account Management')}
                    />
                    <Route
                        path='administration'
                        element={<Administration />}
                        loader={() => handlePrivateRoute('Administration')}
                    />
                </Route>
            </Route>
            <Route
                path='login'
                element={<Login />}
                loader={async () => await handlePublicRoute()}
            />
            <Route
                path='*'
                element={<Login />}
                loader={async () => await handlePublicRoute()}
            />
        </Route>,
    ),
);

export const MainRoutes = () => {
    return <RouterProvider router={router} />;
};
