import React from 'react';
import { Navigate, Outlet, redirect } from 'react-router-dom';
import { axiosDefault } from '../../_helpers/auth-header';
import { config } from '../../_helpers/config';
import { allTabs } from '../../_helpers/common';

const isAuthenticated = () => {
    const user = sessionStorage.getItem('userInfo');
    let isAuth = false;
    if (user) {
        let userInfo = JSON.parse(user);
        if (userInfo.accessToken && userInfo.industry === 'Public Works') {
            isAuth = true;
        }
    }
    return isAuth;
};

export const ProtectedRoutes = () => {
    const user = sessionStorage.getItem('userInfo');
    let isAuth = false;
    if (user) {
        let userInfo = JSON.parse(user);
        if (userInfo.accessToken && userInfo.industry === 'Public Works') {
            isAuth = true;
        }
    }
    return isAuth ? <Outlet /> : <Navigate to='/login' />;
};

export const handlePublicRoute = async () => {
    if (isAuthenticated()) throw redirect('/');
    return null;
};

export const handlePrivateRoute = async (pathTo) => {
    //if there is a pathTo parameter passed, check to make sure the the user is allowed to be on that page.
    if (pathTo) {
        let userInfo = sessionStorage.getItem('userInfo');
        if (userInfo) {
            try {
                userInfo = JSON.parse(userInfo);
                if (userInfo.accessToken) {
                    const url = config.ROApi + 'pagefunctions/AssignableFunctions';
                    let { data } = await axiosDefault.get(url);
                    if (Array.isArray(data)) {
                        // list of all function names from the response payload
                        let functionNames = [...new Set(data.map((v) => v.FunctionName))];

                        if (!functionNames.some((v) => v === pathTo)) {
                            //dictionary of functionName to pathName
                            const functionNameToPathName = allTabs.reduce((acc, v) => {
                                acc[v.functionName] = v.path;
                                return acc;
                            }, {});
                            //redirect to the first found on the dictionary or go to /login
                            return redirect(functionNameToPathName[functionNames[0]] || '/login');
                        }
                    }
                }
            } catch (e) {
                sessionStorage.removeItem('userInfo');
                throw redirect('/login');
            }
        }
    }

    if (!isAuthenticated()) throw redirect('/login');
    return null;
};
