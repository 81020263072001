import React from 'react';
import { RODialog } from '../../../common';

const AddEditUsers = (props) => {
    return (
        <RODialog
            maxWidth='xs'
            fullWidth={true}
            open={props.open}
            dialogTitle={props.title}
            dialogContent={props.dialogContent}
            dialogActions={props.dialogActions}
        />
    );
};

export default AddEditUsers;
