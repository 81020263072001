import React from 'react';
import Grid from '@mui/material/Grid';
import DataGrid, { Column, Sorting, Pager, Paging, FilterRow, HeaderFilter, SearchPanel, Toolbar, Item, Scrolling } from 'devextreme-react/data-grid';
import DxButton from 'devextreme-react/button';
import TextArea from 'devextreme-react/text-area';
import SelectBox from 'devextreme-react/select-box';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import TreeList, { Column as TlColumn, HeaderFilter as TlHeaderFilter, SearchPanel as TlSearchPanel } from 'devextreme-react/tree-list';
import {
    getRoles,
    getAssignableFunctions,
    getAssignableRoles,
    getAssignableGroups,
    getRoleAssignedFunctions,
    getRoleAssignedRoles,
    getRoleAssignedGroups,
    addRole,
    updateRole,
    deleteRole,
} from '../../../_actions/AccountManagement.actions';
import { updateUserActivity } from '../../../_actions/Global.actions';
import { RODialogLabel, GlobalROContext } from '../../common';
import { getErrorMessage } from '../../../_helpers/common';
import { config } from '../../../_helpers/config';
import AddEditRoles from './Dialogs/AddEdit';
import DeleteRoles from './Dialogs/Delete';

const Roles = (props) => {
    const refRoleListGrid = React.useRef();
    const refRoleList = React.useRef();
    const refGroupList = React.useRef();
    const refSelFunctionSelectEvent = React.useRef();
    const refSelRoleSelectEvent = React.useRef();
    const refSelGroupSelectEvent = React.useRef();
    const [assignableRoles, setAssignableRoles] = React.useState(null);
    const [assignableGroups, setAssignableGroups] = React.useState(null);
    const [roleData, setRoleData] = React.useState(null);
    const [assignableFunctions, setAssignableFunctions] = React.useState(null);
    const [expandedFunctionKeys, setExpandedFunctionKeys] = React.useState([]);
    const [selFunctionKeys, setSelFunctionKeys] = React.useState([]);
    const [hasAccountManagementAccess, setHasAccountManagementAccess] = React.useState(false);
    const [selRoleKeys, setSelRoleKeys] = React.useState([]);
    const [selGroupKeys, setSelGroupKeys] = React.useState([]);
    const [showEditRoleDialog, setShowEditRoleDialog] = React.useState(false);
    const [showDeleteRoleDialog, setShowDeleteRoleDialog] = React.useState(false);
    const [roleMode, setRoleMode] = React.useState('');
    const [roleTarget, setRoleTarget] = React.useState({});
    const { showApiROErrorDialog, setShowApiROErrorDialog } = React.useContext(GlobalROContext);

    const populateRoles = async () => {
        const rolesResponse = await getRoles();
        if (rolesResponse && rolesResponse.data && rolesResponse.data.length > 0) {
            setRoleData(rolesResponse.data);
        } else {
            setRoleData([]);
        }
    };

    React.useEffect(() => {
        populateRoles();
    }, []);

    const handleFunctionRowClick = (e) => {
        let selFuncKeys = [...selFunctionKeys];
        if (selFuncKeys.includes(e.data.id)) {
            if (e.data.parent !== 0) {
                let otherSubFunctionsSelected = false;
                let subFunctions = assignableFunctions.filter((item) => item.parent === e.data.parent);
                for (let i = 0; i < subFunctions.length; i++) {
                    if (selFuncKeys.includes(subFunctions[i].id) && subFunctions[i].id !== e.data.id) {
                        otherSubFunctionsSelected = true;
                        break;
                    }
                }
                if (otherSubFunctionsSelected) {
                    //Unselect function
                    selFuncKeys = selFuncKeys.filter((item) => item != e.data.id);
                } else {
                    //Unselect function then unselect parent function if no sub functions are selected
                    selFuncKeys = selFuncKeys.filter((item) => item !== e.data.id && item !== e.data.parent);
                }
            } else {
                let subFuncKeys = [];
                let subFunctions = assignableFunctions.filter((item) => item.parent === e.data.id);
                subFunctions.forEach((it) => {
                    subFuncKeys.push(it.id);
                });
                //Unselect function and sub functions when parent function is unselected
                selFuncKeys = selFuncKeys.filter((item) => item != e.data.id && !subFuncKeys.includes(item));
            }
        } else {
            selFuncKeys.push(e.data.id);
            if (e.data.parent !== 0) {
                //Select parent function if not selected
                if (!selFuncKeys.includes(e.data.parent)) {
                    selFuncKeys.push(e.data.parent);
                }
            } else {
                //Select sub functions when parent function is selected
                let subFuncKeys = assignableFunctions.filter((item) => item.parent === e.data.id);
                subFuncKeys.forEach((it) => {
                    selFuncKeys.push(it.id);
                });
            }
        }
        refSelFunctionSelectEvent.current.value = 'RowClick';
        setSelFunctionKeys(selFuncKeys);
        checkAccountManagementAccess(selFuncKeys);
    };

    //Roles - start
    React.useEffect(() => {
        if (refRoleList && refRoleList.current && refRoleList.current.instance) {
            refRoleList.current.instance.refresh();
        }
        if (refGroupList && refGroupList.current && refGroupList.current.instance) {
            refGroupList.current.instance.refresh();
        }
    }, [hasAccountManagementAccess, selRoleKeys]);

    const updateRoleForm = (field, value) => {
        let t = { ...roleTarget };
        t[field] = value;
        setRoleTarget(t);
    };

    const handleEditRole = async (mode, data) => {
        try {
            if (refRoleListGrid && refRoleListGrid.current && refRoleListGrid.current.instance) {
                refRoleListGrid.current.instance.beginCustomLoading();
            }
            const assignFunctions = await getAssignableFunctions();
            let functions = [];
            let expandKeys = [];
            let parentFunctions = {};
            assignFunctions.data.forEach((item) => {
                if (
                    item.FunctionName !== '' &&
                    !(config.FUNCTIONS_TO_HIDE_ROLE.includes(item.FunctionName) || config.FUNCTIONS_TO_HIDE_ROLE.includes(item.SubFunctionName))
                ) {
                    if (item.SubFunctionName === '') {
                        //TreeList does not support a key value of 0. Replacing to 1 at load. Replace 1 with 0 while saving.
                        functions.push({ id: item.FunctionID === '0' ? 1 : parseInt(item.FunctionID), text: item.FunctionName, parent: 0 });
                        if (!Object.keys(parentFunctions).includes(item.FunctionName)) {
                            parentFunctions[item.FunctionName] = parseInt(item.FunctionID);
                        }
                    } else {
                        if (!expandKeys.includes(parentFunctions[item.FunctionName])) {
                            expandKeys.push(parentFunctions[item.FunctionName]);
                        }
                        functions.push({ id: parseInt(item.FunctionID), text: item.SubFunctionName, parent: parentFunctions[item.FunctionName] });
                    }
                }
            });
            setAssignableFunctions(functions);
            setExpandedFunctionKeys(expandKeys);
            const assignRoles = await getAssignableRoles();
            let roles = [];
            assignRoles.data.forEach((item) => {
                roles.push({ id: item.RoleID, text: item.RoleName });
            });
            setAssignableRoles(roles);
            const assignGroups = await getAssignableGroups();
            let groups = [];
            assignGroups.data.forEach((item) => {
                groups.push({ id: item.Id, text: item.Name });
            });
            setAssignableGroups(groups);
        } catch (e) {
            console.log(e);
        }

        setRoleMode(mode);
        if (mode === 'New') {
            setSelFunctionKeys([1]);
            setSelRoleKeys([]);
            setSelGroupKeys([]);
            setHasAccountManagementAccess(false);
        } else {
            loadSavedFunctionsRolesGroups(data.RoleID);
        }
        setRoleTarget(data);
        setShowEditRoleDialog(true);
        if (refRoleListGrid && refRoleListGrid.current && refRoleListGrid.current.instance) {
            refRoleListGrid.current.instance.endCustomLoading();
        }
    };

    const loadSavedFunctionsRolesGroups = async (roleId) => {
        try {
            let assignedFunctionsResp = await getRoleAssignedFunctions(roleId);
            if (assignedFunctionsResp && assignedFunctionsResp.data && assignedFunctionsResp.data.length > 0) {
                let assignedFunctions = [];
                let hasAccMgmtAccess = false;
                assignedFunctionsResp.data.forEach((item) => {
                    //TreeList does not support a key value of 0. Replacing to 1 at load. Replace 1 with 0 while saving.
                    assignedFunctions.push(item.FunctionID === '0' ? 1 : parseInt(item.FunctionID));
                    if (item.FunctionID === '904') {
                        hasAccMgmtAccess = true;
                    }
                });
                if (hasAccMgmtAccess) setHasAccountManagementAccess(true);
                else setHasAccountManagementAccess(false);
                setSelFunctionKeys(assignedFunctions);
            }
            let assignedRolesResp = await getRoleAssignedRoles(roleId);
            if (assignedRolesResp && assignedRolesResp.data && assignedRolesResp.data.length > 0) {
                let assignedRoles = [];
                assignedRolesResp.data.forEach((item) => {
                    assignedRoles.push(item.RoleID);
                });
                setSelRoleKeys(assignedRoles);
            }
            let assignedGroupsResp = await getRoleAssignedGroups(roleId);
            if (assignedGroupsResp && assignedGroupsResp.data && assignedGroupsResp.data.length > 0) {
                let assignedGroups = [];
                assignedGroupsResp.data.forEach((item) => {
                    assignedGroups.push(item.Id);
                });
                setSelGroupKeys(assignedGroups);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteRole = (data) => {
        setRoleMode('Delete');
        setRoleTarget(data);
        setShowDeleteRoleDialog(true);
    };

    const handleEditRoleClose = async (option) => {
        if (option === 'CANCEL') {
            setShowEditRoleDialog(false);
            setRoleTarget({});
        } else if (option === 'SAVE') {
            let userInfo = JSON.parse(window.sessionStorage['userInfo']);
            //TreeList does not support a key value of 0. Replaced to 1 at load. Replacing 1 with 0 while saving.
            let selFuncKeys = [...selFunctionKeys];
            if (selFuncKeys.includes(1)) {
                selFuncKeys = selFuncKeys.filter((item) => item != 1);
                selFuncKeys.push(0);
            }
            let assignedFuncIDs = selFuncKeys.join(',');
            let postData = {
                ClientID: userInfo.ClientID,
                RoleName: roleTarget.RoleName,
                RoleDescription: roleTarget.RoleDescription,
                AssignableFunctionIDs: assignedFuncIDs,
            };
            if (selRoleKeys.length > 0) {
                postData.AssignableRoleIDs = selRoleKeys.join(',');
            }
            if (selGroupKeys.length > 0) {
                postData.AssignableGroupIDs = selGroupKeys.join(',');
            }
            let err;
            let ActionId;
            try {
                if (roleMode === 'New') {
                    ActionId = 911;
                    await addRole(postData);
                } else if (roleMode === 'Edit') {
                    ActionId = 913;
                    await updateRole(roleTarget.RoleID, postData);
                }

                setShowEditRoleDialog(false);
                setRoleTarget({});
                populateRoles();
            } catch (e) {
                err = e;
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(err));
                }
            } finally {
                let logData = {
                    ActionId: ActionId,
                    Success: err ? false : true,
                    Metadata: roleTarget.RoleName,
                };
                updateUserActivity(logData);
            }
        }
    };

    const handleDeleteRoleClose = async (option) => {
        if (option === 'CANCEL') {
            setShowDeleteRoleDialog(false);
            setRoleTarget({});
        } else if (option === 'DELETE') {
            let err;
            try {
                await deleteRole(roleTarget.RoleID);
                setShowDeleteRoleDialog(false);
                setRoleTarget({});
                populateRoles();
            } catch (e) {
                err = e;
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(err));
                }
            } finally {
                let logData = {
                    ActionId: 912,
                    Success: err ? false : true,
                    Metadata: roleTarget.RoleName,
                };
                updateUserActivity(logData);
            }
        }
    };

    const checkAccountManagementAccess = (keys) => {
        if (keys.includes(904)) {
            setHasAccountManagementAccess(true);
        } else {
            setSelRoleKeys([]);
            setSelGroupKeys([]);
            setHasAccountManagementAccess(false);
        }
    };

    const getFunctionListSelectAllValue = () => {
        if (selFunctionKeys.length === 0) {
            return false;
        } else if (selFunctionKeys.length === assignableFunctions.length) {
            return true;
        } else {
            return null;
        }
    };

    const getRoleListSelectAllValue = () => {
        if (selRoleKeys.length === 0) {
            return false;
        } else if (selRoleKeys.length === assignableRoles.length) {
            return true;
        } else {
            return null;
        }
    };

    const getGroupListSelectAllValue = () => {
        if (selGroupKeys.length === 0) {
            return false;
        } else if (selGroupKeys.length === assignableGroups.length) {
            return true;
        } else {
            return null;
        }
    };

    return (
        <>
            <DataGrid
                height={props.windowDimensions.height - 275}
                dataSource={roleData}
                keyExpr='RoleID'
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                columnMinWidth={50}
                columnAutoWidth={true}
                ref={refRoleListGrid}
            >
                <SearchPanel
                    visible={true}
                    width={240}
                    placeholder='Search...'
                />
                <HeaderFilter visible={false} />
                <FilterRow
                    visible={false}
                    applyFilter={true}
                />
                <Paging defaultPageSize={15} />
                <Pager
                    visible={true}
                    allowedPageSizes={[15, 25, 50, 100, 'all']}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                />
                <Scrolling mode='virtual' />
                <Sorting mode='multiple' />
                <Toolbar>
                    <Item location='before'>
                        <DxButton
                            hint='Add role'
                            icon='add'
                            text='Add role'
                            onClick={(e) => {
                                handleEditRole('New', {});
                            }}
                        />
                    </Item>
                    <Item
                        location='after'
                        name='searchPanel'
                    />
                </Toolbar>
                <Column dataField='RoleName'></Column>
                <Column
                    dataField='RoleDescription'
                    caption='Description'
                ></Column>
                <Column
                    dataField='NumberOfUsers'
                    caption='No of users'
                ></Column>
                <Column
                    type='buttons'
                    width='80px'
                    cellRender={(e) => {
                        return (
                            <div>
                                <DxButton
                                    hint='Edit role'
                                    icon='edit'
                                    onClick={() => {
                                        handleEditRole('Edit', e.data);
                                    }}
                                />
                                &nbsp;
                                <DxButton
                                    hint='Delete role'
                                    icon='trash'
                                    type='danger'
                                    disabled={e.data.NumberOfUsers > 0 ? true : false}
                                    onClick={() => {
                                        handleDeleteRole(e.data);
                                    }}
                                />
                            </div>
                        );
                    }}
                ></Column>
            </DataGrid>

            {/* ROLES dialogs */}
            {/* //////////////////////////////// */}
            {/* add or edit*/}
            {showEditRoleDialog && (
                <AddEditRoles
                    open={showEditRoleDialog}
                    title={`${roleMode} Role`}
                    handleClick={handleEditRoleClose}
                    roleTarget={roleTarget}
                >
                    <Grid
                        container
                        spacing={'10px'}
                        style={{ paddingTop: '10px' }}
                    >
                        <Grid
                            item
                            xs={8}
                        >
                            <RODialogLabel>Role Name *</RODialogLabel>
                            <TextBox
                                name='RoleName'
                                inputAttr={{ autocomplete: 'new-role' }}
                                defaultValue={roleTarget.RoleName}
                                onValueChanged={(e) => {
                                    updateRoleForm('RoleName', e.value);
                                }}
                            />
                        </Grid>
                        {roleMode === 'New' && (
                            <Grid
                                item
                                xs={4}
                            >
                                <RODialogLabel>Copy from</RODialogLabel>
                                <SelectBox
                                    dataSource={assignableRoles}
                                    displayExpr='text'
                                    valueExpr='id'
                                    onValueChanged={async (e) => {
                                        loadSavedFunctionsRolesGroups(e.value);
                                    }}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                        >
                            <RODialogLabel>Role Description</RODialogLabel>
                            <TextArea
                                name='RoleDescription'
                                height={60}
                                defaultValue={roleTarget.RoleDescription}
                                onValueChanged={(e) => {
                                    updateRoleForm('RoleDescription', e.value);
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <div style={{ display: 'none' }}>
                                <input
                                    id='SelFunctionSelectEvent'
                                    type='text'
                                    ref={refSelFunctionSelectEvent}
                                />
                            </div>
                            <TreeList
                                dataSource={assignableFunctions}
                                height={400}
                                showBorders={true}
                                showColumnLines={false}
                                columnAutoWidth={true}
                                wordWrapEnabled={true}
                                defaultExpandedRowKeys={expandedFunctionKeys}
                                selectedRowKeys={selFunctionKeys}
                                keyExpr='id'
                                parentIdExpr='parent'
                                id='functions'
                            >
                                <TlSearchPanel visible={true} />
                                <TlHeaderFilter visible={false} />
                                {/* <TlSelection mode="multiple" recursive={true} /> */}
                                <TlColumn
                                    dataField='id'
                                    caption=''
                                    width='55px'
                                    allowSorting={false}
                                    allowFiltering={false}
                                    visibleIndex='0'
                                    headerCellRender={(e) => {
                                        return (
                                            <CheckBox
                                                value={getFunctionListSelectAllValue()}
                                                onValueChanged={(e) => {
                                                    if (e.event) {
                                                        if (refSelFunctionSelectEvent && refSelFunctionSelectEvent.current.value !== 'RowClick') {
                                                            let selKeys = [];
                                                            if (e.value) {
                                                                selKeys = assignableFunctions.map((it) => it.id);
                                                            } else {
                                                                selKeys = [1];
                                                            }
                                                            setSelFunctionKeys(selKeys);
                                                            checkAccountManagementAccess(selKeys);
                                                        } else {
                                                            refSelFunctionSelectEvent.current.value = 'AllClick';
                                                        }
                                                    } else {
                                                        refSelFunctionSelectEvent.current.value = 'AllClick';
                                                    }
                                                }}
                                            />
                                        );
                                    }}
                                    cellRender={(e) => {
                                        return (
                                            <div
                                                style={{ align: 'left' }}
                                                onClick={() => {
                                                    handleFunctionRowClick(e);
                                                }}
                                            >
                                                <CheckBox value={selFunctionKeys.includes(e.data.id)} />
                                            </div>
                                        );
                                    }}
                                />
                                <TlColumn
                                    dataField='text'
                                    caption='Functions'
                                    cellRender={(e) => {
                                        return (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    handleFunctionRowClick(e);
                                                }}
                                            >
                                                {e.data.parent !== 0 && <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{e.data.text}</>}
                                                {e.data.parent === 0 && <>&nbsp;&nbsp;&nbsp;{e.data.text}</>}
                                            </div>
                                        );
                                    }}
                                ></TlColumn>
                            </TreeList>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <div style={{ display: 'none' }}>
                                <input
                                    id='SelRoleSelectEvent'
                                    type='text'
                                    ref={refSelRoleSelectEvent}
                                />
                            </div>
                            <TreeList
                                dataSource={assignableRoles}
                                height={400}
                                showBorders={true}
                                showColumnLines={false}
                                columnAutoWidth={true}
                                wordWrapEnabled={true}
                                selectedRowKeys={selRoleKeys}
                                keyExpr='id'
                                id='roles'
                                ref={refRoleList}
                                onRowClick={(e) => {
                                    if (hasAccountManagementAccess) {
                                        let selKeys = [...selRoleKeys];
                                        if (selKeys.includes(e.key)) {
                                            selKeys = selKeys.filter((item) => item != e.key);
                                        } else {
                                            selKeys.push(e.key);
                                        }
                                        refSelRoleSelectEvent.current.value = 'RowClick';
                                        setSelRoleKeys(selKeys);
                                    }
                                }}
                            >
                                <TlSearchPanel visible={true} />
                                <TlHeaderFilter visible={false} />
                                <TlColumn
                                    dataField='id'
                                    caption=''
                                    width='0px'
                                    allowSorting={false}
                                    allowFiltering={false}
                                    visibleIndex='0'
                                    cellRender={() => {
                                        return <div></div>;
                                    }}
                                />
                                <TlColumn
                                    dataField='role'
                                    caption=''
                                    width='30px'
                                    allowSorting={false}
                                    allowFiltering={false}
                                    visibleIndex='0'
                                    headerCellRender={(e) => {
                                        return (
                                            <CheckBox
                                                disabled={!hasAccountManagementAccess}
                                                value={getRoleListSelectAllValue()}
                                                onValueChanged={(e) => {
                                                    if (refSelRoleSelectEvent && refSelRoleSelectEvent.current.value !== 'RowClick') {
                                                        let selKeys = [];
                                                        if (e.value) {
                                                            selKeys = assignableRoles.map((it) => it.id);
                                                        } else {
                                                            selKeys = [];
                                                        }
                                                        setSelRoleKeys(selKeys);
                                                    } else {
                                                        refSelRoleSelectEvent.current.value = 'AllClick';
                                                    }
                                                }}
                                            />
                                        );
                                    }}
                                    cellRender={(evt) => {
                                        return (
                                            <div style={{ align: 'left' }}>
                                                <CheckBox
                                                    disabled={!hasAccountManagementAccess}
                                                    value={selRoleKeys.includes(evt.data.id)}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                <TlColumn
                                    dataField='text'
                                    caption='Roles'
                                    cellRender={(e) => {
                                        return (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    if (hasAccountManagementAccess) {
                                                        let selKeys = [...selRoleKeys];
                                                        if (selKeys.includes(e.data.id)) {
                                                            selKeys = selKeys.filter((item) => item != e.data.id);
                                                        } else {
                                                            selKeys.push(e.data.id);
                                                        }
                                                        setSelRoleKeys(selKeys);
                                                    }
                                                }}
                                            >
                                                {e.data.text}
                                            </div>
                                        );
                                    }}
                                ></TlColumn>
                            </TreeList>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <div style={{ display: 'none' }}>
                                <input
                                    id='SelGroupSelectEvent'
                                    type='text'
                                    ref={refSelGroupSelectEvent}
                                />
                            </div>
                            <TreeList
                                dataSource={assignableGroups}
                                height={400}
                                showBorders={true}
                                showColumnLines={false}
                                columnAutoWidth={true}
                                wordWrapEnabled={true}
                                defaultSelectedRowKeys={selGroupKeys}
                                keyExpr='id'
                                id='groups'
                                ref={refGroupList}
                                onRowClick={(e) => {
                                    if (hasAccountManagementAccess) {
                                        let selKeys = [...selGroupKeys];
                                        if (selKeys.includes(e.key)) {
                                            selKeys = selKeys.filter((item) => item != e.key);
                                        } else {
                                            selKeys.push(e.key);
                                        }
                                        refSelGroupSelectEvent.current.value = 'RowClick';
                                        setSelGroupKeys(selKeys);
                                    }
                                }}
                            >
                                <TlSearchPanel visible={true} />
                                <TlHeaderFilter visible={false} />
                                <TlColumn
                                    dataField='id'
                                    caption=''
                                    width='0px'
                                    allowSorting={false}
                                    allowFiltering={false}
                                    visibleIndex='0'
                                    cellRender={() => {
                                        return <div></div>;
                                    }}
                                />
                                <TlColumn
                                    dataField='group'
                                    caption=''
                                    width='30px'
                                    allowSorting={false}
                                    allowFiltering={false}
                                    visibleIndex='0'
                                    headerCellRender={(e) => {
                                        return (
                                            <CheckBox
                                                disabled={!hasAccountManagementAccess}
                                                value={getGroupListSelectAllValue()}
                                                onValueChanged={(e) => {
                                                    if (refSelGroupSelectEvent && refSelGroupSelectEvent.current.value !== 'RowClick') {
                                                        let selKeys = [];
                                                        if (e.value) {
                                                            selKeys = assignableGroups.map((it) => it.id);
                                                        } else {
                                                            selKeys = [];
                                                        }
                                                        setSelGroupKeys(selKeys);
                                                    } else {
                                                        refSelGroupSelectEvent.current.value = 'AllClick';
                                                    }
                                                }}
                                            />
                                        );
                                    }}
                                    cellRender={(evt) => {
                                        return (
                                            <div style={{ align: 'left' }}>
                                                <CheckBox
                                                    disabled={!hasAccountManagementAccess}
                                                    value={selGroupKeys.includes(evt.data.id)}
                                                />
                                            </div>
                                        );
                                    }}
                                />
                                <TlColumn
                                    dataField='text'
                                    caption='Groups'
                                    visibleIndex='1'
                                    cellRender={(e) => {
                                        return (
                                            <div
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    if (hasAccountManagementAccess) {
                                                        let selKeys = [...selGroupKeys];
                                                        if (selKeys.includes(e.data.id)) {
                                                            selKeys = selKeys.filter((item) => item != e.data.id);
                                                        } else {
                                                            selKeys.push(e.data.id);
                                                        }
                                                        setSelGroupKeys(selKeys);
                                                    }
                                                }}
                                            >
                                                {e.data.text}
                                            </div>
                                        );
                                    }}
                                ></TlColumn>
                            </TreeList>
                        </Grid>
                    </Grid>
                </AddEditRoles>
            )}
            {/* //////////////////////////////// */}
            {/* delete*/}
            {showDeleteRoleDialog && (
                <DeleteRoles
                    open={showDeleteRoleDialog}
                    title={`${roleMode} Role`}
                    handleClick={handleDeleteRoleClose}
                    roleTarget={roleTarget}
                />
            )}
            {/* //////////////////////////////// */}
        </>
    );
};

export default Roles;
