import React, { useEffect, useRef } from 'react';
import {
    IconButton,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    DialogActions,
    FormControlLabel,
    FormControl,
    FormGroup,
    Checkbox,
    MenuItem,
    Select,
    Box,
} from '@mui/material';
import { Info as InfoIcon, Close as CloseIcon, Add as AddIcon, Save as SaveIcon, PlayCircleOutline as LoadIcon } from '@mui/icons-material';
import MaterialReactTable, { MRT_GlobalFilterTextField, MRT_ShowHideColumnsButton } from 'material-react-table';
import ReactSelect from 'react-select';
import {
    createProject,
    updateProject,
    deleteProject,
    getProjectRouteList,
    createScenario,
    updateScenario,
    deleteScenario,
    loadScenario,
} from '../../_actions/MapPage.actions';
import { defaultServiceDayList, getFormattedTime } from '../../_helpers/common';
import { ExportToCsv } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const CreateProjectDialog = (props) => {
    const {
        createProjectDialogOpen,
        handleCreateProjectDialogClose,
        routingProjectRouteList,
        projectName,
        handleChangeProjectName,
        projectRouteSelection,
        setProjectRouteSelection,
        projectServiceDay,
        handleProjectServiceDayChanged,
        shareProject,
        handleChangeShareProject,
    } = props;

    return (
        <Dialog
            open={createProjectDialogOpen}
            maxWidth={'xs'}
            id={'CreateProjectDialog'}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {'CREATE PROJECT'}
                <IconButton
                    id={'closeButton'}
                    aria-label='close'
                    onClick={() => handleCreateProjectDialogClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControlLabel
                            control={
                                <TextField
                                    variant='outlined'
                                    value={projectName}
                                    onChange={handleChangeProjectName}
                                    sx={{ height: '30px', marginLeft: '10px' }}
                                />
                            }
                            sx={{ height: '30px', margin: '10px 16px' }}
                            label={<b>Project Name: </b>}
                            labelPlacement='start'
                            id={'projectNameInput'}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControl
                            style={{ margin: '10px 0' }}
                            variant='standard'
                        >
                            <FormGroup row>
                                <FormControlLabel
                                    value='start'
                                    control={
                                        <div style={{ width: '223px', marginLeft: '18px' }}>
                                            <ReactSelect
                                                className='basic-single'
                                                classNamePrefix='select'
                                                isClearable={false}
                                                isSearchable={true}
                                                name='Project_Serviceday_Select'
                                                id='Project_Serviceday_Select'
                                                value={
                                                    projectServiceDay ? defaultServiceDayList.find((s) => s.value == projectServiceDay.value) : null
                                                }
                                                onChange={handleProjectServiceDayChanged}
                                                options={defaultServiceDayList}
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    option: (provided, state) => ({
                                                        ...provided,
                                                        display: state.data.display ? '' : 'none',
                                                        padding: 5,
                                                    }),
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                }}
                                            />
                                        </div>
                                    }
                                    label={<b>Service Day:&nbsp;</b>}
                                    labelPlacement='start'
                                />
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <div
                            id='ProjectSelectionTable'
                            style={{ maxWidth: '100%', padding: '5px', height: '280px', marginTop: '10px' }}
                        >
                            <MaterialReactTable
                                columns={[{ header: 'Routes', accessorKey: 'Id' }]}
                                data={routingProjectRouteList}
                                enableTopToolbar={false}
                                enableStickyHeader={true}
                                enableColumnFilters={false}
                                enablePagination={false}
                                enableBottomToolbar={false}
                                enableColumnActions={false}
                                enableFullScreenToggle={false}
                                enableDensityToggle={false}
                                enableHiding={false}
                                enableRowSelection={true}
                                selectAllMode={'all'}
                                getRowId={(originalRow) => originalRow.Id}
                                onRowSelectionChange={setProjectRouteSelection}
                                initialState={{
                                    rowSelection: {},
                                    density: 'compact',
                                    showGlobalFilter: false,
                                    sorting: [{ id: 'Id', desc: false }],
                                }}
                                state={{
                                    rowSelection: projectRouteSelection,
                                }}
                                muiTableContainerProps={{
                                    sx: { maxHeight: '260px' },
                                }}
                                muiSelectAllCheckboxProps={{
                                    color: 'default',
                                    title: '',
                                }}
                                muiSelectCheckboxProps={{
                                    color: 'default',
                                    title: '',
                                }}
                            />
                        </div>

                        <FormControlLabel
                            label={<span style={{ marginLeft: '10px' }}>Share project</span>}
                            style={{ fontSize: 18, marginLeft: '2px', marginRight: '10px' }}
                            control={
                                <Checkbox
                                    name='anchorWithServiceDay'
                                    checked={shareProject}
                                    onChange={(e) => handleChangeShareProject(e)}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleCreateProjectDialogClose('CREATE')}
                >
                    CREATE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const UpdateProjectDialog = (props) => {
    const { updateProjectDialogOpen, handleUpdateProjectDialogClose, projectName, handleChangeProjectName, shareProject, handleChangeShareProject } =
        props;

    return (
        <Dialog
            open={updateProjectDialogOpen}
            maxWidth={'md'}
            id={'UpdateProjectDialog'}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {'UPDATE PROJECT'}
                <IconButton
                    id={'closeButton'}
                    aria-label='close'
                    onClick={() => handleUpdateProjectDialogClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControlLabel
                            control={
                                <TextField
                                    variant='outlined'
                                    value={projectName}
                                    onChange={handleChangeProjectName}
                                    sx={{ height: '30px', marginLeft: '10px' }}
                                />
                            }
                            sx={{ height: '30px', margin: '10px' }}
                            label={<b>Project Name: </b>}
                            labelPlacement='start'
                            id={'projectNameInput'}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControlLabel
                            label={<span style={{ marginLeft: '10px' }}>Share project</span>}
                            style={{ fontSize: 18, marginLeft: '2px', marginRight: '10px' }}
                            control={
                                <Checkbox
                                    name='shareProject'
                                    checked={shareProject}
                                    onChange={(e) => handleChangeShareProject(e)}
                                />
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleUpdateProjectDialogClose('UPDATE')}
                >
                    UPDATE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DeleteProjectDialog = (props) => {
    const { deleteProjectDialogOpen, targetProject, handleDeleteProjectDialogClose } = props;
    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={deleteProjectDialogOpen}
        >
            <DialogTitle>Delete Project</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        {targetProject && (
                            <Typography>
                                Are you sure you wish to delete project <b>{targetProject.SandboxName}</b> ?
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDeleteProjectDialogClose('CANCEL')}>Cancel</Button>
                <Button onClick={() => handleDeleteProjectDialogClose('CONFIRM')}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

export const ProjectTable = (props) => {
    let userInfo = sessionStorage.getItem('userInfo');
    if (userInfo) userInfo = JSON.parse(userInfo);
    const {
        workspaceId,
        routingProjects,
        setProject,
        setRefreshProject,
        setRefreshScenario,
        bottomHeight,
        setOpenAlert,
        setAlertTitle,
        setAlertMessage,
        setGlobalLoading,
        project,
        serviceDay,
    } = props;
    const [createProjectDialogOpen, setCreateProjectDialogOpen] = React.useState(false);
    const [updateProjectDialogOpen, setUpdateProjectDialogOpen] = React.useState(false);
    const [deleteProjectDialogOpen, setDeleteProjectDialogOpen] = React.useState(false);
    const [projectName, setProjectName] = React.useState('');
    const [projectServiceDay, setProjectServiceDay] = React.useState(null);
    const [shareProject, setShareProject] = React.useState(false);

    const [targetProject, setTargetProject] = React.useState(null);
    const [routingProjectRouteList, setRoutingProjectRouteList] = React.useState([]);
    const [projectRouteSelection, setProjectRouteSelection] = React.useState({});

    const [sandboxGridView, setSandboxGridView] = React.useState('M');

    const [columns, setColumns] = React.useState([]);

    React.useEffect(() => {
        if (project) {
            let c = [
                {
                    header: '',
                    accessorKey: 'actions',
                    enableSorting: false,
                    muiTableBodyCellProps: { align: 'center', sx: { padding: '0' } },
                    size: 50,
                    Cell: ({ cell, row }) => {
                        if (userInfo.UserID === row.original.CreatedByUserName || row.original.Share === true) {
                            return (
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    {project != row.original.ID && (
                                        <IconButton
                                            title='Open'
                                            color='primary'
                                            className={'icon_button'}
                                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                            onClick={() => setProject(row.original.ID)}
                                        >
                                            <LoadIcon color='primary' />
                                        </IconButton>
                                    )}
                                    <IconButton
                                        title='Edit'
                                        color='primary'
                                        className={'icon_button'}
                                        style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                        onClick={() => handleUpdateProject(row.original)}
                                    >
                                        <i className='RS_EDIT' />
                                    </IconButton>
                                    {project != row.original.ID && (
                                        <IconButton
                                            title='Delete'
                                            color='primary'
                                            className={'icon_button'}
                                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                            onClick={() => handleDeleteProject(row.original)}
                                        >
                                            <i className='RS_DELETE' />
                                        </IconButton>
                                    )}
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    },
                },
                {
                    header: 'Name',
                    accessorKey: 'SandboxName',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                },
                {
                    header: 'Date Created',
                    accessorKey: 'DateCreated',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                },
                {
                    header: 'Created By',
                    accessorKey: 'CreatedByUserName',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                },
                {
                    header: 'Initial Route Count',
                    accessorKey: 'RouteCount',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                },
                {
                    header: 'Stop Count',
                    accessorKey: 'SlCount',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                },
                {
                    header: 'Service Days',
                    accessorKey: 'ServiceDays',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                },
                {
                    header: 'Share',
                    accessorKey: 'Share',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                    Cell: ({ cell, row }) => {
                        return cell.getValue() ? 'Yes' : 'No';
                    },
                },
                {
                    header: 'Last Modified By',
                    accessorKey: 'ModifiedByUserName',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                },
                {
                    header: 'Date Modified',
                    accessorKey: 'DateModified',
                    size: 50,
                    muiTableHeadCellProps: { align: 'center' },
                    muiTableBodyCellProps: { align: 'center' },
                },
            ];
            setColumns(c);
        }
    }, [project]);

    React.useEffect(() => {
        if (projectServiceDay && projectServiceDay.value !== null) {
            setProjectRouteSelection({});
            getProjectRouteList(workspaceId, projectServiceDay.value).then(
                (res) => {
                    setRoutingProjectRouteList(res.data);
                },
                () => {
                    setRoutingProjectRouteList([]);
                },
            );
        }
    }, [projectServiceDay]);

    React.useEffect(() => {
        setRefreshProject(sandboxGridView);
    }, [sandboxGridView]);

    const handleCreateProject = () => {
        setProjectName('');
        setProjectRouteSelection({});
        setProjectServiceDay(serviceDay);
        setCreateProjectDialogOpen(true);
    };

    const handleUpdateProject = (data) => {
        setProjectName(data.SandboxName);
        setShareProject(data.Share);
        setTargetProject(data);
        setUpdateProjectDialogOpen(true);
    };

    const handleDeleteProject = (data) => {
        setTargetProject(data);
        setDeleteProjectDialogOpen(true);
    };

    const handleChangeProjectName = (e) => {
        setProjectName(e.target.value);
    };

    const handleChangeShareProject = (e) => {
        setShareProject(e.target.checked);
    };

    const handleProjectServiceDayChanged = (e) => {
        setProjectServiceDay(e);
    };

    const handleCreateProjectDialogClose = (mode) => {
        if (mode === 'CREATE') {
            let selectedRoutes = [];
            Object.keys(projectRouteSelection).forEach((key, index) => {
                selectedRoutes.push(key);
            });
            const data = {
                SandboxName: projectName,
                ServiceDays: projectServiceDay.label,
                SlFilterRoutes: selectedRoutes.toString(),
            };
            setGlobalLoading(true);
            createProject(workspaceId, data).then(
                (projectRes) => {
                    const sandboxId = projectRes.data.ID;
                    const scenarioData = {
                        ScenarioName: 'Initial scenario',
                    };
                    createScenario(workspaceId, sandboxId, scenarioData).then(
                        (result) => {
                            setRefreshProject(sandboxGridView);
                            setRefreshScenario(true);
                            setProjectName('');
                            setProjectRouteSelection({});
                            setCreateProjectDialogOpen(false);
                            setGlobalLoading(false);
                        },
                        (err) => {
                            setRefreshProject(sandboxGridView);
                            setProjectName('');
                            setProjectRouteSelection({});
                            setCreateProjectDialogOpen(false);
                            setOpenAlert(true);
                            setAlertTitle('Error');
                            setAlertMessage(JSON.stringify(err.response.data));
                            setGlobalLoading(false);
                        },
                    );
                },
                (err) => {
                    // setProjectName("");
                    // setProjectRouteSelection({});
                    // setCreateProjectDialogOpen(false);
                    setOpenAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(JSON.stringify(err.response.data));
                    setGlobalLoading(false);
                },
            );
        } else {
            setProjectName('');
            setProjectRouteSelection({});
            setCreateProjectDialogOpen(false);
        }
    };

    const handleUpdateProjectDialogClose = (mode) => {
        if (mode === 'UPDATE') {
            const data = {
                SandboxName: projectName,
                Share: shareProject,
            };
            setGlobalLoading(true);
            updateProject(workspaceId, targetProject.ID, data).then(
                (res) => {
                    setRefreshProject(sandboxGridView);
                    setProjectName('');
                    setShareProject(false);
                    setTargetProject(null);
                    setUpdateProjectDialogOpen(false);
                    setGlobalLoading(false);
                },
                (err) => {
                    // setProjectName("");
                    // setTargetProject(null);
                    // setUpdateProjectDialogOpen(false);
                    setOpenAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(JSON.stringify(err.response.data));
                    setGlobalLoading(false);
                },
            );
        } else {
            setProjectName('');
            setShareProject(false);
            setTargetProject(null);
            setUpdateProjectDialogOpen(false);
        }
    };

    const handleDeleteProjectDialogClose = (mode) => {
        if (mode === 'CONFIRM') {
            setGlobalLoading(true);
            deleteProject(workspaceId, targetProject.ID).then(
                (res) => {
                    setRefreshProject(sandboxGridView);
                    setTargetProject(null);
                    setDeleteProjectDialogOpen(false);
                    setGlobalLoading(false);
                },
                (err) => {
                    setTargetProject(null);
                    setDeleteProjectDialogOpen(false);
                    setOpenAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(JSON.stringify(err.response.data));
                    setGlobalLoading(false);
                },
            );
        } else {
            setTargetProject(null);
            setDeleteProjectDialogOpen(false);
        }
    };

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={routingProjects}
                enableTopToolbar={true}
                enableStickyHeader={true}
                enableColumnFilters={false}
                enablePagination={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={false}
                initialState={{
                    density: 'compact',
                    showGlobalFilter: true,
                    sorting: [{ id: 'SandboxName', desc: false }],
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: bottomHeight - 100 },
                }}
                muiSelectAllCheckboxProps={{
                    color: 'default',
                    title: '',
                }}
                muiSelectCheckboxProps={{
                    color: 'default',
                    title: '',
                }}
                muiTableBodyRowProps={({ row }) => {
                    if (row.original.Share === false && userInfo.UserID !== row.original.CreatedByUserName) {
                        return { sx: { backgroundColor: '#ddd' } };
                    }
                }}
                renderTopToolbarCustomActions={() => (
                    <div>
                        <span style={{ fontSize: '20px', fontWeight: '600', marginRight: '20px' }}>{'Projects'}</span>
                        {/* <IconButton variant="outlined" title={'Create Project'} onClick={() => handleCreateProject()}>
                        <AddIcon />
                    </IconButton> */}
                        <FormControl sx={{ minWidth: 120, margin: 0 }}>
                            <FormControlLabel
                                control={
                                    <Select
                                        value={sandboxGridView}
                                        onChange={(e) => setSandboxGridView(e.target.value)}
                                        displayEmpty
                                        sx={{ height: 36, fontSize: '10px' }}
                                    >
                                        <MenuItem
                                            style={{ fontSize: '10px' }}
                                            value={'M'}
                                        >
                                            My Projects
                                        </MenuItem>
                                        <MenuItem
                                            style={{ fontSize: '10px' }}
                                            value={'A'}
                                        >
                                            All Projects
                                        </MenuItem>
                                        <MenuItem
                                            style={{ fontSize: '10px' }}
                                            value={'E'}
                                        >
                                            Editable Projects
                                        </MenuItem>
                                    </Select>
                                }
                                label={
                                    <div style={{ display: 'flex' }}>
                                        <b style={{ fontSize: '12px', marginTop: '3px', marginRight: '5px' }}>&nbsp;View: &nbsp;</b>
                                    </div>
                                }
                                labelPlacement='start'
                            />
                        </FormControl>
                    </div>
                )}
            />
            <CreateProjectDialog
                createProjectDialogOpen={createProjectDialogOpen}
                handleCreateProjectDialogClose={handleCreateProjectDialogClose}
                routingProjectRouteList={routingProjectRouteList}
                projectRouteSelection={projectRouteSelection}
                projectName={projectName}
                projectServiceDay={projectServiceDay}
                shareProject={shareProject}
                handleChangeShareProject={handleChangeShareProject}
                handleChangeProjectName={handleChangeProjectName}
                setProjectRouteSelection={setProjectRouteSelection}
                handleProjectServiceDayChanged={handleProjectServiceDayChanged}
            />
            <UpdateProjectDialog
                updateProjectDialogOpen={updateProjectDialogOpen}
                projectName={projectName}
                shareProject={shareProject}
                handleUpdateProjectDialogClose={handleUpdateProjectDialogClose}
                handleChangeProjectName={handleChangeProjectName}
                handleChangeShareProject={handleChangeShareProject}
            />
            <DeleteProjectDialog
                deleteProjectDialogOpen={deleteProjectDialogOpen}
                targetProject={targetProject}
                handleDeleteProjectDialogClose={handleDeleteProjectDialogClose}
            />
        </div>
    );
};

const UpdateScenarioDialog = (props) => {
    const { updateOption, updateScenarioDialogOpen, handleUpdateScenarioDialogClose, scenarioName, handleChangeScenarioName } = props;

    return (
        <Dialog
            open={updateScenarioDialogOpen}
            maxWidth={'md'}
            id={'UpdateScenarioDialog'}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                {updateOption + ' SCENARIO'}
                <IconButton
                    id={'closeButton'}
                    aria-label='close'
                    onClick={() => handleUpdateScenarioDialogClose('CANCEL')}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        <FormControlLabel
                            control={
                                <TextField
                                    variant='outlined'
                                    value={scenarioName}
                                    onChange={handleChangeScenarioName}
                                    sx={{ height: '30px', marginLeft: '10px' }}
                                />
                            }
                            sx={{ height: '30px', margin: '10px' }}
                            label={<b>Scenario Name: </b>}
                            labelPlacement='start'
                            id={'scenarioNameInput'}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleUpdateScenarioDialogClose(updateOption)}
                >
                    SAVE
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DeleteScenarioDialog = (props) => {
    const { deleteScenarioDialogOpen, targetScenario, handleDeleteScenarioDialogClose } = props;
    return (
        <Dialog
            maxWidth={'sm'}
            fullWidth={true}
            open={deleteScenarioDialogOpen}
        >
            <DialogTitle>Delete Scenario</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid
                        item
                        xs={12}
                    >
                        {targetScenario && (
                            <Typography>
                                Are you sure you wish to delete scenario <b>{targetScenario.ScenarioName}</b> ?
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDeleteScenarioDialogClose('CANCEL')}>Cancel</Button>
                <Button onClick={() => handleDeleteScenarioDialogClose('CONFIRM')}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

export const ScenarioTable = (props) => {
    const {
        workspace,
        project,
        routingProjectScenarios,
        setRefreshScenario,
        routingProjects,
        setOpenAlert,
        setAlertTitle,
        setAlertMessage,
        setGlobalLoading,
        setLoadScenario,
        setRefreshProject,
        bottomHeight,
        refreshRouteSummary,
        refreshServiceLocationList,
        refreshServiceLocationsInMap,
        servicePatternView,
        refreshServicePatternInMap,
        refreshSwapRouteList,
        blockFaceLayerVisbility,
        refreshRouteBlockfaceLayer,
    } = props;

    const isFirstRender = useRef(true);

    const [updateOption, setUpdateOption] = React.useState(null);
    const [targetScenario, setTargetScenario] = React.useState(null);
    const [scenarioName, setScenarioName] = React.useState('');

    const [updateScenarioDialogOpen, setUpdateScenarioDialogOpen] = React.useState(false);
    const [deleteScenarioDialogOpen, setDeleteScenarioDialogOpen] = React.useState(false);

    const [columnVisibility, setColumnVisibility] = React.useState({});

    useEffect(() => {
        const columnVisibility = localStorage.getItem('scenarioTable_columnVisibility');
        if (columnVisibility) {
            setColumnVisibility(JSON.parse(columnVisibility));
        }
        isFirstRender.current = false;
        setRefreshScenario(true);
    }, []);

    useEffect(() => {
        if (isFirstRender.current) return;
        localStorage.setItem('scenarioTable_columnVisibility', JSON.stringify(columnVisibility));
    }, [columnVisibility]);

    const columns = [
        {
            header: 'Actions',
            accessorKey: 'actions',
            enableSorting: false,
            enableHiding: false,
            muiTableHeadCellProps: { style: { visibility: 'hidden' } },
            muiTableBodyCellProps: { align: 'center', sx: { padding: '0' } },
            size: 50,
            Cell: ({ cell, row }) => {
                return (
                    <div style={{ whiteSpace: 'nowrap' }}>
                        <IconButton
                            title='Load'
                            color='primary'
                            className={'icon_button'}
                            style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                            onClick={() => handleLoadScenario(row.original.ID)}
                        >
                            <LoadIcon color='primary' />
                        </IconButton>
                        {row.original.ScenarioName !== 'Initial scenario' && (
                            <IconButton
                                title='Edit'
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => handlUpdateScenario(row.original)}
                            >
                                <i className='RS_EDIT' />
                            </IconButton>
                        )}
                        {row.original.ScenarioName !== 'Initial scenario' && (
                            <IconButton
                                title='Delete'
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => handlDeleteScenario(row.original)}
                            >
                                <i className='RS_DELETE' />
                            </IconButton>
                        )}
                    </div>
                );
            },
        },
        {
            header: 'Scenario Name',
            accessorKey: 'ScenarioName',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
        },
        {
            header: 'Number of Service Days',
            accessorKey: 'NumServiceDays',
            size: 120,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
        },
        {
            header: 'Number of Routes',
            accessorKey: 'RouteCount',
            size: 80,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
        },
        {
            header: 'Stops',
            accessorKey: 'TotalStops',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
        },
        {
            header: 'Demand',
            accessorKey: 'TotalDemand',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return cell.getValue().toFixed(0);
                } else {
                    return 0;
                }
            },
        },
        {
            header: 'Trips',
            accessorKey: 'TotalTrips',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
        },
        {
            header: 'Total Time',
            accessorKey: 'TotalTime',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            Cell: ({ cell, row }) => {
                let time = '00:00:00';
                if (cell.getValue() !== null) {
                    time = getFormattedTime(cell.getValue());
                }
                return time;
            },
        },
        {
            header: 'Total Distance',
            accessorKey: 'TotalDistance',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return cell.getValue().toFixed(2);
                } else {
                    return 0;
                }
            },
        },
        {
            header: 'Total Cost',
            accessorKey: 'TotalCost',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
            Cell: ({ cell }) => {
                if (cell.getValue()) {
                    return '$' + cell.getValue().toFixed(2);
                } else {
                    return '$' + 0;
                }
            },
        },
        {
            header: 'Date Created',
            accessorKey: 'DateCreated',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
        },
        {
            header: 'Created By',
            accessorKey: 'CreatedBy',
            size: 50,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center' },
        },
        // {
        //     header: 'Health Score', accessorKey: 'HS_Weighted', size: 50,
        //     muiTableHeadCellProps: { align: 'center' },
        //     muiTableBodyCellProps: { align: 'center' },
        //     Cell: ({ cell, row }) => {
        //         if (cell.getValue() !== null) {
        //             return (
        //                 <Typography color="inherit">{cell.getValue()}
        //                     <HtmlTooltip
        //                         title={
        //                             <>
        //                                 <Typography color="inherit">Balance: {row.original.HS_Balance}</Typography>
        //                                 <Typography color="inherit">Efficiency: {row.original.HS_Compactness}</Typography>
        //                                 <Typography color="inherit">Compactness: {row.original.HS_Interlacing}</Typography>
        //                             </>
        //                         }
        //                     >
        //                         <InfoIcon sx={{ fontSize: '16px', marginLeft: '5px', verticalAlign: 'text-bottom' }} />
        //                     </HtmlTooltip>
        //                 </Typography>
        //             )
        //         } else {
        //             return "";
        //         }
        //     }
        // }
    ];

    const handleCreateScenario = () => {
        setUpdateOption('CREATE');
        setUpdateScenarioDialogOpen(true);
    };

    const handlUpdateScenario = (data) => {
        setUpdateOption('UPDATE');
        setTargetScenario(data);
        setScenarioName(data.ScenarioName);
        setUpdateScenarioDialogOpen(true);
    };

    const handlDeleteScenario = (data) => {
        setTargetScenario(data);
        setDeleteScenarioDialogOpen(true);
    };

    const handleUpdateScenarioDialogClose = (mode) => {
        if (mode === 'CREATE') {
            const data = {
                ScenarioName: scenarioName,
            };
            setGlobalLoading(true);
            createScenario(workspace.value, project, data).then(
                (result) => {
                    setRefreshProject(true);
                    setRefreshScenario(true);
                    setScenarioName('');
                    setTargetScenario(null);
                    setUpdateScenarioDialogOpen(false);
                    setUpdateOption(null);
                    setGlobalLoading(false);
                },
                (err) => {
                    setOpenAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(JSON.stringify(err.response.data));
                    setGlobalLoading(false);
                },
            );
        } else if (mode === 'UPDATE') {
            const data = {
                ScenarioName: scenarioName,
            };
            setGlobalLoading(true);
            updateScenario(workspace.value, project, targetScenario.ID, data).then(
                (result) => {
                    setRefreshScenario(true);
                    setScenarioName('');
                    setTargetScenario(null);
                    setUpdateScenarioDialogOpen(false);
                    setUpdateOption(null);
                    setGlobalLoading(false);
                },
                (err) => {
                    setOpenAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(JSON.stringify(err.response.data));
                    setGlobalLoading(false);
                },
            );
        } else {
            setScenarioName('');
            setTargetScenario(null);
            setUpdateScenarioDialogOpen(false);
            setUpdateOption(null);
        }
    };

    const handleLoadScenario = (id) => {
        setGlobalLoading(true);
        loadScenario(workspace.value, project, id).then(
            (res) => {
                refreshRouteSummary();
                refreshServiceLocationList();
                refreshSwapRouteList();
                if (servicePatternView) {
                    refreshServicePatternInMap();
                } else {
                    refreshServiceLocationsInMap();
                }
                if (blockFaceLayerVisbility) refreshRouteBlockfaceLayer();
                setRefreshProject(true);
                setLoadScenario(true);
                setGlobalLoading(false);
            },
            (err) => {
                setOpenAlert(true);
                setAlertTitle('Error');
                setAlertMessage(JSON.stringify(err.response.data));
                setGlobalLoading(false);
            },
        );
    };

    const handleDeleteScenarioDialogClose = (mode) => {
        if (mode === 'CONFIRM') {
            setGlobalLoading(true);
            deleteScenario(workspace.value, project, targetScenario.ID).then(
                (res) => {
                    setRefreshProject(true);
                    setRefreshScenario(true);
                    setTargetScenario(null);
                    setDeleteScenarioDialogOpen(false);
                    setGlobalLoading(false);
                },
                (err) => {
                    setTargetScenario(null);
                    setDeleteScenarioDialogOpen(false);
                    setOpenAlert(true);
                    setAlertTitle('Error');
                    setAlertMessage(JSON.stringify(err.response.data));
                    setGlobalLoading(false);
                },
            );
        } else {
            setTargetScenario(null);
            setDeleteScenarioDialogOpen(false);
        }
    };

    const handleChangeScenarioName = (e) => {
        setScenarioName(e.target.value);
    };

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: `${workspace.label}_${project}_Scenario Table`,
        useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportData = () => {
        let result = [...routingProjectScenarios];
        // result = result.map(r => {
        //     r.ServiceDay = r.ServiceDayLabel;
        //     delete r.ServiceDayLabel;
        //     return r;
        // });
        csvExporter.generateCsv(result);
    };

    return (
        <div>
            <MaterialReactTable
                columns={columns}
                data={routingProjectScenarios}
                enableTopToolbar={true}
                enableStickyHeader={true}
                enableColumnFilters={false}
                enablePagination={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableHiding={true}
                initialState={{
                    density: 'compact',
                    showGlobalFilter: true,
                    sorting: [{ id: 'ScenarioName', desc: false }],
                    columnVisibility: { DateCreated: false, CreatedBy: false },
                }}
                state={{
                    columnVisibility,
                }}
                onColumnVisibilityChange={setColumnVisibility}
                muiTableContainerProps={{
                    sx: { maxHeight: bottomHeight - 100 },
                }}
                muiTableHeadCellProps={{
                    sx: { fontSize: '12px' },
                }}
                muiTableBodyCellProps={{
                    sx: { fontSize: '12px' },
                }}
                muiSelectAllCheckboxProps={{
                    color: 'default',
                    title: '',
                }}
                muiSelectCheckboxProps={{
                    color: 'default',
                    title: '',
                }}
                renderTopToolbar={({ table }) => (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span style={{ fontSize: '20px', fontWeight: '600', marginRight: '20px' }}>{'Scenarios'}</span>
                        <IconButton
                            variant='outlined'
                            title={'Save Scenario'}
                            onClick={() => handleCreateScenario()}
                        >
                            <SaveIcon />
                        </IconButton>
                        <IconButton
                            variant='outlined'
                            style={{ marginLeft: 'auto' }}
                            title={'Export to CSV'}
                            disabled={routingProjectScenarios.length === 0}
                            onClick={handleExportData}
                        >
                            <FileDownloadIcon />
                        </IconButton>
                        <MRT_ShowHideColumnsButton table={table} />
                        <MRT_GlobalFilterTextField table={table} />
                    </Box>
                )}
                muiTableBodyRowProps={({ row }) => {
                    let currentScenario = 0;
                    if (row) {
                        if (project) {
                            let currentProject = routingProjects.find((p) => p.ID == project);
                            if (currentProject) {
                                currentScenario = currentProject.ScenarioId;
                            }
                        }
                    }
                    return {
                        sx: {
                            backgroundColor: currentScenario === row.original.ID ? 'rgba(155, 255, 155, 0.18)' : '',
                            fontStyle: currentScenario === row.original.ID ? 'italic' : '',
                        },
                    };
                }}
            />
            <UpdateScenarioDialog
                updateOption={updateOption}
                updateScenarioDialogOpen={updateScenarioDialogOpen}
                scenarioName={scenarioName}
                handleUpdateScenarioDialogClose={handleUpdateScenarioDialogClose}
                handleChangeScenarioName={handleChangeScenarioName}
            />
            <DeleteScenarioDialog
                deleteScenarioDialogOpen={deleteScenarioDialogOpen}
                targetScenario={targetScenario}
                handleDeleteScenarioDialogClose={handleDeleteScenarioDialogClose}
            />
        </div>
    );
};
