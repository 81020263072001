import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const LanguagePicker = (props) => {
    const {language, changeLanguage} = props;
    return (
        <Box sx={{ width: 50, textAlign: 'center', marginTop: '15px' }}>
            <FormControl fullWidth>
                <Select
                    id="language-select"
                    value={language}
                    onChange={(e) => {changeLanguage(e); window.location.reload()}}
                    IconComponent= {() => null} 
                    inputProps={{ sx: { padding: '0 !important'}} }
                    sx={{paddingTop: '4px', border: 'none'}}
                    variant='standard'
                    disableUnderline='true'
                >
                    <MenuItem value={'en'}><img src="https://admin.dev.routesmartonline.com/clients/css/images/LangBar/enUS.png" width="25" height="25" /></MenuItem>
                    <MenuItem value={'es'}><img src="https://admin.dev.routesmartonline.com/clients/css/images/LangBar/esES.png" width="25" height="25" /></MenuItem>
                    {/* <MenuItem value={'zh'}><img src="https://admin.dev.routesmartonline.com/clients/css/images/LangBar/zhCN.png" width="25" height="25" /></MenuItem> */}
                </Select>
            </FormControl>
        </Box>
    );
}