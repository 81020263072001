import React, { useEffect, useState } from 'react';
import { startTimeOptions } from '../../_helpers/common';
import { postDispatch, getRouteSetServiceDay } from '../../_actions/MapPage.actions';
import { SelectBox } from 'devextreme-react';
import { Grid, Button, Typography, CircularProgress, Box } from '@mui/material';
import { getRouteList } from '../../_actions/Global.actions';

export const SplitRoutes = (props) => {
    const {
        serviceDayList,
        splitSeqList,
        dispatchFacilityList,
        splitFormData,
        splitStartTime,
        splitLoadIndicatorVisible,
        workspace,
        defSplitFormData,
        updateSplitFormData,
        setSplitLoadIndicatorVisible,
        refreshScenarioList,
        setSelDispatchTool,
        refreshSplitSeqList,
        setSplitStartTime,
        setSplitFormData,
        setOpenMapAlert,
        setMapAlertTitle,
        setMapAlertMessage,
    } = props;

    const [routeList, setRouteList] = useState([]);

    const handleSplitRoute = () => {
        if (!splitLoadIndicatorVisible && splitFormData.Routes && splitFormData.Routes.length > 0) {
            setSplitLoadIndicatorVisible(true);
            splitFormData.WorkspaceID = workspace.value;
            splitFormData.StartTime = splitStartTime;
            postDispatch('Split', splitFormData).then(
                (res) => {
                    if (res.status === 200) {
                        setSplitLoadIndicatorVisible(false);
                        refreshScenarioList(true);
                        setSplitFormData(defSplitFormData);
                        setSplitStartTime(null);
                        setSelDispatchTool('SavedScenarios');
                    }
                },
                (err) => {
                    console.log(err);
                    setSplitLoadIndicatorVisible(false);
                    setOpenMapAlert(true);
                    setMapAlertTitle('Error');
                    setMapAlertMessage(`${JSON.stringify(err.response.data)}`);
                },
            );
        }
    };

    useEffect(() => {
        const day = new Date().getDay() === 0 ? 7 : new Date().getDay();
        getRouteList(workspace.value, day).then((res) => {
            let routes = [];
            if (res && res.data) {
                res.data.forEach((r) => {
                    routes.push({
                        Id: r.Id,
                        Label: r.Id,
                    });
                });
                setRouteList(routes);
            }
        });
    }, []);

    return (
        <div style={{ maxWidth: '100%', paddingTop: '5px', height: '278px' }}>
            <Grid
                container
                spacing={'5px'}
                style={{ paddingTop: '5px' }}
            >
                <Grid
                    item
                    xs={4}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Service Day:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <SelectBox
                        dataSource={serviceDayList}
                        disabled={true}
                        searchEnabled={true}
                        searchMode={'contains'}
                        placeholder='Select service day...'
                        displayExpr='label'
                        valueExpr='value'
                        width='186px'
                        value={splitFormData.ServiceDay}
                        onValueChanged={(e) => {
                            updateSplitFormData('ServiceDay', e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Route:{' '}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <SelectBox
                        dataSource={routeList}
                        searchEnabled={true}
                        searchMode={'contains'}
                        placeholder='Select route to split...'
                        displayExpr='Id'
                        valueExpr='Id'
                        width='186px'
                        value={splitFormData.Routes}
                        onValueChanged={(e) => {
                            updateSplitFormData('Routes', e.value);
                            let sd = serviceDayList.find((a) => a.value === splitFormData.ServiceDay);
                            if (sd) {
                                getRouteSetServiceDay(workspace.value, e.value, sd.serviceDay).then(
                                    (res) => {
                                        setSplitStartTime(res.data.StartTime);
                                    },
                                    () => {},
                                );
                            }
                            refreshSplitSeqList(e.value);
                        }}
                    ></SelectBox>
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Seq #:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <SelectBox
                        dataSource={splitSeqList}
                        searchEnabled={true}
                        searchMode={'contains'}
                        displayExpr='Id'
                        valueExpr='Id'
                        width='100px'
                        value={splitFormData.SequenceNum}
                        disabled={!splitFormData.Routes}
                        onValueChanged={(e) => {
                            updateSplitFormData('SequenceNum', e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Pieces:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <SelectBox
                        dataSource={[...Array(9).keys()].map((i) => ({ Id: i + 2 }))}
                        searchEnabled={true}
                        searchMode={'contains'}
                        displayExpr='Id'
                        valueExpr='Id'
                        width='100px'
                        value={splitFormData.NumberOfRoutes}
                        onValueChanged={(e) => {
                            updateSplitFormData('NumberOfRoutes', e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Begins at:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <SelectBox
                        dataSource={dispatchFacilityList}
                        searchEnabled={true}
                        searchMode={'contains'}
                        placeholder='Select start location...'
                        displayExpr='Label'
                        valueExpr='Id'
                        width='186px'
                        value={splitFormData.OfficeID}
                        onValueChanged={(e) => {
                            updateSplitFormData('OfficeID', e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Start Time:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <SelectBox
                        dataSource={startTimeOptions}
                        searchEnabled={true}
                        searchMode={'contains'}
                        displayExpr='label'
                        valueExpr='value'
                        width='186px'
                        value={splitStartTime}
                        onValueChanged={(e) => {
                            setSplitStartTime(e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'center', marginTop: '10px' }}
                >
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button
                            variant='contained'
                            style={
                                splitFormData.Routes && splitFormData.Routes.length > 0 && !splitLoadIndicatorVisible
                                    ? { backgroundColor: '#f77c2b' }
                                    : { backgroundColor: '#eee' }
                            }
                            disabled={splitLoadIndicatorVisible || !(splitFormData.Routes && splitFormData.Routes.length > 0)}
                            onClick={() => handleSplitRoute()}
                        >
                            Split
                        </Button>
                        {splitLoadIndicatorVisible && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};
