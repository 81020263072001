import axios from 'axios';

export const axiosDefault = axios.create();
axiosDefault.defaults.timeout = 300 * 60 * 1000;

axiosDefault.interceptors.request.use((config) => {
    let accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo'])?.accessToken : '';
    // const headers = {
    //     Authorization: 'Bearer ' + accessToken,
    // }
    config.headers['Authorization'] = 'Bearer ' + accessToken;
    return config;
});

axiosDefault.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error && error.response && error.response.status === 401 && process.env.NODE_ENV === 'production') {
            window.sessionStorage.clear();
            window.location.replace(window.location.origin);
        } else {
            return Promise.reject(error);
        }
    },
);

export const axiosHeaders = () => {
    let userInfo = JSON.parse(window.sessionStorage['userInfo']);
    let accessToken = userInfo ? userInfo.accessToken : '';
    let headers = {
        Authorization: 'Bearer ' + accessToken,
    };
    return { headers };
};
