import Graphic from '@arcgis/core/Graphic';
import SearchSource from '@arcgis/core/widgets/Search/SearchSource';
import esriRequest from '@arcgis/core/request';
import * as geometryEngine from '@arcgis/core/geometry/geometryEngine';
import { config } from '../config';

const searchConfig = (apiKey) => {
    if (!apiKey) {
        apiKey = config.HERE_API_KEY_V3;
    }

    return {
        name: 'HERE Geocoding Service',
        placeholder: 'Find address or place',
        suggestionsEnabled: true,
        minSuggestCharacters: 3,
        resultGraphicEnabled: true,
        resultSymbol: {
            type: 'simple-marker',
            style: 'path',
            angle: 0,
            xoffset: 0,
            yoffset: 6,
            size: 14,
            path: 'M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602',
        },
        autoNavigate: true,
        popupEnabled: true,
        popupTemplate: {
            title: 'HERE Search Result',
            content: '{label}',
        },
        getSuggestions: async (params) => {
            return esriRequest(config.HERE_GEOCODING_URL + 'autosuggest', {
                query: {
                    apiKey,
                    q: params.suggestTerm.replace(/ /g, '+'),
                    limit: 6,
                    at: '0,0',
                },
                responseType: 'json',
            }).then(function (results) {
                return results.data.items.map(function (feature) {
                    return {
                        key: 'name',
                        text: feature.title,
                        position: feature.position,
                        sourceIndex: params.sourceIndex,
                    };
                });
            });
        },
        getResults: async (params) => {
            return esriRequest(config.HERE_GEOCODING_URL + 'geocode', {
                query: {
                    apiKey,
                    q: params.suggestResult.text.replace(/ /g, '+'),
                    at: '0,0',
                },
                responseType: 'json',
            }).then(function (results) {
                var searchResults = results.data.items.map(function (feature) {
                    var graphic = new Graphic({
                        geometry: {
                            type: 'point',
                            x: feature.position.lng,
                            y: feature.position.lat,
                        },
                        attributes: feature.address,
                    });

                    var buffer = geometryEngine.geodesicBuffer(graphic.geometry, 500, 'feet');

                    var searchResult = {
                        extent: buffer.extent,
                        feature: graphic,
                        name: feature.title,
                    };
                    return searchResult;
                });
                return searchResults;
            });
        },
    };
};

export const customSearchSource_V2 = new SearchSource(searchConfig(config.HERE_API_KEY_V2));
export const customSearchSource_V3 = new SearchSource(searchConfig());
