import React, { useState, useEffect, useRef, useCallback } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ReactSelect from 'react-select';
import MaterialReactTable from 'material-react-table';
import ListIcon from '@mui/icons-material/List';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ReplayIcon from '@mui/icons-material/Replay';
import ModifiedIcon from '@mui/icons-material/PriorityHigh';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getSequence, getSequenceSolution, updateSequence, submitSandboxSequence, getGapSetting, getRouteList } from '../../_actions/MapPage.actions';
import { signalRService } from '../../_helpers/signalr-connection';

const MAXUNDOSTEP = 10;

export const SequenceDialog = (props) => {
    const {
        workspace,
        project,
        sequenceDialogOpen,
        setSequenceDialogOpen,
        serviceDay,
        siteMode,
        showAPICallbackDialog,
        setIsMapLoading,
        showNotificationCallbackDialog,
    } = props;

    const [sequenceGap, setSequenceGap] = useState(1);

    const [draggingRow, setDraggingRow] = useState(null);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [hoveredTable, setHoveredTable] = useState(null);

    const [data, setData] = useState([]);
    const [sequencedData, setSequencedData] = useState([]);
    const [unsequencedData, setUnsequencedData] = useState([]);
    const [editSequenceServiceDay, setEditSequenceServiceDay] = useState(serviceDay);
    const [editSequenceRoute, setEditSequenceRoute] = useState(null);
    const [editSequenceRouteList, setEditSequenceRouteList] = useState([]);
    const [selectionMode, setSelectionMode] = useState(null);
    const [manualMode, setManualnMode] = useState(false);

    const [showUnsequencedGrid, setShowUnsequencedGrid] = useState(false);

    const [savedSequenceForUndo, setSavedSequenceForUndo] = useState([]);
    const [savedSequenceForRedo, setSavedSequenceForRedo] = useState([]);

    const [prevUnsequencedSelectedRowIndex, setPrevUnsequencedSelectedRowIndex] = useState(null);
    const [prevSequencedSelectedRowIndex, setPrevSequencedSelectedRowIndex] = useState(null);

    const [unsequencedRowSelection, setUnsequencedRowSelection] = useState({});
    const [sequencedRowSelection, setSequencedRowSelection] = useState({});

    const [resequenceDialogOpen, setResequenceDialogOpen] = useState(false);
    const [newSequence, setNewSequence] = useState('');

    const [unSavedConfirmationOpen, setUnSavedConfirmationOpen] = useState(false);
    const [tempFilters, setTempFilters] = useState({});

    const [refreshInput, setRefreshInput] = useState(false);
    const [runSequence, setRunSequence] = useState(false);

    const rowVirtualizerInstanceRef = useRef(null);

    const unsequencedInputRefs = useRef([]);
    const sequencedInputRefs = useRef([]);

    const [buttonAvailability, setButtonAvailability] = useState({
        moveTop: true,
        moveUp: true,
        moveDown: true,
        moveBottom: true,
        reverse: true,
        unsequence: true,
        resequence: true,
        undo: true,
        redo: true,
        reset: true,
    });

    const commonTableProps = {
        autoResetPageIndex: false,
        enableFullScreenToggle: false,
        onDraggingRowChange: setDraggingRow,
        onHoveredRowChange: setHoveredRow,
        initialState: { density: 'compact' },
        enableRowDragging: true,
        enableSorting: false,
        enableColumnFilters: false,
        enablePagination: false,
        enableTopToolbar: false,
        enableBottomToolbar: false,
        enableColumnActions: false,
        enableFullScreenToggle: false,
        enableDensityToggle: false,
        enableColumnOrdering: false,
        enableColumnResizing: false,
        enableSelectAll: true,
        enableRowVirtualization: true,
        rowVirtualizerOptions: { overscan: 5, estimateSize: () => 50 },
    };

    const serviceDayList = [
        { value: 1, label: 'Monday' },
        { value: 2, label: 'Tuesday' },
        { value: 3, label: 'Wednesday' },
        { value: 4, label: 'Thursday' },
        { value: 5, label: 'Friday' },
        { value: 6, label: 'Saturday' },
        { value: 7, label: 'Sunday' },
    ];

    const selectionModeList = [
        { value: 1, label: 'Both Sides' },
        { value: 2, label: 'Same Side' },
    ];

    const columns = [
        { accessorKey: 'Sequence', header: 'Exist Seq', maxSize: 80 },
        { accessorKey: 'Address', header: 'Address' },
        { accessorKey: 'City', header: 'City' },
        { accessorKey: 'PostalCode', header: 'Zip', maxSize: 60 },
        { accessorKey: 'EarliestArrival', header: 'Earliest Arrival', maxSize: 80 },
        { accessorKey: 'LatestArrival', header: 'Latest Arrival', maxSize: 80 },
        { accessorKey: 'RefId', header: 'Segment', maxSize: 80 },
        { accessorKey: 'Side', header: 'Side', maxSize: 50 },
    ];

    const unsequencedColumns = [
        {
            accessorKey: 'Modified',
            header: 'Modified',
            maxSize: 60,
            muiTableBodyCellProps: { align: 'center' },
            Cell: ({ row }) =>
                row.original.NewSequence !== undefined &&
                row.original.NewSequence !== row.original.OriginalSequence && <ModifiedIcon sx={{ color: 'red', fontSize: 14 }} />,
        },
        {
            header: 'New Seq',
            maxSize: 80,
            Cell: ({ row }) =>
                manualMode ? (
                    <TextField
                        variant='outlined'
                        inputRef={(ref) => (unsequencedInputRefs.current[row.index] = ref)}
                        inputProps={{
                            onKeyPress: (e) => handleKeyPress(e, row.index, 'unsequenced'),
                            sx: {
                                p: 0,
                                textAlign: 'center',
                            },
                        }}
                        defaultValue={row.original.NewSequence !== undefined ? row.original.NewSequence : row.original.Sequence}
                        onBlur={(e) => {
                            const oldData = [...unsequencedData, ...sequencedData];
                            let newData = [];
                            oldData.forEach((od) => {
                                let record = { ...od };
                                if (record.Gid === row.original.Gid) {
                                    record.NewSequence = Number(e.target.value);
                                } else {
                                    record.NewSequence = record.NewSequence !== undefined ? record.NewSequence : record.Sequence;
                                }
                                newData.push(record);
                            });
                            if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
                                saveToUndo();
                                const unsequenced = newData.filter((t) => t.NewSequence === 0 && t.RefId > 0);
                                const sequenced = newData.filter((t) => t.NewSequence > 0 && t.RefId > 0);
                                setUnsequencedData(unsequenced);
                                setSequencedData(sequenced);
                            }
                        }}
                    />
                ) : (
                    0
                ),
        },
        ...columns,
    ];

    const sequencedColumns = [
        {
            accessorKey: 'Modified',
            header: 'Modified',
            maxSize: 60,
            muiTableBodyCellProps: { align: 'center' },
            Cell: ({ row }) =>
                row.original.NewSequence !== undefined &&
                row.original.NewSequence !== row.original.OriginalSequence && <ModifiedIcon sx={{ color: 'red', fontSize: 14 }} />,
        },
        {
            header: 'New Seq',
            maxSize: 80,
            Cell: ({ row }) =>
                manualMode ? (
                    <TextField
                        variant='outlined'
                        inputRef={(ref) => (sequencedInputRefs.current[row.index] = ref)}
                        inputProps={{
                            onKeyPress: (e) => handleKeyPress(e, row.index, 'sequenced'),
                            sx: {
                                p: 0,
                                textAlign: 'center',
                            },
                        }}
                        defaultValue={row.original.NewSequence !== undefined ? row.original.NewSequence : row.original.Sequence}
                        onBlur={(e) => {
                            const oldData = [...unsequencedData, ...sequencedData];
                            let newData = [];
                            oldData.forEach((od) => {
                                let record = { ...od };
                                if (record.Gid === row.original.Gid) {
                                    record.NewSequence = Number(e.target.value);
                                } else {
                                    record.NewSequence = record.NewSequence !== undefined ? record.NewSequence : record.Sequence;
                                }
                                newData.push(record);
                            });
                            if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
                                saveToUndo();
                                const unsequenced = newData.filter((t) => t.NewSequence === 0 && t.RefId > 0);
                                const sequenced = newData.filter((t) => t.NewSequence > 0 && t.RefId > 0);
                                setUnsequencedData(unsequenced);
                                setSequencedData(sequenced);
                            }
                        }}
                    />
                ) : row.original.NewSequence !== undefined ? (
                    `${row.original.NewSequence}`
                ) : (
                    `${row.original.Sequence}`
                ),
        },
        ...columns,
    ];

    useEffect(() => {
        if (sequenceDialogOpen) {
            if (editSequenceServiceDay && editSequenceRoute) {
                refreshSequenceGrid();
            } else {
                resetSequenceGrid();
            }
        } else {
            resetSequenceGrid();
            setEditSequenceRoute(null);
        }
    }, [sequenceDialogOpen]);

    useEffect(() => {
        if (workspace) {
            getGapSetting(workspace.value).then(
                (res) => {
                    if (res.data && !isNaN(res.data)) {
                        setSequenceGap(Number(res.data));
                    }
                },
                (err) => {
                    setSequenceGap(1);
                },
            );
        } else {
            setSequenceGap(1);
        }
    }, [workspace]);

    useEffect(() => {
        if (editSequenceServiceDay && editSequenceRoute) {
            refreshSequenceGrid();
        } else {
            resetSequenceGrid();
        }
        setTempFilters({});
    }, [editSequenceRoute]);

    useEffect(() => {
        checkButtonAvailability();
    }, [sequencedData, unsequencedData]);

    useEffect(() => {
        checkButtonAvailability();
    }, [savedSequenceForUndo]);

    useEffect(() => {
        checkButtonAvailability();
    }, [savedSequenceForRedo]);

    useEffect(() => {
        checkButtonAvailability();
        if (selectionMode) {
            let targetRow,
                ids = [];
            if (prevUnsequencedSelectedRowIndex !== null) {
                targetRow = unsequencedData[prevUnsequencedSelectedRowIndex];
            }
            if (targetRow) {
                const selectedArray = getSelectedArray(unsequencedRowSelection);
                if (selectionMode.value === 1) {
                    if (!selectedArray.includes(targetRow.Gid)) {
                        //unselect
                        let target = unsequencedData.find((s) => s.Gid === targetRow.Gid);
                        let segments = unsequencedData.filter((s) => s.RefId === target.RefId);
                        let IdsToRemove = segments.map((s) => s.Gid);
                        ids = selectedArray.filter((s) => !IdsToRemove.includes(s));
                    } else {
                        //select
                        selectedArray.forEach((sa) => {
                            let data = unsequencedData.find((s) => s.Gid === sa);
                            if (data) {
                                let sameSegment = unsequencedData.filter((s) => s.RefId === data.RefId);
                                let sameSegmentIds = sameSegment.map((s) => s.Gid);
                                ids = [...ids, ...sameSegmentIds];
                            }
                        });
                        ids = [...new Set(ids)];
                    }
                } else if (selectionMode.value === 2) {
                    if (!selectedArray.includes(targetRow.Gid)) {
                        //unselect
                        let target = unsequencedData.find((s) => s.Gid === targetRow.Gid);
                        let segments = unsequencedData.filter((s) => s.RefId === target.RefId && s.Side === target.Side);
                        let IdsToRemove = segments.map((s) => s.Gid);
                        ids = selectedArray.filter((s) => !IdsToRemove.includes(s));
                    } else {
                        //select
                        selectedArray.forEach((sa) => {
                            let data = unsequencedData.find((s) => s.Gid === sa);
                            if (data) {
                                let sameSegment = unsequencedData.filter((s) => s.RefId === data.RefId && s.Side === data.Side);
                                let sameSegmentIds = sameSegment.map((s) => s.Gid);
                                ids = [...ids, ...sameSegmentIds];
                            }
                        });
                        ids = [...new Set(ids)];
                    }
                }
                let sameSegmentRows = {};
                ids.forEach((i) => {
                    sameSegmentRows[i] = true;
                });
                if (!selectedArray.every((item) => ids.includes(item)) || !ids.every((item) => selectedArray.includes(item))) {
                    setUnsequencedRowSelection(sameSegmentRows);
                }
            }
        }
    }, [unsequencedRowSelection]);

    useEffect(() => {
        if (sequenceDialogOpen) {
            if (editSequenceServiceDay.value) {
                getRouteList(siteMode, workspace.value, editSequenceServiceDay.value, project).then(
                    (res) => {
                        if (res.data) {
                            let routes = `${res.data}`.split(',');
                            let rOptions = [];
                            routes.forEach((r) => {
                                rOptions.push({ label: r, value: r });
                            });
                            setEditSequenceRouteList(rOptions);
                            setEditSequenceRoute(null);
                        } else {
                            setEditSequenceRoute(null);
                            setEditSequenceRouteList([]);
                        }
                    },
                    (err) => {
                        setEditSequenceRoute(null);
                        setEditSequenceRouteList([]);
                    },
                );
            } else {
                setEditSequenceRoute(null);
                setEditSequenceRouteList([]);
            }
        } else {
            setEditSequenceRoute(null);
            setEditSequenceRouteList([]);
        }
    }, [editSequenceServiceDay, sequenceDialogOpen]);

    useEffect(() => {
        setUnsequencedRowSelection({});
        setSequencedRowSelection({});
    }, [manualMode]);

    useEffect(() => {
        if (refreshInput) {
            setRefreshInput(false);
            setManualnMode(true);
        }
    }, [refreshInput]);

    useEffect(() => {
        if (unsequencedData.length > 0) {
            setShowUnsequencedGrid(true);
        } else {
            setShowUnsequencedGrid(false);
        }
    }, [unsequencedData]);

    useEffect(() => {
        checkButtonAvailability();
        if (selectionMode) {
            let targetRow,
                ids = [];
            if (prevSequencedSelectedRowIndex !== null) {
                targetRow = sequencedData[prevSequencedSelectedRowIndex];
            }
            if (targetRow) {
                const selectedArray = getSelectedArray(sequencedRowSelection);
                if (selectionMode.value === 1) {
                    if (!selectedArray.includes(targetRow.Gid)) {
                        //unselect
                        let target = sequencedData.find((s) => s.Gid === targetRow.Gid);
                        let segments = sequencedData.filter((s) => s.RefId === target.RefId);
                        let IdsToRemove = segments.map((s) => s.Gid);
                        ids = selectedArray.filter((s) => !IdsToRemove.includes(s));
                    } else {
                        //select
                        selectedArray.forEach((sa) => {
                            let data = sequencedData.find((s) => s.Gid === sa);
                            if (data) {
                                let sameSegment = sequencedData.filter((s) => s.RefId === data.RefId);
                                let sameSegmentIds = sameSegment.map((s) => s.Gid);
                                ids = [...ids, ...sameSegmentIds];
                            }
                        });
                        ids = [...new Set(ids)];
                    }
                } else if (selectionMode.value === 2) {
                    if (!selectedArray.includes(targetRow.Gid)) {
                        //unselect
                        let target = sequencedData.find((s) => s.Gid === targetRow.Gid);
                        let segments = sequencedData.filter((s) => s.RefId === target.RefId && s.Side === target.Side);
                        let IdsToRemove = segments.map((s) => s.Gid);
                        ids = selectedArray.filter((s) => !IdsToRemove.includes(s));
                    } else {
                        //select
                        selectedArray.forEach((sa) => {
                            let data = sequencedData.find((s) => s.Gid === sa);
                            if (data) {
                                let sameSegment = sequencedData.filter((s) => s.RefId === data.RefId && s.Side === data.Side);
                                let sameSegmentIds = sameSegment.map((s) => s.Gid);
                                ids = [...ids, ...sameSegmentIds];
                            }
                        });
                        ids = [...new Set(ids)];
                    }
                }
                let sameSegmentRows = {};
                ids.forEach((i) => {
                    sameSegmentRows[i] = true;
                });
                if (!selectedArray.every((item) => ids.includes(item)) || !ids.every((item) => selectedArray.includes(item))) {
                    setSequencedRowSelection(sameSegmentRows);
                }
            }
        }
    }, [sequencedRowSelection]);

    const handleUnsequencedMultiRowSelectionWithShiftKey = useCallback(
        (table, opts) => {
            const { rowId, rowIndex } = opts;
            if (prevUnsequencedSelectedRowIndex === null) {
                table.setRowSelection((updater) => {
                    return {
                        ...updater,
                        [rowId]: true,
                    };
                });
            } else {
                const start = Math.min(prevUnsequencedSelectedRowIndex, rowIndex);
                const end = Math.max(prevUnsequencedSelectedRowIndex, rowIndex);
                handleMultiRowSelection(table, { end, start });
            }
            setPrevUnsequencedSelectedRowIndex(rowIndex);
        },
        [prevUnsequencedSelectedRowIndex],
    );

    const handleSequencedMultiRowSelectionWithShiftKey = useCallback(
        (table, opts) => {
            const { rowId, rowIndex } = opts;
            if (prevSequencedSelectedRowIndex === null) {
                table.setRowSelection((updater) => {
                    return {
                        ...updater,
                        [rowId]: true,
                    };
                });
            } else {
                const start = Math.min(prevSequencedSelectedRowIndex, rowIndex);
                const end = Math.max(prevSequencedSelectedRowIndex, rowIndex);
                handleMultiRowSelection(table, { end, start });
            }
            setPrevSequencedSelectedRowIndex(rowIndex);
        },
        [prevSequencedSelectedRowIndex],
    );

    const handleMultiRowSelection = useCallback((table, opts) => {
        const { end, start } = opts;
        const rows = table.getRowModel().rows;
        const res = {};
        for (let i = end; i >= start; i--) {
            if (!rows[i]?.getCanSelect()) {
                continue;
            }
            res[rows[i]['id']] = true;
        }
        table.setRowSelection((updater) => {
            return {
                ...updater,
                ...res,
            };
        });
    }, []);

    const refreshSequenceGrid = () => {
        if (workspace.value && editSequenceServiceDay && editSequenceServiceDay.value && project && editSequenceRoute && editSequenceRoute.value) {
            getSequence(workspace.value, editSequenceServiceDay.value, project, editSequenceRoute.value).then(
                (res) => {
                    setRunSequence(false);
                    const stops = res.data;
                    setData(stops);
                    if (stops.length > 0) {
                        let unsequenced = stops.filter((t) => t.Sequence === 0 && t.RefId > 0);
                        unsequenced = unsequenced.map((s, i) => {
                            s.OriginalSequence = 0;
                            s.NewSequence = 0;
                            return s;
                        });
                        let sequenced = stops.filter((t) => t.Sequence > 0 && t.RefId > 0);
                        sequenced = sequenced
                            .sort((a, b) => a.Sequence - b.Sequence)
                            .map((s, i) => {
                                s.OriginalSequence = (i + 1) * sequenceGap;
                                s.NewSequence = (i + 1) * sequenceGap;
                                return s;
                            });
                        setUnsequencedData(unsequenced);
                        setSequencedData(sequenced);
                    } else {
                        setUnsequencedData([]);
                        setSequencedData([]);
                    }
                    setUnsequencedRowSelection({});
                    setSequencedRowSelection({});
                    setSavedSequenceForUndo([]);
                    setSavedSequenceForRedo([]);
                },
                (err) => {
                    resetSequenceGrid();
                },
            );
        }
    };

    const resetSequenceGrid = () => {
        setData([]);
        setUnsequencedData([]);
        setSequencedData([]);
        setUnsequencedRowSelection({});
        setSequencedRowSelection({});
        setSavedSequenceForUndo([]);
        setSavedSequenceForRedo([]);
    };

    const handleServiceDayChange = (e) => {
        if (savedSequenceForUndo.length > 0) {
            let temp = { ...tempFilters };
            temp.serviceDay = e;
            setTempFilters(temp);
            setUnSavedConfirmationOpen(true);
        } else {
            setEditSequenceServiceDay(e);
        }
    };

    const handleRouteChange = (e) => {
        if (savedSequenceForUndo.length > 0) {
            let temp = { ...tempFilters };
            temp.route = e;
            setTempFilters(temp);
            setUnSavedConfirmationOpen(true);
        } else {
            setEditSequenceRoute(e);
        }
    };

    const moveTop = () => {
        saveToUndo();
        const selectedArray = getSelectedArray(sequencedRowSelection);
        const oldSequence = [...sequencedData];
        const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));
        const unchanged = oldSequence.filter((os) => !selectedArray.includes(os.Gid));
        let newSequence = [...selected, ...unchanged];
        newSequence.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
        setSequencedData(newSequence);
        scrollTo(0);
    };

    const moveUp = () => {
        saveToUndo();
        const selectedArray = getSelectedArray(sequencedRowSelection);
        let oldSequence = [...sequencedData];
        let lastItem = selectedArray.slice(-1);
        let index = oldSequence.findIndex((x) => x.Gid === selectedArray[0]);
        if (index <= 0) {
            return;
        } else {
            const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));
            const unchanged = oldSequence.filter((os) => !selectedArray.includes(os.Gid));
            let indexArray = [];
            selectedArray.forEach((s) => {
                indexArray.push(sequencedData.findIndex((sd) => sd.Gid === s));
            });
            indexArray.sort();
            let newSequence = [...unchanged.slice(0, indexArray[0] - 1), ...selected, ...unchanged.slice(indexArray[0] - 1)];
            newSequence.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
            setSequencedData(newSequence);
            const newIndex = newSequence.findIndex((x) => x.Gid === lastItem[0]);
            scrollTo(newIndex);
        }
    };

    const moveDown = () => {
        saveToUndo();
        const selectedArray = getSelectedArray(sequencedRowSelection);
        let oldSequence = [...sequencedData];
        let lastItem = selectedArray.slice(-1);
        let index = oldSequence.findIndex((x) => x.Gid === lastItem[0]);
        if (index >= oldSequence.length) {
            return;
        } else {
            const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));
            const unchanged = oldSequence.filter((os) => !selectedArray.includes(os.Gid));
            let indexArray = [];
            selectedArray.forEach((s) => {
                indexArray.push(sequencedData.findIndex((sd) => sd.Gid === s));
            });
            indexArray.sort();
            let newSequence = [...unchanged.slice(0, indexArray[0] + 1), ...selected, ...unchanged.slice(indexArray[0] + 1)];
            newSequence.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
            setSequencedData(newSequence);
            const newIndex = newSequence.findIndex((x) => x.Gid === lastItem[0]);
            scrollTo(newIndex);
        }
    };

    const moveBottom = () => {
        saveToUndo();
        const selectedArray = getSelectedArray(sequencedRowSelection);
        const oldSequence = [...sequencedData];
        const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));
        const unchanged = oldSequence.filter((os) => !selectedArray.includes(os.Gid));
        let newSequence = [...unchanged, ...selected];
        newSequence.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
        setSequencedData(newSequence);
        scrollTo(newSequence.length);
    };

    const reverse = () => {
        saveToUndo();
        const selectedArray = getSelectedArray(sequencedRowSelection);
        let oldSequence = [...sequencedData];
        let newSequence = [];
        if (selectedArray.length === 2) {
            const index_0 = oldSequence.findIndex((sd) => sd.Gid === selectedArray[0]);
            const index_1 = oldSequence.findIndex((sd) => sd.Gid === selectedArray[1]);
            const item_0 = oldSequence[index_0];
            oldSequence[index_0] = oldSequence[index_1];
            oldSequence[index_1] = item_0;
            newSequence = [...oldSequence];
        } else {
            const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));
            const unchanged = oldSequence.filter((os) => !selectedArray.includes(os.Gid));
            const reversed = [...selected].reverse();
            let indexArray = [];
            selectedArray.forEach((s) => {
                indexArray.push(sequencedData.findIndex((sd) => sd.Gid === s));
            });
            indexArray.sort();
            newSequence = [...unchanged.slice(0, indexArray[0]), ...reversed, ...unchanged.slice(indexArray[0])];
        }
        newSequence.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
        setSequencedData(newSequence);
    };

    const unsequence = () => {
        saveToUndo();
        const selectedArray = getSelectedArray(sequencedRowSelection);
        const rows = sequencedData.filter((s) => selectedArray.includes(s.Gid));
        let unsequenced = [...unsequencedData, ...rows];
        unsequenced.map((ns, i) => (ns.NewSequence = 0));
        let sequenced = sequencedData.filter((s) => !selectedArray.includes(s.Gid));
        sequenced.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
        setUnsequencedData(unsequenced);
        setSequencedData(sequenced);
        let selectedRows = { ...sequencedRowSelection };
        Object.entries(selectedRows).forEach(([key]) => {
            if (sequencedData.filter((s) => s.Gid === key).length === 0) {
                delete selectedRows[key];
            }
        });
        setSequencedRowSelection(selectedRows);
    };

    const resequence = () => {
        setResequenceDialogOpen(true);
    };

    const undo = () => {
        saveToRedo();
        const sequenceToUse = savedSequenceForUndo[0];
        let originalData = [...data];
        let undoData = originalData.map((od) => {
            const record = sequenceToUse.find((s) => s.Gid === od.Gid);
            od.NewSequence = record.Sequence;
            return od;
        });
        let unsequenced = undoData.filter((t) => t.NewSequence === 0 && t.RefId > 0);
        let sequenced = undoData.filter((t) => t.NewSequence > 0 && t.RefId > 0);
        sequenced = sequenced.sort((a, b) => a['NewSequence'] - b['NewSequence']);
        setUnsequencedRowSelection({});
        setSequencedRowSelection({});
        setUnsequencedData(unsequenced);
        setSequencedData(sequenced);

        let undoList = [...savedSequenceForUndo];
        undoList.shift();
        setSavedSequenceForUndo(undoList);
        if (manualMode) {
            setManualnMode(false);
            setRefreshInput(true);
        }
    };

    const redo = () => {
        saveToUndo();
        const sequenceToUse = savedSequenceForRedo[0];
        let originalData = [...data];
        let redoData = originalData.map((od) => {
            const record = sequenceToUse.find((s) => s.Gid === od.Gid);
            od.NewSequence = record.Sequence;
            return od;
        });
        let unsequenced = redoData.filter((t) => t.NewSequence === 0 && t.RefId > 0);
        let sequenced = redoData.filter((t) => t.NewSequence > 0 && t.RefId > 0);
        sequenced = sequenced.sort((a, b) => a['NewSequence'] - b['NewSequence']);
        setUnsequencedRowSelection({});
        setSequencedRowSelection({});
        setUnsequencedData(unsequenced);
        setSequencedData(sequenced);
        let redoList = [...savedSequenceForRedo];
        redoList.shift();
        setSavedSequenceForRedo(redoList);
        if (manualMode) {
            setManualnMode(false);
            setRefreshInput(true);
        }
    };

    const reset = () => {
        if (savedSequenceForUndo.length > 0) {
            setUnSavedConfirmationOpen(true);
        } else {
            refreshSequenceGrid();
        }
    };

    const handleSetNewSequence = (e) => {
        const valid = /^[0-9]*$/.test(e.target.value);
        if (valid) {
            setNewSequence(e.target.value);
        } else {
            setNewSequence('');
        }
    };

    const handleNewSequenceDialogClose = (mode) => {
        if (mode === 'SAVE') {
            saveToUndo();
            const selectedUnsequencedArray = getSelectedArray(unsequencedRowSelection);
            const selectedArray = getSelectedArray(sequencedRowSelection);
            if (selectedUnsequencedArray.length > 0 && selectedArray.length > 0) {
                showAPICallbackDialog('Error', 'Cannot preform this operation while both sequenced and unsequenced records are being selected!');
            } else if (selectedArray.length > 0) {
                const oldSequence = [...sequencedData];
                const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));

                let sequenced = [
                    ...oldSequence.slice(0, newSequence).filter((os) => !selectedArray.includes(os.Gid)),
                    ...selected,
                    ...oldSequence.slice(newSequence).filter((os) => !selectedArray.includes(os.Gid)),
                ];
                sequenced.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                setSequencedData(sequenced);
            } else if (selectedUnsequencedArray.length > 0) {
                const oldSequence = [...sequencedData];
                const oldUnsequenced = [...unsequencedData];
                const selected = oldUnsequenced.filter((os) => selectedUnsequencedArray.includes(os.Gid));
                const unchanged = oldUnsequenced.filter((os) => !selectedUnsequencedArray.includes(os.Gid));
                let sequenced;
                if (oldSequence.length > newSequence) {
                    sequenced = [...oldSequence.slice(0, newSequence - 1), ...selected, ...oldSequence.slice(newSequence - 1)];
                } else {
                    sequenced = [...oldSequence.slice(0, newSequence), ...selected, ...oldSequence.slice(newSequence)];
                }
                sequenced.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                setSequencedData(sequenced);
                setUnsequencedData(unchanged);
                setUnsequencedRowSelection({});
            }
        }
        setNewSequence(null);
        setResequenceDialogOpen(false);
    };

    const handleSaveSequence = (mode) => {
        const data = generateSequence();
        setIsMapLoading(true);
        updateSequence(workspace.value, editSequenceServiceDay.value, project, editSequenceRoute.value, data).then(
            (res) => {
                setIsMapLoading(false);
                resetSequenceGrid();
                refreshSequenceGrid();
            },
            (err) => {
                setIsMapLoading(false);
                resetSequenceGrid();
                showAPICallbackDialog('Error', err.response.data);
            },
        );
    };

    const handleSubmitSequence = () => {
        if (savedSequenceForUndo.length > 0) {
            let temp = { ...tempFilters };
            temp.Sequence = true;
            setTempFilters(temp);
            setUnSavedConfirmationOpen(true);
        } else {
            handleSequenceRoute();
        }
    };

    const handleSequenceRoute = () => {
        setIsMapLoading(true);
        submitSandboxSequence(workspace.value, editSequenceServiceDay.value, project, editSequenceRoute.value).then(
            (res) => {
                signalRService.startConnection(workspace.value, project);
                signalRService.onReceiveMessage((msg) => {
                    if (msg) {
                        let message = JSON.parse(msg);
                        if (!message.Disabled) {
                            if (message.Errors) {
                                showNotificationCallbackDialog('Error', <div dangerouslySetInnerHTML={{ __html: message.Errors }} />);
                            } else {
                                resetSequenceGrid();
                                getSequenceSolution(workspace.value, editSequenceServiceDay.value, project, editSequenceRoute.value).then(
                                    (result) => {
                                        setRunSequence(true);
                                        setIsMapLoading(false);
                                        const stops = result.data;
                                        setData(stops);
                                        if (stops.length > 0) {
                                            let unsequenced = stops.filter((t) => t.Sequence === 0 && t.RefId > 0);
                                            unsequenced = unsequenced.map((s, i) => {
                                                s.OriginalSequence = 0;
                                                s.NewSequence = 0;
                                                return s;
                                            });
                                            let sequenced = stops.filter((t) => t.Sequence > 0 && t.RefId > 0);
                                            sequenced = sequenced
                                                .sort((a, b) => a.Sequence - b.Sequence)
                                                .map((s, i) => {
                                                    s.OriginalSequence = (i + 1) * sequenceGap;
                                                    s.NewSequence = (i + 1) * sequenceGap;
                                                    return s;
                                                });
                                            setUnsequencedData(unsequenced);
                                            setSequencedData(sequenced);
                                        }
                                    },
                                    (error) => {
                                        setIsMapLoading(false);
                                        resetSequenceGrid();
                                        showAPICallbackDialog('Error', error.response.data);
                                    },
                                );
                                signalRService.disconnect();
                            }
                        }
                    }
                });
            },
            (err) => {
                setIsMapLoading(false);
                showAPICallbackDialog('Error', err.response.data);
            },
        );
    };

    const handleSequenceDialogClose = () => {
        if (savedSequenceForUndo.length > 0) {
            let temp = { ...tempFilters };
            temp.closeDialog = true;
            setTempFilters(temp);
            setUnSavedConfirmationOpen(true);
        } else {
            setSavedSequenceForUndo([]);
            setSavedSequenceForRedo([]);
            setSequenceDialogOpen(false);
        }
    };

    const generateSequence = () => {
        const unsequenced = unsequencedData.map((us) => {
            return { Gid: us.Gid, Sequence: 0 };
        });
        const sequenced = sequencedData.map((s) => {
            return { Gid: s.Gid, Sequence: s.NewSequence ? s.NewSequence : s.Sequence };
        });
        return [...unsequenced, ...sequenced];
    };

    const checkButtonAvailability = () => {
        const selectedArray = getSelectedArray(sequencedRowSelection);
        const selectedUnsequencedArray = getSelectedArray(unsequencedRowSelection);
        let b = { ...buttonAvailability };
        if (selectedArray.length > 0) {
            b['moveTop'] = false;
            b['moveUp'] = false;
            b['moveDown'] = false;
            b['moveBottom'] = false;
            b['unsequence'] = false;
            b['resequence'] = false;

            const firstRow = sequencedData[0];
            const lastRow = sequencedData[sequencedData.length - 1];

            b['reverse'] = false;
            if (selectedArray.length > 2) {
                let indexArray = [];
                selectedArray.forEach((s) => {
                    indexArray.push(sequencedData.findIndex((sd) => sd.Gid === s));
                });
                indexArray.sort();
                for (let i = 1; i < indexArray.length; i++) {
                    if (indexArray[i] - indexArray[i - 1] > 1) {
                        b['reverse'] = true;
                        break;
                    }
                }
            } else if (selectedArray.length < 2) {
                b['reverse'] = true;
            }

            if (selectedArray.includes(firstRow['Gid'])) {
                b['moveTop'] = true;
                b['moveUp'] = true;
            }
            if (selectedArray.includes(lastRow['Gid'])) {
                b['moveDown'] = true;
                b['moveBottom'] = true;
            }
        } else if (selectedUnsequencedArray.length > 0) {
            b['moveTop'] = true;
            b['moveUp'] = true;
            b['moveDown'] = true;
            b['moveBottom'] = true;
            b['reverse'] = true;
            b['unsequence'] = true;
            b['resequence'] = false;
        } else {
            b['moveTop'] = true;
            b['moveUp'] = true;
            b['moveDown'] = true;
            b['moveBottom'] = true;
            b['reverse'] = true;
            b['unsequence'] = true;
            b['resequence'] = true;
        }

        b['undo'] = savedSequenceForUndo.length === 0;
        b['redo'] = savedSequenceForRedo.length === 0;
        b['reset'] = savedSequenceForUndo.length + savedSequenceForRedo.length === 0 && !runSequence;

        setButtonAvailability(b);
    };

    const saveToUndo = () => {
        const current = generateSequence();
        let undoList = [...savedSequenceForUndo];
        if (undoList.length >= MAXUNDOSTEP) {
            setSavedSequenceForUndo([current, ...undoList.slice(0, MAXUNDOSTEP - 1)]);
        } else {
            setSavedSequenceForUndo([current, ...undoList]);
        }
    };

    const saveToRedo = () => {
        const current = generateSequence();
        let redoList = [...savedSequenceForRedo];
        if (redoList.length >= MAXUNDOSTEP) {
            setSavedSequenceForRedo([current, ...redoList.slice(0, MAXUNDOSTEP - 1)]);
        } else {
            setSavedSequenceForRedo([current, ...redoList]);
        }
    };

    const handleKeyPress = (event, index, table) => {
        if (event.charCode === 13) {
            if (table === 'unsequenced') {
                const nextIndex = Math.max(0, Math.min(index + 1, unsequencedData.length - 1));
                unsequencedInputRefs.current[nextIndex].focus();
            } else {
                const nextIndex = Math.max(0, Math.min(index + 1, sequencedData.length - 1));
                sequencedInputRefs.current[nextIndex].focus();
            }
        } else {
            if (event.charCode < 48 || event.charCode > 57) {
                event.preventDefault();
            }
        }
    };

    const handleUnsavedConfirmation = (mode) => {
        if (mode === 'CONFIRM') {
            if (tempFilters.closeDialog) {
                resetSequenceGrid();
                setSequenceDialogOpen(false);
            } else if (tempFilters.Sequence) {
                handleSequenceRoute();
            } else if (tempFilters.serviceDay) {
                setEditSequenceServiceDay(tempFilters.serviceDay);
            } else if (tempFilters.route) {
                setEditSequenceRoute(tempFilters.route);
            } else {
                refreshSequenceGrid();
            }
        }
        setTempFilters({});
        setUnSavedConfirmationOpen(false);
    };

    const getSelectedArray = (obj) => {
        let selectedArray = [];
        Object.entries(obj).forEach(([key]) => {
            selectedArray.push(Number(key));
        });
        return selectedArray;
    };

    const scrollTo = (index) => {
        if (!isNaN(index)) rowVirtualizerInstanceRef.current.scrollToIndex(index, { align: 'start', smoothScroll: true });
    };

    return (
        <>
            <Dialog
                open={sequenceDialogOpen}
                maxWidth={'xl'}
                fullWidth={true}
            >
                <DialogTitle>
                    Edit Sequence
                    <IconButton
                        aria-label='close'
                        onClick={handleSequenceDialogClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ userSelect: 'none' }}>
                    <Grid
                        container
                        spacing={'10px'}
                        style={{ paddingTop: '10px' }}
                    >
                        <Grid
                            container
                            item
                            xs={12}
                        >
                            <Grid
                                container
                                item
                                xs={2}
                            >
                                <FormControl
                                    style={{ margin: '8px 5px' }}
                                    variant='standard'
                                >
                                    <FormGroup row>
                                        <FormControlLabel
                                            value='start'
                                            control={
                                                <div style={{ width: '120px' }}>
                                                    <ReactSelect
                                                        classNamePrefix='select'
                                                        value={
                                                            editSequenceServiceDay
                                                                ? serviceDayList.find((s) => s.value == editSequenceServiceDay.value)
                                                                : null
                                                        }
                                                        onChange={(e) => handleServiceDayChange(e)}
                                                        options={serviceDayList}
                                                        styles={{
                                                            menu: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                zIndex: 100,
                                                            }),
                                                            valueContainer: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                fontSize: '10px',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                padding: 5,
                                                                fontSize: '10px',
                                                            }),
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        }}
                                                    />
                                                </div>
                                            }
                                            label={<b style={{ fontSize: '11px' }}>Service Day: &nbsp;</b>}
                                            labelPlacement='start'
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={2}
                            >
                                <FormControl
                                    style={{ margin: '8px 5px' }}
                                    variant='standard'
                                >
                                    <FormGroup row>
                                        <FormControlLabel
                                            value='start'
                                            control={
                                                <div style={{ width: '125px' }}>
                                                    <ReactSelect
                                                        classNamePrefix='select'
                                                        value={
                                                            editSequenceRoute
                                                                ? editSequenceRouteList.find((s) => s.value == editSequenceRoute.value)
                                                                : null
                                                        }
                                                        onChange={(e) => handleRouteChange(e)}
                                                        options={editSequenceRouteList}
                                                        isDisabled={editSequenceRouteList.length === 0}
                                                        styles={{
                                                            menu: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                zIndex: 100,
                                                            }),
                                                            valueContainer: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                fontSize: '10px',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                padding: 5,
                                                                fontSize: '10px',
                                                            }),
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        }}
                                                    />
                                                </div>
                                            }
                                            label={<b style={{ fontSize: '11px' }}>Route: &nbsp;</b>}
                                            labelPlacement='start'
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={3}
                            >
                                <FormControl
                                    style={{ margin: '8px 5px' }}
                                    variant='standard'
                                >
                                    <FormGroup row>
                                        <FormControlLabel
                                            value='start'
                                            control={
                                                <div style={{ width: '145px' }}>
                                                    <ReactSelect
                                                        className='basic-single'
                                                        classNamePrefix='select'
                                                        isClearable
                                                        placeholder={'None'}
                                                        value={selectionMode ? selectionModeList.find((s) => s.value == selectionMode.value) : null}
                                                        onChange={setSelectionMode}
                                                        options={selectionModeList}
                                                        styles={{
                                                            menu: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                zIndex: 100,
                                                            }),
                                                            valueContainer: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                fontSize: '10px',
                                                            }),
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                padding: 5,
                                                                fontSize: '10px',
                                                            }),
                                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        }}
                                                    />
                                                </div>
                                            }
                                            label={<b style={{ fontSize: '11px' }}>Selection Group Mode: &nbsp;</b>}
                                            labelPlacement='start'
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid
                                container
                                item
                                xs={3}
                            />
                            <Grid
                                container
                                item
                                xs={2}
                            >
                                <FormControlLabel
                                    value='start'
                                    control={
                                        <Switch
                                            color='primary'
                                            disabled={savedSequenceForUndo.length > 0}
                                            checked={manualMode}
                                            onChange={(e) => setManualnMode(e.target.checked)}
                                        />
                                    }
                                    label='Manual Mode'
                                    labelPlacement='start'
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                        >
                            <Grid
                                container
                                item
                                xs={1}
                                style={{ textAlign: 'center' }}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ButtonGroup
                                        orientation='vertical'
                                        size='small'
                                        color='inherit'
                                    >
                                        <Button
                                            key='1'
                                            title='Toggle unsequenced records'
                                            color={showUnsequencedGrid ? 'success' : undefined}
                                            disabled={unsequencedData.length === 0}
                                            onClick={() => setShowUnsequencedGrid(!showUnsequencedGrid)}
                                        >
                                            <ListIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ButtonGroup
                                        orientation='vertical'
                                        size='small'
                                        color='inherit'
                                    >
                                        <Button
                                            key='1'
                                            title='Move selected records to top'
                                            onClick={moveTop}
                                            disabled={buttonAvailability.moveTop}
                                        >
                                            <VerticalAlignTopIcon />
                                        </Button>
                                        <Button
                                            key='2'
                                            title='Move selected records up one row'
                                            onClick={moveUp}
                                            disabled={buttonAvailability.moveUp}
                                        >
                                            <ExpandLessIcon />
                                        </Button>
                                        <Button
                                            key='3'
                                            title='Move selected records down one row'
                                            onClick={moveDown}
                                            disabled={buttonAvailability.moveDown}
                                        >
                                            <ExpandMoreIcon />
                                        </Button>
                                        <Button
                                            key='4'
                                            title='Move selected records to bottom'
                                            onClick={moveBottom}
                                            disabled={buttonAvailability.moveBottom}
                                        >
                                            <VerticalAlignBottomIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ButtonGroup
                                        orientation='vertical'
                                        size='small'
                                        color='inherit'
                                    >
                                        <Button
                                            key='1'
                                            title='Reverse selected records'
                                            onClick={reverse}
                                            disabled={buttonAvailability.reverse}
                                        >
                                            <AutorenewIcon />
                                        </Button>
                                        <Button
                                            key='2'
                                            title='Unsequence selected records'
                                            onClick={unsequence}
                                            disabled={buttonAvailability.unsequence}
                                        >
                                            <FormatListBulletedIcon />
                                        </Button>
                                        {/* 
                                        <Button 
                                            key="3" title='Resequence selected records'
                                            onClick={resequence}
                                            disabled={buttonAvailability.resequence}
                                        >
                                            <FormatListNumberedIcon />
                                        </Button> 
                                        */}
                                    </ButtonGroup>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ButtonGroup
                                        orientation='vertical'
                                        size='small'
                                        color='inherit'
                                    >
                                        <Button
                                            key='1'
                                            title='Undo'
                                            disabled={buttonAvailability.undo}
                                            onClick={undo}
                                        >
                                            <UndoIcon />
                                        </Button>
                                        <Button
                                            key='2'
                                            title='Redo'
                                            disabled={buttonAvailability.redo}
                                            onClick={redo}
                                        >
                                            <RedoIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    style={{ marginBottom: '10px' }}
                                >
                                    <ButtonGroup
                                        orientation='vertical'
                                        size='small'
                                        color='inherit'
                                    >
                                        <Button
                                            key='1'
                                            title='Reset to saved sequence'
                                            disabled={buttonAvailability.reset}
                                            onClick={reset}
                                        >
                                            <ReplayIcon />
                                        </Button>
                                    </ButtonGroup>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                item
                                xs={11}
                            >
                                {showUnsequencedGrid && unsequencedData.length > 0 && (
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        sx={{ marginBottom: '10px' }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'grid',
                                                gridTemplateColumns: { xs: 'auto', lg: '1fr 1fr' },
                                                gap: '1rem',
                                                overflow: 'auto',
                                                p: '4px',
                                            }}
                                        >
                                            <MaterialReactTable
                                                {...commonTableProps}
                                                columns={unsequencedColumns}
                                                data={unsequencedData}
                                                enableRowSelection={!manualMode}
                                                enableRowOrdering={!manualMode}
                                                onRowSelectionChange={setUnsequencedRowSelection}
                                                state={{ draggingRow, hoveredRow, rowSelection: unsequencedRowSelection }}
                                                muiTableContainerProps={{
                                                    sx: { maxHeight: '155px' },
                                                }}
                                                getRowId={(originalRow) => `${originalRow.Gid}`}
                                                muiSelectAllCheckboxProps={({ table, row }) => ({
                                                    onClick: () => {
                                                        setPrevUnsequencedSelectedRowIndex(null);
                                                    },
                                                })}
                                                muiSelectCheckboxProps={({ table, row }) => ({
                                                    onClick: (e) => {
                                                        if (e.shiftKey) {
                                                            if (!row.getCanSelect() || row.index === undefined) {
                                                                return;
                                                            }
                                                            handleUnsequencedMultiRowSelectionWithShiftKey(table, {
                                                                rowId: row.original.Gid,
                                                                rowIndex: row.index,
                                                            });
                                                            return;
                                                        }
                                                        setPrevUnsequencedSelectedRowIndex(row.index);
                                                    },
                                                })}
                                                muiTableBodyRowProps={({ table, row }) => ({
                                                    onClick: (e) => {
                                                        if (!e.shiftKey || !row.getCanSelect()) {
                                                            return;
                                                        }
                                                        handleUnsequencedMultiRowSelectionWithShiftKey(table, {
                                                            rowId: row.original.Gid,
                                                            rowIndex: row.index,
                                                        });
                                                    },
                                                    sx: {
                                                        cursor: 'pointer',
                                                        height: '40px',
                                                    },
                                                })}
                                                muiTableBodyRowDragHandleProps={({ table }) => ({
                                                    onDragEnd: () => {
                                                        if (hoveredTable === 'sequenced_table') {
                                                            saveToUndo();
                                                            const { draggingRow, hoveredRow, rowSelection } = table.getState();
                                                            const selectedArray = getSelectedArray(rowSelection);
                                                            let oldSequence = [...unsequencedData];

                                                            if (selectedArray.length > 0 && selectedArray.includes(Number(draggingRow.id))) {
                                                                const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));
                                                                let newSeq = [
                                                                    ...sequencedData.slice(0, hoveredRow.index),
                                                                    ...selected,
                                                                    ...sequencedData.slice(hoveredRow.index),
                                                                ];
                                                                newSeq.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                                                                setSequencedData(newSeq);
                                                                const unchanged = oldSequence.filter((os) => !selectedArray.includes(os.Gid));
                                                                unchanged.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                                                                setUnsequencedData(unchanged);
                                                                table.setRowSelection({});
                                                            } else {
                                                                let newSeq = [
                                                                    ...sequencedData.slice(0, hoveredRow.index),
                                                                    draggingRow.original,
                                                                    ...sequencedData.slice(hoveredRow.index),
                                                                ];
                                                                newSeq.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                                                                let selectedRows = { ...rowSelection };
                                                                Object.entries(rowSelection).forEach(([key]) => {
                                                                    if (unsequencedData.filter((s) => s.Gid === key).length === 0) {
                                                                        delete selectedRows[key];
                                                                    }
                                                                });
                                                                table.setRowSelection(selectedRows);
                                                                setSequencedData(newSeq);
                                                                setUnsequencedData((data) => data.filter((d) => d !== draggingRow.original));
                                                            }
                                                        }
                                                        setHoveredTable(null);
                                                    },
                                                })}
                                                muiTablePaperProps={{
                                                    onDragEnter: () => setHoveredTable('unsequenced_table'),
                                                    onDragEnd: () => setHoveredTable(null),
                                                    sx: {
                                                        outline: hoveredTable === 'unsequenced_table' ? '2px dashed pink' : undefined,
                                                    },
                                                }}
                                                muiTableBodyCellProps={{
                                                    sx: {
                                                        backgroundColor: '#CCCCCC',
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                )}

                                <Grid
                                    container
                                    item
                                    xs={12}
                                >
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            gridTemplateColumns: { xs: 'auto', lg: '1fr 1fr' },
                                            gap: '1rem',
                                            overflow: 'auto',
                                            p: '4px',
                                        }}
                                    >
                                        <MaterialReactTable
                                            {...commonTableProps}
                                            columns={sequencedColumns}
                                            data={sequencedData}
                                            enableRowSelection={!manualMode}
                                            enableRowOrdering={!manualMode}
                                            rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
                                            onRowSelectionChange={setSequencedRowSelection}
                                            state={{ draggingRow, hoveredRow, rowSelection: sequencedRowSelection }}
                                            muiTableContainerProps={{
                                                sx: { maxHeight: showUnsequencedGrid ? '435px' : '635px' },
                                            }}
                                            getRowId={(originalRow) => `${originalRow.Gid}`}
                                            muiSelectAllCheckboxProps={({ table, row }) => ({
                                                onClick: () => {
                                                    setPrevSequencedSelectedRowIndex(null);
                                                },
                                            })}
                                            muiSelectCheckboxProps={({ table, row }) => ({
                                                onClick: (e) => {
                                                    if (e.shiftKey) {
                                                        if (!row.getCanSelect() || row.index === undefined) {
                                                            return;
                                                        }
                                                        handleSequencedMultiRowSelectionWithShiftKey(table, {
                                                            rowId: row.original.Gid,
                                                            rowIndex: row.index,
                                                        });
                                                        return;
                                                    }
                                                    setPrevSequencedSelectedRowIndex(row.index);
                                                },
                                            })}
                                            muiTableBodyRowProps={({ table, row }) => ({
                                                onClick: (e) => {
                                                    if (!e.shiftKey || !row.getCanSelect()) {
                                                        return;
                                                    }
                                                    handleSequencedMultiRowSelectionWithShiftKey(table, {
                                                        rowId: row.original.Gid,
                                                        rowIndex: row.index,
                                                    });
                                                },
                                                sx: {
                                                    cursor: 'pointer',
                                                    height: '40px',
                                                },
                                            })}
                                            muiTableBodyRowDragHandleProps={({ table }) => ({
                                                onDragEnd: (e) => {
                                                    e.preventDefault();
                                                    const { draggingRow, hoveredRow, rowSelection } = table.getState();
                                                    if (hoveredTable === 'unsequenced_table') {
                                                        saveToUndo();
                                                        const selectedArray = getSelectedArray(rowSelection);
                                                        let oldSequence = [...sequencedData];
                                                        if (selectedArray.length > 0 && selectedArray.includes(Number(draggingRow.id))) {
                                                            const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));
                                                            let newUnseq = [
                                                                ...unsequencedData.slice(0, hoveredRow.index),
                                                                ...selected,
                                                                ...unsequencedData.slice(hoveredRow.index),
                                                            ];
                                                            newUnseq.map((ns) => (ns.NewSequence = 0));
                                                            setUnsequencedData(newUnseq);
                                                            const unchanged = oldSequence.filter((os) => !selectedArray.includes(os.Gid));
                                                            unchanged.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                                                            setSequencedData(unchanged);
                                                            table.setRowSelection({});
                                                        } else {
                                                            let newUnseq = [
                                                                ...unsequencedData.slice(0, hoveredRow.index),
                                                                draggingRow.original,
                                                                ...unsequencedData.slice(hoveredRow.index),
                                                            ];
                                                            newUnseq.map((ns) => (ns.NewSequence = 0));
                                                            setUnsequencedData(newUnseq);
                                                            let newSeq = [...sequencedData].filter((d) => d !== draggingRow.original);
                                                            newSeq.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                                                            setSequencedData(newSeq);
                                                            let selectedRows = { ...rowSelection };
                                                            Object.entries(rowSelection).forEach(([key]) => {
                                                                if (sequencedData.filter((s) => s.Gid === key).length === 0) {
                                                                    delete selectedRows[key];
                                                                }
                                                            });
                                                            table.setRowSelection(selectedRows);
                                                        }
                                                    } else {
                                                        if (hoveredRow && draggingRow) {
                                                            saveToUndo();
                                                            let oldSequence = [...sequencedData];
                                                            const selectedArray = getSelectedArray(rowSelection);
                                                            if (selectedArray.length > 0 && selectedArray.includes(Number(draggingRow.id))) {
                                                                const selected = oldSequence.filter((os) => selectedArray.includes(os.Gid));
                                                                const unchanged = oldSequence.filter((os) => !selectedArray.includes(os.Gid));
                                                                let indexArray = [];
                                                                selectedArray.forEach((s) => {
                                                                    indexArray.push(sequencedData.findIndex((sd) => sd.Gid === s));
                                                                });
                                                                indexArray.sort();
                                                                let newSequence = [];
                                                                if (hoveredRow.index === 0) {
                                                                    newSequence = [
                                                                        ...oldSequence
                                                                            .slice(0, hoveredRow.index)
                                                                            .filter((os) => !selectedArray.includes(os.Gid)),
                                                                        ...selected,
                                                                        ...oldSequence
                                                                            .slice(hoveredRow.index)
                                                                            .filter((os) => !selectedArray.includes(os.Gid)),
                                                                    ];
                                                                } else {
                                                                    newSequence = [
                                                                        ...oldSequence
                                                                            .slice(0, hoveredRow.index + 1)
                                                                            .filter((os) => !selectedArray.includes(os.Gid)),
                                                                        ...selected,
                                                                        ...oldSequence
                                                                            .slice(hoveredRow.index + 1)
                                                                            .filter((os) => !selectedArray.includes(os.Gid)),
                                                                    ];
                                                                }
                                                                newSequence.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                                                                setSequencedData(newSequence);
                                                            } else {
                                                                sequencedData.splice(
                                                                    hoveredRow.index,
                                                                    0,
                                                                    sequencedData.splice(draggingRow.index, 1)[0],
                                                                );
                                                                let newSequence = [...sequencedData];
                                                                newSequence.map((ns, i) => (ns.NewSequence = (i + 1) * sequenceGap));
                                                                setSequencedData(newSequence);
                                                            }
                                                        }
                                                    }
                                                    setHoveredTable(null);
                                                },
                                            })}
                                            muiTablePaperProps={{
                                                onDragEnter: () => setHoveredTable('sequenced_table'),
                                                onDragEnd: () => setHoveredTable(null),
                                                sx: {
                                                    outline: hoveredTable === 'sequenced_table' ? '2px dashed pink' : undefined,
                                                },
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                        style={!editSequenceRoute ? { backgroundColor: '#fff' } : { backgroundColor: '#16a8a6', color: '#fff' }}
                        disabled={!editSequenceRoute}
                        onClick={handleSubmitSequence}
                    >
                        SEQUENCE
                    </Button>
                    <Button
                        style={
                            !editSequenceRoute || (savedSequenceForUndo.length === 0 && !runSequence)
                                ? { backgroundColor: '#fff' }
                                : { backgroundColor: '#16a8a6', color: '#fff' }
                        }
                        disabled={!editSequenceRoute || (savedSequenceForUndo.length === 0 && !runSequence)}
                        onClick={handleSaveSequence}
                    >
                        SAVE
                    </Button>
                </DialogActions>
            </Dialog>

            {/* 
            <ResequenceDialog 
                resequenceDialogOpen={resequenceDialogOpen}
                newSequence={newSequence}
                handleSetNewSequence={handleSetNewSequence}
                handleNewSequenceDialogClose={handleNewSequenceDialogClose}
            /> 
            */}
            <UnSavedConfirmationDialog
                unSavedConfirmationOpen={unSavedConfirmationOpen}
                handleUnsavedConfirmation={handleUnsavedConfirmation}
            />
        </>
    );
};

const ResequenceDialog = (props) => {
    const { resequenceDialogOpen, newSequence, handleSetNewSequence, handleNewSequenceDialogClose } = props;

    return (
        <Dialog
            open={resequenceDialogOpen}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>Resequence</DialogTitle>
            <DialogContent>
                New Sequence Number
                <br />
                <TextField
                    size='small'
                    variant='outlined'
                    value={newSequence}
                    onChange={(e) => handleSetNewSequence(e)}
                />
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleNewSequenceDialogClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleNewSequenceDialogClose('SAVE')}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const UnSavedConfirmationDialog = (props) => {
    const { unSavedConfirmationOpen, handleUnsavedConfirmation } = props;

    return (
        <Dialog
            open={unSavedConfirmationOpen}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
                <p>Unsaved sequences will be lost after this operation.</p>
                <p>Would you like to proceed?</p>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleUnsavedConfirmation('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleUnsavedConfirmation('CONFIRM')}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
