import React from 'react';
import MaterialReactTable from 'material-react-table';

export const ZoneViewTab = (props) => {
    const { routePlanId, serviceDay, zoneMode, zoneRouteList, selectedZoneRoutes, zoneRouteSelectionChanged, unSavedChanges } = props;
    const [rowSelection, setRowSelection] = React.useState({});
    const lock = React.useRef(false);

    const tableInstanceRef = React.createRef(null);

    let tableRowLength = tableInstanceRef?.current?.refs?.tableContainerRef?.current?.clientWidth || 307;

    let checkboxW = 30;
    let svgW = 15;

    let sumOfOtherColumns = checkboxW + svgW;
    let calculatedLabelWidth = tableRowLength - sumOfOtherColumns;

    let widthOfScroll = 15;

    let columns = [
        {
            header: '',
            accessorKey: 'Svg',
            size: svgW,
            minSize: svgW,
            maxSize: svgW,
            enableGlobalFilter: false,
            Cell: ({ cell, row }) => {
                return (
                    <div
                        style={{ width: '10px' }}
                        dangerouslySetInnerHTML={{ __html: cell.getValue() }}
                    ></div>
                );
            },
        },
        {
            header: routePlanId ? 'Zone Route' : serviceDay.value === 0 && sessionStorage.getItem('mdr') !== 'true' ? 'Service Day' : 'Stop Route',
            accessorKey: 'Label',
            minSize: calculatedLabelWidth - widthOfScroll,
            maxSize: calculatedLabelWidth,
            Cell: ({ cell, row }) => {
                return <span>{cell.getValue()}</span>;
            },
        },
    ];

    React.useEffect(() => {
        if (!lock.current) {
            let selected = [];
            Object.keys(rowSelection).forEach((key, index) => {
                selected.push(key.toString());
            });
            zoneRouteSelectionChanged(selected);
        } else {
            lock.current = false;
        }
    }, [rowSelection]);

    React.useEffect(() => {
        if (selectedZoneRoutes) {
            let selected = {};
            zoneRouteList.forEach((value) => {
                if (selectedZoneRoutes.includes(value.Id)) {
                    selected[value.Id] = true;
                }
            });
            if (JSON.stringify(selected) != JSON.stringify(rowSelection)) {
                lock.current = true;
                setRowSelection(selected);
            }
        }
    }, [selectedZoneRoutes]);

    return (
        <div
            id='zoneViewPane'
            style={{ width: '100%' }}
        >
            <div style={{ whiteSpace: 'nowrap', paddingTop: '5px', paddingBottom: '5px' }}>
                <label>
                    <b>
                        {routePlanId
                            ? 'Zone Route'
                            : serviceDay.value === 0 && sessionStorage.getItem('mdr') !== 'true'
                              ? 'Service Day'
                              : 'Stop Route'}
                        :
                    </b>
                    {selectedZoneRoutes.length} selected of {zoneRouteList.length} rows
                </label>
            </div>
            <div
                id='NC_RouteList'
                style={{ maxWidth: '100%' }}
            >
                <MaterialReactTable
                    columns={columns}
                    data={zoneRouteList}
                    enableTopToolbar={true}
                    enableStickyHeader={true}
                    enableSorting={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableColumnOrdering={false}
                    enableColumnResizing={false}
                    //enableRowSelection={true}
                    enableRowSelection={(row) => !unSavedChanges || Object.keys(setRowSelection).includes(row.original.Id)}
                    enableHiding={false}
                    enableFilterMatchHighlighting={false}
                    enableMultiRowSelection={zoneMode === 'VIEW'}
                    selectAllMode='all'
                    // layoutMode="grid"
                    positionToolbarAlertBanner='none'
                    initialState={{
                        density: 'compact',
                        showGlobalFilter: true,
                        sorting: [{ id: 'Label', desc: false }],
                    }}
                    state={{
                        rowSelection,
                        columnOrder: ['mrt-row-select', 'Svg', 'Label'],
                    }}
                    onRowSelectionChange={setRowSelection}
                    defaultColumn={{
                        maxSize: 30,
                        minSize: 30,
                        size: 30,
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-select': {
                            enableHiding: true,
                        },
                    }}
                    muiTopToolbarProps={{
                        sx: {
                            height: '40px',
                            minHeight: '40px',
                        },
                    }}
                    muiTableHeadCellProps={{
                        sx: {
                            flex: '0 0 auto',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            padding: 0,
                        },
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            flex: '0 0 auto',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            padding: 0,
                        },
                    }}
                    muiSearchTextFieldProps={{
                        sx: { maxWidth: `${tableRowLength - 16}px`, width: `${tableRowLength - 16}px` },
                        InputProps: {
                            sx: { fontSize: '12px', height: '30px' },
                        },
                        variant: 'outlined',
                    }}
                    muiSelectAllCheckboxProps={{
                        color: 'default',
                        title: '',
                    }}
                    muiSelectCheckboxProps={{
                        color: 'default',
                        title: '',
                    }}
                    muiTableProps={{
                        sx: { tableLayout: 'fixed' },
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: '250px', minHeight: '250px', overflowX: 'hidden' },
                    }}
                    getRowId={(originalRow) => originalRow.Id}
                    tableInstanceRef={tableInstanceRef}
                />
            </div>
        </div>
    );
};
