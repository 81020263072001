import React from 'react';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DataSource from 'devextreme/data/data_source';
import DataGrid, {
    Column,
    RowDragging,
    Scrolling,
    Sorting,
    Pager,
    Paging,
    FilterRow,
    HeaderFilter,
    SearchPanel,
    Toolbar,
    Item,
    MasterDetail,
} from 'devextreme-react/data-grid';
import DxButton from 'devextreme-react/button';
import Popover from 'devextreme-react/popover';
import SelectBox from 'devextreme-react/select-box';
import TagBox from 'devextreme-react/tag-box';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import { Validator, RequiredRule, CompareRule, EmailRule, PatternRule, StringLengthRule } from 'devextreme-react/validator';
import ValidationSummary from 'devextreme-react/validation-summary';

import UserDataPrivileges from './Dialogs/UserDataPrivileges';
import AddEditUsers from './Dialogs/AddEdit';
import DeleteUsers from './Dialogs/Delete';
import { RODialogLabel, GlobalROContext } from '../../common';
import { config } from '../../../_helpers/config';
import { getErrorMessage } from '../../../_helpers/common';
import { getWorkspaceList, getRouteList, updateUserActivity } from '../../../_actions/Global.actions';
import {
    getUsers,
    addUser,
    updateUser,
    deleteUser,
    getAssignableRoles,
    getAssignableGroups,
    getRoleAssignedFunctions,
    getUserDataForFunction,
    addUserDataForFunction,
    replicateUserDataForFunction,
    deleteUserData,
    checkWorkspaceAccess,
} from '../../../_actions/AccountManagement.actions';

const Users = (props) => {
    const refUserListGrid = React.useRef();
    const [userData, setUserData] = React.useState(null);
    const [showEditUserDialog, setShowEditUserDialog] = React.useState(false);
    const [workspaceList, setWorkspaceList] = React.useState([]);
    const [workspaceRouteList, setWorkspaceRouteList] = React.useState({});
    const [savedUserFunctionData, setSavedUserFunctionData] = React.useState([]);
    const [showEditUserPrivilegesDialog, setShowEditUserPrivilegesDialog] = React.useState(false);
    const [showDeleteUserDialog, setShowDeleteUserDialog] = React.useState(false);
    const [userMode, setUserMode] = React.useState('');
    const [userTarget, setUserTarget] = React.useState({});
    const [userPopoverVisible, setUserPopoverVisible] = React.useState(false);
    const [editUserPasswordVisible, setEditUserPasswordVisible] = React.useState(false);
    const [passwordMode, setPasswordMode] = React.useState('password');
    const [confirmPasswordMode, setConfirmPasswordMode] = React.useState('password');
    const [assignableRoles, setAssignableRoles] = React.useState(null);
    const [assignableGroups, setAssignableGroups] = React.useState(null);
    const [userPrivilegesTarget, setUserPrivilegesTarget] = React.useState({});
    const [userPrivilegesFunctions, setUserPrivilegesFunctions] = React.useState([]);
    const [userPrivilegesReplicateFunctions, setUserPrivilegesReplicateFunctions] = React.useState([]);
    const [canAccessAll, setCanAccessAll] = React.useState(false);
    const { showApiROErrorDialog, setShowApiROErrorDialog } = React.useContext(GlobalROContext);

    const userPrivilegesGroupFunctionsData = new DataSource({
        store: {
            type: 'array',
            data: userPrivilegesFunctions,
            key: 'id',
        },
        group: 'groupName',
    });
    const userPrivilegesReplicateGroupFunctionsData = new DataSource({
        store: {
            type: 'array',
            data: userPrivilegesReplicateFunctions,
            key: 'id',
        },
        group: 'groupName',
    });

    const populateUsers = async () => {
        const usersResponse = await getUsers();
        if (usersResponse && usersResponse.data && usersResponse.data.length > 0) {
            let usersData = usersResponse.data;
            usersData.forEach((item) => {
                item.RoleId = parseInt(item.RoleId);
            });
            setUserData(usersData);
        } else {
            setUserData([]);
        }
    };

    React.useEffect(() => {
        populateUsers();
    }, []);

    //Users - start
    const updateUserForm = (field, value) => {
        let t = { ...userTarget };
        t[field] = value;
        setUserTarget(t);
    };

    const handleEditUser = async (mode, data) => {
        try {
            const assignRoles = await getAssignableRoles();
            let roles = [];
            assignRoles.data.forEach((item) => {
                roles.push({ id: item.RoleID, text: item.RoleName });
            });
            setAssignableRoles(roles);
            const assignGroups = await getAssignableGroups();
            let groups = [];
            assignGroups.data.forEach((item) => {
                groups.push({ id: item.Id, text: item.Name });
            });
            setAssignableGroups(groups);
        } catch (e) {
            console.log(e);
        }
        setUserMode(mode);
        if (mode === 'New') {
            setEditUserPasswordVisible(true);
        } else {
            setEditUserPasswordVisible(false);
        }
        setUserTarget(data);
        setShowEditUserDialog(true);
    };

    const handleEditUserPrivileges = async (data) => {
        try {
            const assignFunctions = await getRoleAssignedFunctions(data.RoleId);
            let functions = [];
            assignFunctions.data.forEach((item) => {
                if (!config.FUNCTIONS_TO_HIDE_FROM_PRIVILEGE_ASSIGN.includes(parseInt(item.FunctionID))) {
                    if (item.SubFunctionName === '') {
                        //TreeList does not support a key value of 0. Replacing to 1 at load. Replace 1 with 0 while saving.
                        functions.push({
                            id: item.FunctionID === '0' ? 1 : parseInt(item.FunctionID),
                            functionName: item.FunctionName,
                            groupName: item.FunctionName,
                        });
                    } else {
                        functions.push({ id: parseInt(item.FunctionID), functionName: item.SubFunctionName, groupName: item.FunctionName });
                    }
                }
            });
            setUserPrivilegesFunctions(functions);
            setUserPrivilegesReplicateFunctions([]);
            let workspaceResp = await getWorkspaceList();
            let wsList = workspaceResp.data;
            let wsRouteList = {};
            wsList.forEach(async (item) => {
                item.Status = 'Unselected';
                if (item.Id > 0) {
                    const res = await getRouteList(item.Id, 0); //Routes for all service days
                    let wsRteLst = res.data;
                    wsRteLst.forEach((item) => {
                        item.Status = 'Unselected';
                    });
                    wsRouteList[item.Id] = wsRteLst;
                }
            });
            wsList.sort((a, b) => {
                return a.Name.localeCompare(b.Name, 'en', { numeric: true });
            });
            setWorkspaceList(wsList);
            setWorkspaceRouteList(wsRouteList);
            setUserPrivilegesTarget(data);
            setShowEditUserPrivilegesDialog(true);
        } catch (e) {
            console.log(e);
        }
    };

    const handleDeleteUser = (data) => {
        setUserMode('Delete');
        setUserTarget(data);
        setShowDeleteUserDialog(true);
    };

    const validateUserForm = () => {
        let valid = true;
        if (!userTarget.UserName || userTarget.UserName.trim().length == 0) {
            valid = false;
        }
        if (
            editUserPasswordVisible &&
            (!userTarget.Password ||
                !userTarget.Password.match(/[A-Z]/) ||
                !userTarget.Password.match(/[a-z]/) ||
                !userTarget.Password.match(/\d/) ||
                !userTarget.Password.match(/[$-/:-?{-~!"^_`@#\[\]\\]/) ||
                userTarget.Password.length < 8 ||
                userTarget.Password.length > 16)
        ) {
            valid = false;
        }
        if (
            editUserPasswordVisible &&
            (!userTarget.ConfirmPassword || userTarget.ConfirmPassword.trim().length == 0 || userTarget.Password != userTarget.ConfirmPassword)
        ) {
            valid = false;
        }
        if (!userTarget.Email || userTarget.Email.trim().length == 0) {
            valid = false;
        }

        if (!userTarget.RoleId) {
            valid = false;
        }

        return valid;
    };

    const handleEditUserClose = async (option) => {
        if (option === 'CANCEL') {
            setShowEditUserDialog(false);
            setUserTarget({});
        } else if (option === 'SAVE') {
            if (validateUserForm()) {
                let ActionId;
                let err;
                try {
                    let postData = {
                        UserName: userTarget.UserName,
                        PreferredUsername: userTarget.PreferredUsername,
                        Email: userTarget.Email,
                        FirstName: userTarget.FirstName,
                        LastName: userTarget.LastName,
                        RoleId: userTarget.RoleId,
                    };
                    if (userMode === 'New') {
                        ActionId = 904;
                        postData.UserPassword = userTarget.Password;
                        if (userTarget.GroupId) {
                            postData.GroupId = userTarget.GroupId;
                        }
                        await addUser(postData);
                    } else if (userMode === 'Edit') {
                        ActionId = 906;
                        if (userTarget.Password) {
                            postData.UserPassword = userTarget.Password;
                        }
                        if (userTarget.GroupId) {
                            postData.GroupId = userTarget.GroupId;
                        }
                        await updateUser(userTarget.UserID, postData);
                    }
                    setShowEditUserDialog(false);
                    setUserTarget({});
                    populateUsers();
                } catch (e) {
                    err = e;
                    if (!showApiROErrorDialog) {
                        setShowApiROErrorDialog(getErrorMessage(err));
                    }
                } finally {
                    let logData = {
                        ActionId,
                        Success: err ? false : true,
                        Metadata: userTarget.UserName,
                    };
                    updateUserActivity(logData);
                }
            }
        }
    };

    const handleEditUserPrivilegesClose = async (option) => {
        if (option === 'CANCEL') {
            setShowEditUserPrivilegesDialog(false);
            setUserPrivilegesTarget({});
        } else if (option === 'SAVE') {
            try {
                if (savedUserFunctionData) {
                    //delete user data for function ID
                    savedUserFunctionData.forEach((item) => {
                        deleteUserData(userPrivilegesTarget.UserID, item.ID);
                    });
                }
                //Add user data for function ID
                let userDataWorkspaces = [];
                let userDataRoutes = {};
                let selectedWorkspaces = workspaceList.filter((obj) => {
                    return obj.Status === 'Selected';
                });
                if (selectedWorkspaces.length == workspaceList.length) {
                    userDataWorkspaces.push('ALL WORKSPACES');
                    userDataRoutes['ALL WORKSPACES'] = '';
                } else {
                    if (selectedWorkspaces.length > 0) {
                        selectedWorkspaces.forEach((item) => {
                            userDataWorkspaces.push(item.Id);
                            let selectedRoutes = workspaceRouteList[item.Id].filter((obj) => {
                                return obj.Status === 'Selected';
                            });
                            if (selectedRoutes.length == workspaceRouteList[item.Id].length) {
                                userDataRoutes[item.Id.toString()] = 'ALL ROUTES';
                            } else {
                                let userDataWorkspaceRoutes = [];
                                selectedRoutes.forEach((udwRtItem) => {
                                    userDataWorkspaceRoutes.push(udwRtItem.Id);
                                });
                                userDataRoutes[item.Id.toString()] = userDataWorkspaceRoutes.join(',');
                            }
                        });
                    }
                }
                userDataWorkspaces.forEach((workspaceId) => {
                    let postData = {
                        UserID: userPrivilegesTarget.UserID,
                        UserName: userPrivilegesTarget.UserName,
                        FunctionID: userPrivilegesTarget.FunctionID,
                        Workspaces: workspaceId,
                        Routes: userDataRoutes[workspaceId.toString()],
                    };
                    addUserDataForFunction(userPrivilegesTarget.UserID, postData);
                });
                if (userPrivilegesTarget.ReplicateFunctionID && userPrivilegesTarget.ReplicateFunctionID.length > 0) {
                    let replicateFuncIDs = userPrivilegesTarget.ReplicateFunctionID.join(',');
                    await replicateUserDataForFunction(userPrivilegesTarget.UserID, userPrivilegesTarget.FunctionID, replicateFuncIDs);
                }
                setShowEditUserPrivilegesDialog(false);
                setUserPrivilegesTarget({});
            } catch (e) {
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(e));
                }
            }
        }
    };

    const handleDeleteUserClose = async (option) => {
        if (option === 'CANCEL') {
            setShowDeleteUserDialog(false);
            setUserTarget({});
        } else if (option === 'DELETE') {
            let err;
            try {
                await deleteUser(userTarget.UserID);
                setShowDeleteUserDialog(false);
                setUserTarget({});
                populateUsers();
            } catch (e) {
                err = e;
                if (!showApiROErrorDialog) {
                    setShowApiROErrorDialog(getErrorMessage(err));
                }
            } finally {
                let logData = {
                    ActionId: 905,
                    Success: err ? false : true,
                    Metadata: userTarget.UserName,
                };
                updateUserActivity(logData);
            }
        }
    };

    //Users - end

    return (
        <>
            <DataGrid
                height={props.windowDimensions.height - 275}
                dataSource={userData}
                keyExpr='UserID'
                showBorders={true}
                showColumnLines={true}
                showRowLines={true}
                allowColumnResizing={true}
                columnResizingMode={'nextColumn'}
                columnMinWidth={50}
                columnAutoWidth={true}
                ref={refUserListGrid}
            >
                <SearchPanel
                    visible={true}
                    width={240}
                    placeholder='Search...'
                />
                <HeaderFilter visible={false} />
                <FilterRow
                    visible={false}
                    applyFilter={true}
                />
                <Paging defaultPageSize={15} />
                <Pager
                    visible={true}
                    allowedPageSizes={[15, 25, 50, 100, 'all']}
                    displayMode={'full'}
                    showPageSizeSelector={true}
                    showInfo={true}
                    showNavigationButtons={true}
                />
                <Scrolling mode='virtual' />
                <Sorting mode='multiple' />
                <Toolbar>
                    <Item location='before'>
                        <DxButton
                            hint='Add user'
                            icon='add'
                            text='Add user'
                            onClick={(e) => {
                                handleEditUser('New', {});
                            }}
                        />
                    </Item>
                    <Item
                        location='after'
                        name='searchPanel'
                    />
                </Toolbar>
                <Column dataField='UserName'></Column>
                <Column dataField='FirstName'></Column>
                <Column dataField='LastName'></Column>
                <Column dataField='Email'></Column>
                <Column
                    dataField='RoleName'
                    caption='Role'
                ></Column>
                <Column
                    dataField='GroupName'
                    caption='Group'
                ></Column>
                <Column
                    type='buttons'
                    width='120px'
                    cellRender={(e) => {
                        return (
                            <div>
                                <DxButton
                                    hint='Edit user data privileges'
                                    icon='copy'
                                    onClick={() => {
                                        handleEditUserPrivileges(e.data);
                                    }}
                                />
                                &nbsp;
                                <DxButton
                                    hint='Edit user'
                                    icon='edit'
                                    onClick={() => {
                                        handleEditUser('Edit', e.data);
                                    }}
                                />
                                &nbsp;
                                <DxButton
                                    hint='Delete user'
                                    icon='trash'
                                    type='danger'
                                    onClick={() => {
                                        handleDeleteUser(e.data);
                                    }}
                                />
                            </div>
                        );
                    }}
                ></Column>
            </DataGrid>

            {/* USERS Dialogs */}
            {/* //////////////////////////////// */}
            {/* Edit User Data Privileges dialog*/}
            {showEditUserPrivilegesDialog && (
                <UserDataPrivileges
                    open={showEditUserPrivilegesDialog}
                    title='Edit User Data Privileges'
                    dialogContent={
                        <DialogContent>
                            <Grid
                                container
                                spacing={'10px'}
                                style={{ paddingTop: '10px' }}
                            >
                                <Grid
                                    item
                                    xs={1}
                                >
                                    <RODialogLabel>User</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                >
                                    <TextBox
                                        name='UserName'
                                        inputAttr={{ autocomplete: 'new-username' }}
                                        value={userPrivilegesTarget.UserName}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                >
                                    <RODialogLabel>Role</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <TextBox
                                        name='RoleName'
                                        inputAttr={{ autocomplete: 'new-role' }}
                                        value={userPrivilegesTarget.RoleName}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                >
                                    <RODialogLabel>Function</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                >
                                    <SelectBox
                                        dataSource={userPrivilegesGroupFunctionsData}
                                        valueExpr='id'
                                        grouped={true}
                                        displayExpr='functionName'
                                        value={userPrivilegesTarget.FunctionID}
                                        onValueChanged={async (e) => {
                                            let target = { ...userPrivilegesTarget };
                                            target.FunctionID = e.value;
                                            target.ReplicateFunctionID = [];
                                            setUserPrivilegesTarget(target);
                                            if (e.value) {
                                                checkWorkspaceAccess(e.value).then((res) => {
                                                    setCanAccessAll(res.data);
                                                });
                                            }

                                            let replicateFunctions = [];
                                            userPrivilegesFunctions.forEach((item) => {
                                                if (e.value !== item.id) {
                                                    replicateFunctions.push(item);
                                                }
                                            });
                                            setUserPrivilegesReplicateFunctions(replicateFunctions);
                                            if (e.value && userPrivilegesTarget && userPrivilegesTarget.UserID) {
                                                let funcUserData = null;
                                                try {
                                                    funcUserData = await getUserDataForFunction(userPrivilegesTarget.UserID, e.value);
                                                } catch (e) {
                                                    //Hanlde Not Found exception for new roles
                                                }
                                                if (funcUserData && funcUserData.data && funcUserData.data.length > 0) {
                                                    setSavedUserFunctionData(funcUserData.data);
                                                    let wsList = [...workspaceList];
                                                    let wsRouteList = { ...workspaceRouteList };
                                                    wsList.forEach((item) => {
                                                        item.Status = 'Unselected';
                                                        if (wsRouteList[item.Id] && wsRouteList[item.Id].length > 0) {
                                                            wsRouteList[item.Id].forEach((wsRtItem) => {
                                                                wsRtItem.Status = 'Unselected';
                                                            });
                                                        }
                                                    });

                                                    if (funcUserData.data[0].Workspaces === 'ALL WORKSPACES') {
                                                        wsList.forEach((item) => {
                                                            item.Status = 'Selected';
                                                        });
                                                    } else {
                                                        funcUserData.data.forEach((userDataItem) => {
                                                            let wsItem;
                                                            for (let i = 0; i < wsList.length; i++) {
                                                                wsItem = wsList[i];
                                                                if (wsItem.Id.toString() === userDataItem.Workspaces) {
                                                                    wsItem.Status = 'Selected';
                                                                    if (userDataItem.Routes !== '') {
                                                                        if (userDataItem.Routes === 'ALL ROUTES') {
                                                                            if (wsRouteList[wsItem.Id] && wsRouteList[wsItem.Id].length > 0) {
                                                                                wsRouteList[wsItem.Id].forEach((wsRtItem) => {
                                                                                    wsRtItem.Status = 'Selected';
                                                                                });
                                                                            }
                                                                        } else {
                                                                            let selRoutes = userDataItem.Routes.split(',');
                                                                            if (selRoutes.length > 0 && wsRouteList[wsItem.Id]) {
                                                                                let wsRtItem;
                                                                                for (let j = 0; j < wsRouteList[wsItem.Id].length; j++) {
                                                                                    wsRtItem = wsRouteList[wsItem.Id][j];
                                                                                    if (selRoutes.includes(wsRtItem.Id.toString())) {
                                                                                        wsRtItem.Status = 'Selected';
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                    break;
                                                                }
                                                            }
                                                        });
                                                    }
                                                    setWorkspaceList(wsList);
                                                    setWorkspaceRouteList(wsRouteList);
                                                } else {
                                                    setSavedUserFunctionData(null);
                                                    let wsList = [...workspaceList];
                                                    let wsRouteList = { ...workspaceRouteList };
                                                    wsList.forEach((item) => {
                                                        item.Status = 'Unselected';
                                                        if (wsRouteList[item.Id] && wsRouteList[item.Id].length > 0) {
                                                            wsRouteList[item.Id].forEach((wsRtItem) => {
                                                                wsRtItem.Status = 'Unselected';
                                                            });
                                                        }
                                                    });
                                                    setWorkspaceList(wsList);
                                                    setWorkspaceRouteList(wsRouteList);
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                >
                                    <RODialogLabel>Replicate to</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                >
                                    <TagBox
                                        dataSource={userPrivilegesReplicateGroupFunctionsData}
                                        valueExpr='id'
                                        grouped={true}
                                        showSelectionControls={true}
                                        applyValueMode='useButtons'
                                        displayExpr='functionName'
                                        disabled={userPrivilegesReplicateFunctions.length === 0}
                                        onValueChanged={(e) => {
                                            let target = { ...userPrivilegesTarget };
                                            target.ReplicateFunctionID = e.value;
                                            setUserPrivilegesTarget(target);
                                        }}
                                    />
                                </Grid>
                                {userPrivilegesTarget.FunctionID && (
                                    <Grid
                                        item
                                        xs={5}
                                    >
                                        <RODialogLabel>
                                            Assignable Workspaces (
                                            {
                                                workspaceList.filter((obj) => {
                                                    return obj.Status === 'Unselected';
                                                }).length
                                            }
                                            )
                                        </RODialogLabel>
                                        <DataGrid
                                            height={275}
                                            dataSource={
                                                canAccessAll
                                                    ? workspaceList
                                                    : workspaceList.filter((obj) => {
                                                          return obj.Status !== 'Selected';
                                                      })
                                            }
                                            keyExpr='Id'
                                            showBorders={true}
                                            showRowLines={true}
                                            filterSyncEnabled={true}
                                            filterValue={['Status', '=', 'Unselected']}
                                        >
                                            <RowDragging
                                                showDragIcons={true}
                                                group='workspaces'
                                            />
                                            <Scrolling mode='virtual' />
                                            <Sorting mode='none' />
                                            <Column dataField='Name'></Column>
                                            <Column
                                                dataField='Status'
                                                visible={false}
                                            ></Column>
                                        </DataGrid>
                                    </Grid>
                                )}
                                {userPrivilegesTarget.FunctionID && (
                                    <Grid
                                        item
                                        xs={2}
                                        justifyContent='center'
                                    >
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <br />
                                        <DxButton
                                            icon='chevrondoubleright'
                                            text='Assign all'
                                            width='120px'
                                            onClick={() => {
                                                let wsList = [...workspaceList];
                                                wsList.forEach((item) => {
                                                    item.Status = 'Selected';
                                                });
                                                setWorkspaceList(wsList);
                                                setWorkspaceRouteList({});
                                            }}
                                        />
                                        <br />
                                        <br />
                                        <DxButton
                                            icon='chevrondoubleleft'
                                            text='Remove all'
                                            width='120px'
                                            onClick={() => {
                                                let wsList = [...workspaceList];
                                                wsList.forEach((item) => {
                                                    item.Status = 'Unselected';
                                                });
                                                setWorkspaceList(wsList);
                                                setWorkspaceRouteList({});
                                            }}
                                        />
                                    </Grid>
                                )}
                                {userPrivilegesTarget.FunctionID && (
                                    <Grid
                                        item
                                        xs={5}
                                    >
                                        <RODialogLabel>
                                            Assigned Workspaces (
                                            {
                                                workspaceList.filter((obj) => {
                                                    return obj.Status === 'Selected';
                                                }).length
                                            }
                                            )
                                        </RODialogLabel>
                                        <DataGrid
                                            height={275}
                                            dataSource={
                                                workspaceList.filter((obj) => {
                                                    return obj.Status === 'Selected';
                                                }).length == workspaceList.length && canAccessAll
                                                    ? [{ Id: 0, Name: 'All Workspaces', Status: 'Selected' }]
                                                    : workspaceList
                                            }
                                            keyExpr='Id'
                                            showBorders={true}
                                            showRowLines={true}
                                            filterSyncEnabled={true}
                                            filterValue={['Status', '=', 'Selected']}
                                        >
                                            <MasterDetail
                                                autoExpandAll={false}
                                                enabled={
                                                    config.FUNCTIONS_THAT_ALLOW_ROUTE_SELECTION.includes(userPrivilegesTarget.FunctionID) &&
                                                    workspaceList.filter((obj) => {
                                                        return obj.Status === 'Selected';
                                                    }).length != workspaceList.length
                                                        ? true
                                                        : false
                                                }
                                                component={(props) => {
                                                    return (
                                                        <Grid
                                                            container
                                                            spacing={'10px'}
                                                            style={{ paddingTop: '10px' }}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={5}
                                                            >
                                                                <DataGrid
                                                                    width={125}
                                                                    height={150}
                                                                    dataSource={workspaceRouteList[props.data.key]}
                                                                    keyExpr='Id'
                                                                    showBorders={true}
                                                                    showRowLines={true}
                                                                    filterSyncEnabled={true}
                                                                    filterValue={['Status', '=', 'Unselected']}
                                                                >
                                                                    <RowDragging
                                                                        showDragIcons={true}
                                                                        group='workspaces'
                                                                    />
                                                                    <Scrolling mode='virtual' />
                                                                    <Sorting mode='none' />
                                                                    <Column
                                                                        dataField='Id'
                                                                        caption='Route'
                                                                    />
                                                                    <Column
                                                                        dataField='Status'
                                                                        visible={false}
                                                                    ></Column>
                                                                </DataGrid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={2}
                                                                justifyContent='center'
                                                            >
                                                                <br />
                                                                <br />
                                                                <DxButton
                                                                    icon='chevrondoubleright'
                                                                    hint='Assign all'
                                                                    width='50px'
                                                                    onClick={() => {
                                                                        let wsRteLst = { ...workspaceRouteList };
                                                                        wsRteLst[props.data.key].forEach((item) => {
                                                                            item.Status = 'Selected';
                                                                        });
                                                                        setWorkspaceRouteList(wsRteLst);
                                                                    }}
                                                                />
                                                                <br />
                                                                <br />
                                                                <DxButton
                                                                    icon='chevrondoubleleft'
                                                                    hint='Remove all'
                                                                    width='50px'
                                                                    onClick={() => {
                                                                        let wsRteLst = { ...workspaceRouteList };
                                                                        wsRteLst[props.data.key].forEach((item) => {
                                                                            item.Status = 'Unselected';
                                                                        });
                                                                        setWorkspaceRouteList(wsRteLst);
                                                                    }}
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={5}
                                                            >
                                                                <DataGrid
                                                                    width={125}
                                                                    height={150}
                                                                    dataSource={
                                                                        workspaceRouteList[props.data.key] &&
                                                                        workspaceRouteList[props.data.key].filter((obj) => {
                                                                            return obj.Status === 'Selected';
                                                                        }).length === workspaceRouteList[props.data.key].length
                                                                            ? [{ Id: 'All Routes', Name: 'All Routes', Status: 'Selected' }]
                                                                            : workspaceRouteList[props.data.key]
                                                                    }
                                                                    keyExpr='Id'
                                                                    showBorders={true}
                                                                    showRowLines={true}
                                                                    filterSyncEnabled={true}
                                                                    filterValue={['Status', '=', 'Selected']}
                                                                >
                                                                    <RowDragging
                                                                        showDragIcons={true}
                                                                        group='workspaces'
                                                                        onAdd={async (e) => {
                                                                            if (e.itemData) {
                                                                                let wsRouteList = { ...workspaceRouteList };
                                                                                for (let i = 0; i < wsRouteList[props.data.key].length; i++) {
                                                                                    if (wsRouteList[props.data.key][i].Id === e.itemData.Id) {
                                                                                        wsRouteList[props.data.key][i].Status = 'Selected';
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                setWorkspaceRouteList(wsRouteList);
                                                                            }
                                                                        }}
                                                                        onRemove={(e) => {
                                                                            if (e.itemData) {
                                                                                let wsRouteList = { ...workspaceRouteList };
                                                                                for (let i = 0; i < wsRouteList[props.data.key].length; i++) {
                                                                                    if (wsRouteList[props.data.key][i].Id === e.itemData.Id) {
                                                                                        wsRouteList[props.data.key][i].Status = 'Unselected';
                                                                                        break;
                                                                                    }
                                                                                }
                                                                                setWorkspaceRouteList(wsRouteList);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <Scrolling mode='virtual' />
                                                                    <Sorting mode='none' />
                                                                    <Column
                                                                        dataField='Id'
                                                                        caption='Route'
                                                                    />
                                                                    <Column
                                                                        dataField='Status'
                                                                        visible={false}
                                                                    ></Column>
                                                                </DataGrid>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                }}
                                            />
                                            <RowDragging
                                                showDragIcons={true}
                                                group='workspaces'
                                                onAdd={async (e) => {
                                                    if (e.itemData) {
                                                        let wsList = [...workspaceList];
                                                        let wsRouteList = { ...workspaceRouteList };
                                                        for (let i = 0; i < wsList.length; i++) {
                                                            if (wsList[i].Id === e.itemData.Id) {
                                                                wsList[i].Status = 'Selected';
                                                                const res = await getRouteList(e.itemData.Id, 0); //Routes for all service days
                                                                let wsRteLst = res.data;
                                                                wsRteLst.forEach((item) => {
                                                                    item.Status = 'Unselected';
                                                                });
                                                                wsRouteList[e.itemData.Id] = wsRteLst;
                                                                break;
                                                            }
                                                        }
                                                        setWorkspaceList(wsList);
                                                        setWorkspaceRouteList(wsRouteList);
                                                    }
                                                }}
                                                onRemove={(e) => {
                                                    if (e.itemData) {
                                                        let wsList = [...workspaceList];
                                                        let wsRouteList = { ...workspaceRouteList };
                                                        if (e.itemData.Id === 0) {
                                                            wsList.forEach((item) => {
                                                                item.Status = 'Unselected';
                                                            });
                                                            setWorkspaceRouteList({});
                                                        } else {
                                                            for (let i = 0; i < wsList.length; i++) {
                                                                if (wsList[i].Id === e.itemData.Id) {
                                                                    wsList[i].Status = 'Unselected';
                                                                    delete wsRouteList[e.itemData.Id];
                                                                    break;
                                                                }
                                                            }
                                                        }
                                                        setWorkspaceList(wsList);
                                                        setWorkspaceRouteList(wsRouteList);
                                                    }
                                                }}
                                            />
                                            <Scrolling mode='virtual' />
                                            <Sorting mode='none' />
                                            <Column dataField='Name'></Column>
                                            <Column
                                                dataField='Status'
                                                visible={false}
                                            ></Column>
                                        </DataGrid>
                                    </Grid>
                                )}
                            </Grid>
                        </DialogContent>
                    }
                    dialogActions={
                        <DialogActions style={{ justifyContent: 'right' }}>
                            <DxButton
                                id='button'
                                text='Save'
                                type='default'
                                useSubmitBehavior={true}
                                disabled={!userPrivilegesTarget.FunctionID}
                                onClick={() => {
                                    handleEditUserPrivilegesClose('SAVE');
                                }}
                            />
                            <DxButton
                                id='button'
                                text='Cancel'
                                type='normal'
                                useSubmitBehavior={false}
                                onClick={() => {
                                    handleEditUserPrivilegesClose('CANCEL');
                                }}
                            />
                        </DialogActions>
                    }
                />
            )}
            {/* //////////////////////////////// */}
            {/* add or edit dialog*/}
            {showEditUserDialog && (
                <AddEditUsers
                    open={showEditUserDialog}
                    title={`${userMode} User`}
                    dialogContent={
                        <DialogContent>
                            <Grid
                                container
                                spacing={'10px'}
                                style={{ paddingTop: '10px' }}
                            >
                                <Grid
                                    item
                                    xs={4}
                                >
                                    <RODialogLabel>User Name *</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                >
                                    <TextBox
                                        name='UserName'
                                        value={userTarget.UserName}
                                        inputAttr={{ autocomplete: 'new-username' }}
                                        onValueChanged={(e) => {
                                            updateUserForm('UserName', e.value);
                                        }}
                                    >
                                        <Validator>
                                            <RequiredRule message='User Name is required' />
                                        </Validator>
                                    </TextBox>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                >
                                    <RODialogLabel>Preferred User Name</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={8}
                                >
                                    <TextBox
                                        name='PreferredUsername'
                                        value={userTarget.PreferredUsername}
                                        onValueChanged={(e) => {
                                            updateUserForm('PreferredUsername', e.value);
                                        }}
                                    ></TextBox>
                                </Grid>
                                {editUserPasswordVisible && (
                                    <Grid
                                        item
                                        xs={5}
                                    >
                                        <RODialogLabel>Password</RODialogLabel>
                                        <TextBox
                                            id='Password'
                                            name='Password'
                                            mode={passwordMode}
                                            inputAttr={{ autocomplete: 'new-password' }}
                                            onValueChanged={(e) => {
                                                updateUserForm('Password', e.value);
                                            }}
                                        >
                                            <TextBoxButton
                                                name='password'
                                                location='after'
                                                options={{
                                                    //icon: '../../_images/icons/FA_eye.svg',
                                                    //icon: 'FA_EYE',
                                                    icon: passwordMode === 'password' ? 'equal' : 'notequal',
                                                    hint: passwordMode === 'password' ? 'Show password' : 'Hide password',
                                                    type: 'normal',
                                                    onClick: () => {
                                                        if (passwordMode === 'text') setPasswordMode('password');
                                                        else setPasswordMode('text');
                                                    },
                                                }}
                                            >
                                                {/*<i className={'FA_EYE'}></i>*/}
                                            </TextBoxButton>
                                            <Validator>
                                                <RequiredRule message='Password is required' />
                                                <PatternRule
                                                    message='Password must use at least 1 uppercase letter'
                                                    pattern={/[A-Z]/}
                                                />
                                                <PatternRule
                                                    message='Password must use at least 1 lowercase letter'
                                                    pattern={/[a-z]/}
                                                />
                                                <PatternRule
                                                    message='Password must use at least 1 number'
                                                    pattern={/\d/}
                                                />
                                                <PatternRule
                                                    message='Password must use at least 1 symbol'
                                                    pattern={/[$-/:-?{-~!"^_`@#\[\]\\]/}
                                                />
                                                <StringLengthRule
                                                    message='Password must be between 8 - 16 characters'
                                                    min={8}
                                                    max={16}
                                                />
                                            </Validator>
                                        </TextBox>
                                    </Grid>
                                )}
                                {editUserPasswordVisible && (
                                    <Grid
                                        item
                                        xs={1}
                                    >
                                        <RODialogLabel>&nbsp;</RODialogLabel>
                                        <Popover
                                            target='#PasswordHint'
                                            position='top'
                                            width={250}
                                            visible={userPopoverVisible}
                                        >
                                            <ul style={{ listStyle: 'none' }}>
                                                <li>
                                                    <font
                                                        style={{ color: userTarget.Password && userTarget.Password.match(/[A-Z]/) ? 'green' : 'red' }}
                                                    >
                                                        At least <strong>1 uppercase letter</strong>
                                                    </font>
                                                </li>
                                                <li>
                                                    <font
                                                        style={{ color: userTarget.Password && userTarget.Password.match(/[a-z]/) ? 'green' : 'red' }}
                                                    >
                                                        At least <strong>1 lowercase letter</strong>
                                                    </font>
                                                </li>
                                                <li>
                                                    <font style={{ color: userTarget.Password && userTarget.Password.match(/\d/) ? 'green' : 'red' }}>
                                                        At least <strong>1 number</strong>
                                                    </font>
                                                </li>
                                                <li>
                                                    <font
                                                        style={{
                                                            color:
                                                                userTarget.Password && userTarget.Password.match(/[$-/:-?{-~!"^_`@#\[\]\\]/)
                                                                    ? 'green'
                                                                    : 'red',
                                                        }}
                                                    >
                                                        At least <strong>1 symbol</strong>
                                                    </font>
                                                </li>
                                                <li>
                                                    <font
                                                        style={{
                                                            color:
                                                                userTarget.Password &&
                                                                userTarget.Password.length > 7 &&
                                                                userTarget.Password.length < 17
                                                                    ? 'green'
                                                                    : 'red',
                                                        }}
                                                    >
                                                        Between <strong> 8 - 16 </strong> characters
                                                    </font>
                                                </li>
                                            </ul>
                                        </Popover>{' '}
                                        <a
                                            id='PasswordHint'
                                            onMouseEnter={() => {
                                                setUserPopoverVisible(true);
                                            }}
                                            onMouseLeave={() => {
                                                setUserPopoverVisible(false);
                                            }}
                                        >
                                            <DxButton
                                                icon='info'
                                                type={
                                                    userTarget.Password &&
                                                    userTarget.Password.match(/[A-Z]/) &&
                                                    userTarget.Password.match(/[a-z]/) &&
                                                    userTarget.Password.match(/\d/) &&
                                                    userTarget.Password.match(/[$-/:-?{-~!"^_`@#\[\]\\]/) &&
                                                    userTarget.Password.length > 7 &&
                                                    userTarget.Password.length < 17
                                                        ? 'success'
                                                        : 'danger'
                                                }
                                            ></DxButton>
                                        </a>
                                    </Grid>
                                )}
                                {editUserPasswordVisible && (
                                    <Grid
                                        item
                                        xs={6}
                                    >
                                        <RODialogLabel>Confirm Password</RODialogLabel>
                                        <TextBox
                                            name='ConfirmPassword'
                                            mode={confirmPasswordMode}
                                            inputAttr={{ autocomplete: 'new-password' }}
                                            onValueChanged={(e) => {
                                                updateUserForm('ConfirmPassword', e.value);
                                            }}
                                        >
                                            <TextBoxButton
                                                name='password'
                                                location='after'
                                                options={{
                                                    icon: confirmPasswordMode === 'password' ? 'equal' : 'notequal',
                                                    hint: confirmPasswordMode === 'password' ? 'Show password' : 'Hide password',
                                                    type: 'normal',
                                                    onClick: () => {
                                                        if (confirmPasswordMode === 'text') setConfirmPasswordMode('password');
                                                        else setConfirmPasswordMode('text');
                                                    },
                                                }}
                                            ></TextBoxButton>
                                            <Validator>
                                                <RequiredRule message='Confirm Password is required' />
                                                <CompareRule
                                                    message='Password and Confirm Password do not match'
                                                    comparisonTarget={() => {
                                                        return userTarget.Password;
                                                    }}
                                                />
                                            </Validator>
                                        </TextBox>
                                    </Grid>
                                )}
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <RODialogLabel>Email *</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                >
                                    <TextBox
                                        name='Email'
                                        value={userTarget.Email}
                                        inputAttr={{ autocomplete: 'new-email' }}
                                        onValueChanged={(e) => {
                                            updateUserForm('Email', e.value);
                                        }}
                                    >
                                        <Validator>
                                            <RequiredRule message='Email is required' />
                                            <EmailRule message='Email is invalid' />
                                        </Validator>
                                    </TextBox>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <RODialogLabel>First Name</RODialogLabel>
                                    <TextBox
                                        name='FirstName'
                                        value={userTarget.FirstName}
                                        inputAttr={{ autocomplete: 'new-firstname' }}
                                        onValueChanged={(e) => {
                                            updateUserForm('FirstName', e.value);
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <RODialogLabel>Last Name</RODialogLabel>
                                    <TextBox
                                        name='LastName'
                                        value={userTarget.LastName}
                                        inputAttr={{ autocomplete: 'new-lastname' }}
                                        onValueChanged={(e) => {
                                            updateUserForm('LastName', e.value);
                                        }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <RODialogLabel>Role</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                >
                                    <SelectBox
                                        dataSource={assignableRoles}
                                        value={userTarget.RoleId}
                                        displayExpr='text'
                                        valueExpr='id'
                                        onValueChanged={(e) => {
                                            updateUserForm('RoleId', e.value);
                                        }}
                                    >
                                        <Validator>
                                            <RequiredRule message='Role Id is required' />
                                        </Validator>
                                    </SelectBox>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <RODialogLabel>Group</RODialogLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={9}
                                >
                                    <SelectBox
                                        dataSource={assignableGroups}
                                        value={userTarget.GroupId}
                                        displayExpr='text'
                                        valueExpr='id'
                                        onValueChanged={(e) => {
                                            updateUserForm('GroupId', e.value);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <ValidationSummary id='summary'></ValidationSummary>
                        </DialogContent>
                    }
                    dialogActions={
                        <DialogActions style={{ justifyContent: 'right' }}>
                            {userMode === 'Edit' && (
                                <div style={{ justifyContent: 'left' }}>
                                    <DxButton
                                        width={200}
                                        text={editUserPasswordVisible ? 'Cancel Change Password' : 'Change Password'}
                                        type='normal'
                                        stylingMode='text'
                                        onClick={() => {
                                            setEditUserPasswordVisible(!editUserPasswordVisible);
                                        }}
                                    />
                                </div>
                            )}
                            <DxButton
                                id='button'
                                text='Save'
                                type='default'
                                useSubmitBehavior={true}
                                onClick={() => {
                                    handleEditUserClose('SAVE');
                                }}
                            />
                            <DxButton
                                id='button'
                                text='Cancel'
                                type='normal'
                                useSubmitBehavior={false}
                                onClick={() => {
                                    handleEditUserClose('CANCEL');
                                }}
                            />
                        </DialogActions>
                    }
                />
            )}
            {/* //////////////////////////////// */}
            {/* delete dialog*/}
            {showDeleteUserDialog && (
                <DeleteUsers
                    open={showDeleteUserDialog}
                    title={`${userMode} User`}
                    handleClick={handleDeleteUserClose}
                    userTarget={userTarget}
                />
            )}
            {/* //////////////////////////////// */}
        </>
    );
};

export default Users;
