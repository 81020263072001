import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import { GlobalROProvider } from './_components/common';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const root = ReactDOM.createRoot(document.getElementById('root'));

const Loader = () => (
    <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
    </Box>
);

root.render(
    <Suspense fallback={<Loader />}>
        <GlobalROProvider>
            <App />
        </GlobalROProvider>
    </Suspense>,
);
