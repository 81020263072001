import React from 'react';
import { RODialog, ROGreenButton, ROOrangeButton } from '../../../common';
import DialogActions from '@mui/material/DialogActions';

// open={showDeleteRoleDialog}
// tile={`${roleMode} Role`}
// handleClick={handleEditRoleClose}
// roleTarget = { roleTarget }

const AddEditRoles = (props) => {
    return (
        <RODialog
            maxWidth='lg'
            fullWidth={true}
            open={props.open}
            dialogTitle={props.title}
            dialogActions={
                <DialogActions style={{ justifyContent: 'right' }}>
                    {props.roleTarget.RoleName && (
                        <ROGreenButton
                            onClick={() => {
                                props.handleClick('SAVE');
                            }}
                        >
                            Save
                        </ROGreenButton>
                    )}
                    <ROOrangeButton
                        onClick={() => {
                            props.handleClick('CANCEL');
                        }}
                    >
                        Cancel
                    </ROOrangeButton>
                </DialogActions>
            }
        >
            {/* content here */}
            {props.children}
        </RODialog>
    );
};

export default AddEditRoles;
