import React from 'react';
import MaterialReactTable from 'material-react-table';
import { SelectBox } from 'devextreme-react';
import { ToggleButton, ToggleButtonGroup, IconButton } from '@mui/material';

export const SavedSceranio = (props) => {
    const {
        scenarioList,
        selectedScenarioId,
        selectedScenarioRoutes,
        scenarioRouteList,
        showScenarioSequenceLabel,
        showScenarioTravelPathLabel,
        bottomPaneGrid,
        bottomPaneVisibility,
        showScenarioReport,
        showScenarioExport,
        showScenarioTravelPathLabelBtnTooltip,
        setShowScenarioTravelPathLabel,
        travelPathLabelTooltip,
        selScenarioTravelPathRoute,
        selScenarioSequenceRoute,
        selScenarioMapToolChangeHandler,
        setSelectedScenarioId,
        setSelectedScenarioRoutes,
        setShowScenarioSequenceLabel,
        toggleScenarioTravelPathLayer,
        toggleScenarioSequenceLayer,
        handleZoomToScenarioRoute,
        handleZoomToSelectedScenarioRoutes,
    } = props;

    const [rowSelection, setRowSelection] = React.useState({});
    const lock = React.useRef(false);

    const tableInstanceRef = React.createRef(null);
    let tableRowLength = tableInstanceRef?.current?.refs?.tableContainerRef?.current?.clientWidth || 307;

    let checkboxW = 30;
    let svgW = 15;
    let zoomW = 30;
    let travelPathW = 30;
    let sequenceW = 30;

    let sumOfOtherColumns = checkboxW + svgW + zoomW + travelPathW + sequenceW;
    let calculatedLabelWidth = tableRowLength - sumOfOtherColumns;

    let widthOfScroll = 15;

    let columns = [
        {
            header: '',
            accessorKey: 'Svg',
            size: svgW,
            minSize: svgW,
            maxSize: svgW,
            enableGlobalFilter: false,
            Cell: ({ cell, row }) => {
                return (
                    <div
                        style={{ width: '10px' }}
                        dangerouslySetInnerHTML={{ __html: cell.getValue() }}
                    ></div>
                );
            },
        },
        {
            header: 'Route',
            accessorKey: 'Label',
            minSize: calculatedLabelWidth - widthOfScroll,
            maxSize: calculatedLabelWidth,
        },

        {
            header: '',
            accessorKey: 'travelPath',
            size: travelPathW,
            minSize: travelPathW,
            maxSize: travelPathW,
            enableGlobalFilter: false,
            Header: ({ column }) => (
                <div
                    title={showScenarioTravelPathLabelBtnTooltip}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setShowScenarioTravelPathLabel(!showScenarioTravelPathLabel);
                    }}
                >
                    <i
                        className={
                            showScenarioTravelPathLabel
                                ? showScenarioTravelPathLabelBtnTooltip === travelPathLabelTooltip
                                    ? 'RS_LABEL'
                                    : 'RS_LABEL_RED'
                                : 'RS_LABEL_GR'
                        }
                    ></i>
                </div>
            ),
            Cell: ({ cell, row }) => {
                return (
                    <div
                        title='Show arrow head travel path'
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleScenarioTravelPathLayer(row.original.Id === 'No Route' ? '0' : row.original.Id);
                        }}
                    >
                        <i className={selScenarioTravelPathRoute === row.original.Id + '|1' ? 'RS_TRAVEL_PATH' : 'RS_TRAVEL_PATH_GR'}></i>
                    </div>
                );
            },
        },
        {
            header: '',
            accessorKey: 'sequence',
            size: sequenceW,
            minSize: sequenceW,
            maxSize: sequenceW,
            enableGlobalFilter: false,
            Header: ({ column }) => (
                <div
                    title='Show sequence labels'
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setShowScenarioSequenceLabel(!showScenarioSequenceLabel);
                    }}
                >
                    <i className={showScenarioSequenceLabel ? 'RS_LABEL' : 'RS_LABEL_GR'}></i>
                </div>
            ),
            Cell: ({ cell, row }) => {
                return (
                    <div
                        title='Show sequence'
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleScenarioSequenceLayer(row.original.Id === 'No Route' ? '0' : row.original.Id);
                        }}
                    >
                        <i className={selScenarioSequenceRoute === row.original.Id ? 'RS_SEQUENCE' : 'RS_SEQUENCE_GR'}></i>
                    </div>
                );
            },
        },
        {
            header: '',
            accessorKey: 'zoom',
            size: zoomW,
            minSize: zoomW,
            maxSize: zoomW,
            enableGlobalFilter: false,
            Cell: ({ cell, row }) => {
                return (
                    <div
                        title='Zoom to route'
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleZoomToScenarioRoute(row.original.Id === 'No Route' ? '0' : row.original.Id);
                        }}
                    >
                        <i className='RS_ZOOM'></i>
                    </div>
                );
            },
        },
    ];

    React.useEffect(() => {
        if (!lock.current) {
            let selected = [];
            Object.keys(rowSelection).forEach((key, index) => {
                selected.push(key.toString());
            });
            setSelectedScenarioRoutes(selected);
        } else {
            lock.current = false;
        }
    }, [rowSelection]);

    React.useEffect(() => {
        if (selectedScenarioRoutes) {
            let selected = {};
            scenarioRouteList.forEach((value) => {
                if (selectedScenarioRoutes.includes(value.Id)) {
                    selected[value.Id] = true;
                }
            });
            if (JSON.stringify(selected) != JSON.stringify(rowSelection)) {
                lock.current = true;
                setRowSelection(selected);
            }
        }
    }, [selectedScenarioRoutes]);

    return (
        <div style={{ maxWidth: '100%', paddingTop: '5px', height: '278px' }}>
            <ToggleButtonGroup
                style={{ float: 'right', paddingTop: '2px' }}
                exclusive
                onChange={(e, v) => selScenarioMapToolChangeHandler(v)}
            >
                <ToggleButton
                    value='ScenarioRouteSummary'
                    title='View Summary'
                    style={
                        bottomPaneVisibility && bottomPaneGrid === 'ScenarioRouteSummary'
                            ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 16, padding: '0px' }
                            : { fontSize: 16, padding: '0px', filter: 'grayscale(1)' }
                    }
                >
                    <span className='RS_ROUTE_SUMMARY' />
                </ToggleButton>
                <ToggleButton
                    value='ScenarioServiceLocation'
                    title='View Stops'
                    style={
                        bottomPaneVisibility && bottomPaneGrid === 'ScenarioServiceLocation'
                            ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 16, padding: '0px' }
                            : { fontSize: 16, padding: '0px', filter: 'grayscale(1)' }
                    }
                >
                    <span className='RS_GEOCODE_SERVICE_LOCATION' />
                </ToggleButton>
                {/* <ToggleButton
                    value='ReportSelScenarioRoutes'
                    title='Download Route Report'
                    style={
                        showScenarioReport
                            ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 16, padding: '0px' }
                            : { fontSize: 16, padding: '0px', filter: 'grayscale(1)' }
                    }
                >
                    <span className='RS_REPORT' />
                </ToggleButton>
                <ToggleButton
                    value='ExportSelScenarioRoutes'
                    title='Export Stops'
                    style={
                        showScenarioExport
                            ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 16, padding: '0px' }
                            : { fontSize: 16, padding: '0px', filter: 'grayscale(1)' }
                    }
                >
                    <span className='RS_EXPORT_SERVICE_LOCATION' />
                </ToggleButton> */}
            </ToggleButtonGroup>
            <SelectBox
                dataSource={scenarioList}
                searchEnabled={true}
                searchMode={'contains'}
                placeholder='Select dispatch scenario...'
                displayExpr='label'
                valueExpr='RaaSJobID'
                width='200px'
                value={selectedScenarioId}
                onValueChanged={(e) => {
                    setSelectedScenarioId(e.value);
                }}
            />
            <div style={{ whiteSpace: 'nowrap', verticalAlign: 'middle', paddingTop: '5px', paddingBottom: '5px' }}>
                <label>
                    <b>Routes:</b> {selectedScenarioRoutes.length} selected of {scenarioRouteList.length} routes
                </label>
            </div>
            <>
                <MaterialReactTable
                    columns={columns}
                    data={scenarioRouteList}
                    enableTopToolbar={true}
                    enableStickyHeader={true}
                    enableSorting={false}
                    enableColumnFilters={false}
                    enablePagination={false}
                    enableBottomToolbar={false}
                    enableColumnActions={false}
                    enableFullScreenToggle={false}
                    enableDensityToggle={false}
                    enableColumnOrdering={false}
                    enableColumnResizing={false}
                    enableRowSelection={true}
                    enableHiding={false}
                    enableFilterMatchHighlighting={false}
                    selectAllMode='all'
                    // layoutMode="grid"
                    positionToolbarAlertBanner='none'
                    initialState={{
                        density: 'compact',
                        showGlobalFilter: true,
                        sorting: [{ id: 'Label', desc: false }],
                    }}
                    state={{
                        rowSelection,
                    }}
                    onRowSelectionChange={setRowSelection}
                    defaultColumn={{
                        maxSize: 30,
                        minSize: 30,
                        size: 30,
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-select': {
                            enableHiding: true,
                        },
                    }}
                    muiTopToolbarProps={{
                        sx: {
                            height: '40px',
                            minHeight: '40px',
                        },
                    }}
                    muiTableBodyRowProps={({ row }) => ({
                        onClick: row.getToggleSelectedHandler(),
                        sx: { cursor: 'pointer' },
                    })}
                    muiTableHeadCellProps={{
                        sx: {
                            flex: '0 0 auto',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            padding: 0,
                        },
                    }}
                    muiTableBodyCellProps={{
                        sx: {
                            flex: '0 0 auto',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            padding: 0,
                        },
                    }}
                    muiSearchTextFieldProps={{
                        sx: { maxWidth: '150px' },
                        InputProps: {
                            sx: { fontSize: '12px', height: '30px' },
                        },
                        variant: 'outlined',
                    }}
                    muiSelectAllCheckboxProps={{
                        color: 'default',
                        title: '',
                    }}
                    muiSelectCheckboxProps={{
                        color: 'default',
                        title: '',
                    }}
                    muiTableProps={{
                        sx: { tableLayout: 'fixed' },
                    }}
                    muiTableContainerProps={{
                        sx: { maxHeight: '180px', minHeight: '180px', overflowX: 'hidden' },
                    }}
                    getRowId={(originalRow) => originalRow.Id}
                    renderTopToolbarCustomActions={({ table }) => {
                        return (
                            <IconButton
                                value='ZoomToSelRoutes'
                                title='Zoom to Selected Routes'
                                style={{ marginTop: '2px', marginLeft: '5px', padding: '2px', fontSize: '12px' }}
                                onClick={handleZoomToSelectedScenarioRoutes}
                            >
                                <span className='RS_ZOOM_TO_SELECT' />
                            </IconButton>
                        );
                    }}
                    tableInstanceRef={tableInstanceRef} //get access to the underlying table instance ref
                />
            </>
        </div>
    );
};
