import React, { useContext } from 'react';
import { refreshRoute, uploadWorkspace } from '../../_actions/Global.actions';
import { Button, Grid } from '@mui/material';
import MaterialReactTable from 'material-react-table';
import { updateUserActivity, checkFunction } from '../../_actions/Global.actions';
import { UTCToLocal } from '../../_helpers/date';
import { GlobalROContext } from '../common';
import { useTranslation } from 'react-i18next';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

const RefreshWorkspaceTable = (props) => {
    const { data, workspaceFileInput, handleFileChange, handleUpdateWorkspace, handleRefreshRoute, loading } = props;

    const { t } = useTranslation();

    const locale = localStorage.getItem('i18nextLng') || null;

    let localization = '';
    if (locale === 'es') {
        localization = MRT_Localization_ES;
    } else {
        localization = MRT_Localization_EN;
    }

    let columns = [
        {
            header: t('Administration.Columns.Name'),
            accessorKey: 'Name',
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { sx: { borderBottom: 'none' } },
        },
        {
            header: t('Administration.Columns.LastProcessed'),
            accessorKey: 'Date',
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center', sx: { borderBottom: 'none' } },
            sortingFn: (rowA, rowB, columnId) => {
                if (rowA.original.Date && rowB.original.Date) {
                    return new Date(rowA.original.Date) - new Date(rowB.original.Date);
                } else if (rowA.original.Date) {
                    return 1;
                } else if (rowB.original.Date) {
                    return -1;
                }
            },
            Cell: ({ cell, row }) => (cell.getValue() ? UTCToLocal(cell.getValue(), 'MM/dd/yyyy hh:mm:ss a') : row.original.Status),
        },
    ];

    if (checkFunction(1502)) {
        columns.push({
            header: t('Administration.Columns.Workspace'),
            accessorKey: 'UpdateWorkspace',
            size: 100,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center', sx: { borderBottom: 'none' } },
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell, row }) => {
                if (!['In Queue', 'In Progress'].includes(row.original.Status)) {
                    return (
                        <>
                            <Button
                                className={'icon_button'}
                                title={t('Administration.Actions.UpdateWorkspace')}
                                color='primary'
                                style={{ color: '#bbb', marginLeft: '5px', padding: '0', fontSize: '14px' }}
                                onClick={() => handleUpdateWorkspace(row.original.Id)}
                            >
                                <i
                                    className='RS_REFRESH_DATA'
                                    style={{ fontSize: 20 }}
                                />
                            </Button>
                            <input
                                ref={workspaceFileInput}
                                onChange={handleFileChange}
                                type='file'
                                style={{ display: 'none' }}
                                accept={'.csv'}
                            />
                        </>
                    );
                } else {
                    return null;
                }
            },
        });
    }

    if (checkFunction(1503)) {
        columns.push({
            header: t('Administration.Columns.Routes'),
            accessorKey: 'RefreshRoute',
            size: 100,
            muiTableHeadCellProps: { align: 'center' },
            muiTableBodyCellProps: { align: 'center', sx: { borderBottom: 'none' } },
            enableColumnActions: false,
            enableSorting: false,
            Cell: ({ cell, row }) => {
                if (!['In Queue', 'In Progress'].includes(row.original.Status)) {
                    return (
                        <>
                            <Button
                                className={'icon_button'}
                                title={t('Administration.Actions.RefreshRoutes')}
                                color='primary'
                                style={{ color: '#bbb', marginLeft: '5px', padding: '0', fontSize: '14px' }}
                                onClick={() => handleRefreshRoute(row.original.Id)}
                            >
                                <i
                                    className='RS_REFRESH_DATA'
                                    style={{ fontSize: 20 }}
                                />
                            </Button>
                        </>
                    );
                } else {
                    return null;
                }
            },
        });
    }

    return (
        <MaterialReactTable
            columns={columns}
            data={data}
            enableTopToolbar={true}
            enableStickyHeader={true}
            enableColumnFilters={false}
            enablePagination={false}
            enableBottomToolbar={false}
            enableColumnActions={false}
            enableFullScreenToggle={false}
            enableDensityToggle={false}
            enableHiding={false}
            enableSortingRemoval={false}
            initialState={{
                density: 'compact',
                showGlobalFilter: true,
                sorting: [{ id: 'Name', desc: false }],
            }}
            muiTableContainerProps={{
                sx: { maxHeight: '600px' },
            }}
            state={{ isLoading: loading }}
            localization={localization}
        />
    );
};

export const Administration = () => {
    const [id, setId] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const workspaceFileInput = React.useRef(null);

    const {
        setShowWorkspaceList,
        setShowServiceDayList,
        setShowRoutePlanList,
        setShowWeekList,
        setShowFrequencyList,
        setShowServicePatternList,
        workspaceStatus,
        setRefreshWorkspaceStatus,
        setOpenAlert,
        setAlertTitle,
        setAlertMessage,
    } = useContext(GlobalROContext);

    React.useEffect(() => {
        setRefreshWorkspaceStatus(true);
        setShowWorkspaceList(false);
        setShowServiceDayList(false);
        setShowRoutePlanList(false);
        setShowWeekList(false);
        setShowFrequencyList(false);
        setShowServicePatternList(false);
        updateUserActivity({ ActionId: 1501 });
    }, []);

    const handleUpdateWorkspace = (id) => {
        setId(id);
        workspaceFileInput.current.click();
    };
    const handleFileChange = (e) => {
        const fileUploaded = e.target.files[0];
        uploadFile(fileUploaded);
    };

    const uploadFile = (file) => {
        setLoading(true);
        const input = { ...workspaceFileInput };
        uploadWorkspace(id, file).then(
            (res) => {
                setId(null);
                input.current.value = null;
                setTimeout(() => {
                    setRefreshWorkspaceStatus(true);
                    setLoading(false);
                }, 5000);

                const logData = {
                    WorkspaceId: id,
                    ActionId: 1503,
                    Success: true,
                    Metadata: id,
                };
                updateUserActivity(logData);
            },
            (err) => {
                setId(null);
                input.current.value = null;
                setTimeout(() => {
                    setRefreshWorkspaceStatus(true);
                    setLoading(false);
                }, 1000);
                setOpenAlert(true);
                setAlertTitle('Error');
                setAlertMessage(`${JSON.stringify(err.response.data)}`);

                const logData = {
                    WorkspaceId: id,
                    ActionId: 1503,
                    Success: false,
                    Metadata: id,
                };
                updateUserActivity(logData);
            },
        );
    };

    const handleRefreshRoute = (id) => {
        setLoading(true);
        refreshRoute(id).then(
            (res) => {
                setTimeout(() => {
                    setRefreshWorkspaceStatus(true);
                    setLoading(false);
                }, 5000);
                const logData = {
                    WorkspaceId: id,
                    ActionId: 1504,
                    Success: true,
                    Metadata: id,
                };
                updateUserActivity(logData);
            },
            (err) => {
                setTimeout(() => {
                    setRefreshWorkspaceStatus(true);
                    setLoading(false);
                }, 1000);
                setOpenAlert(true);
                setAlertTitle('Error');
                setAlertMessage(`${JSON.stringify(err.response.data)}`);

                const logData = {
                    WorkspaceId: id,
                    ActionId: 1504,
                    Success: false,
                    Metadata: id,
                };
                updateUserActivity(logData);
            },
        );
    };

    return (
        <Grid
            container
            wrap={'nowrap'}
            spacing={0}
            style={{ marginTop: 10 }}
            alignItems='center'
            justifyContent='center'
        >
            <Grid
                item
                xs={8}
                id={'RefreshWorkspaceTable'}
            >
                <RefreshWorkspaceTable
                    data={workspaceStatus}
                    loading={loading}
                    workspaceFileInput={workspaceFileInput}
                    handleUpdateWorkspace={handleUpdateWorkspace}
                    handleFileChange={handleFileChange}
                    handleRefreshRoute={handleRefreshRoute}
                />
            </Grid>
        </Grid>
    );
};
