import axios from 'axios';
import { config } from '../_helpers/config';

export const getIpd = () => {
    const url = `${config.ROApi}idp`;
    return axios.get(url);
};

export const userLogin = (data, isAdmin) => {
    const url = `${isAdmin ? config.ROAdminApi : config.ROApi}token`;
    return axios.post(url, new URLSearchParams(data).toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
};

export const getClients = () => {
    const url = `${config.ROAdminApi}clients`;
    const adminToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).adminToken : '';
    return axios.get(url, { headers: { Authorization: 'Bearer ' + adminToken } });
};

export const clientLogin = (data) => {
    let origin = window.location.origin;
    if (origin.includes('localhost')) origin = 'https://admin.dev.routesmartonline.com';
    const url = `${config.ROApi}token?requestUri=${origin}`;
    return axios.post(url, new URLSearchParams(data).toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
};

export const getClientToken = (clientId) => {
    const url = `${config.ROAdminApi}token/clients/${clientId}`;
    const adminToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).adminToken : '';
    return axios.post(url, null, { headers: { Authorization: 'Bearer ' + adminToken } });
};

export const getUserFunctions = () => {
    const url = `${config.ROApi}pagefunctions/AssignableFunctions`;
    const accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    return axios.get(url, { headers: { Authorization: 'Bearer ' + accessToken } });
};

export const getIndustry = (clientId) => {
    const url = `${config.ROApi}industry/${clientId}`;
    const accessToken = window.sessionStorage['userInfo'] ? JSON.parse(window.sessionStorage['userInfo']).accessToken : '';
    return axios.get(url, { headers: { Authorization: 'Bearer ' + accessToken } });
};

export const resetPassword = (userName) => {
    const url = `${config.ROApi}users/resetPassword?username=${userName}`;
    return axios.post(url);
};
