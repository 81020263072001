import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialReactTable from 'material-react-table';
import { getSpecialties, createSpecialty, deleteSpecialty } from '../../_actions/Manage.actions';
import { GlobalROContext } from '../common';
import { getErrorMessage } from '../../_helpers/common';

export const ManagementSpecialtyDialog = (props) => {
    const { workspace, manageSpecialtyDialogOpen, setManageSpecialtyDialogOpen } = props;
    const { showApiROErrorDialog, setShowApiROErrorDialog } = useContext(GlobalROContext);
    const [refreshSpecialtyTable, setRefreshSpecialtyTable] = useState(false);
    const [addSpecialtyDialogOpen, setAddSpecialtyDialogOpen] = useState(false);

    const [data, setData] = useState([]);

    useEffect(() => {
        if (manageSpecialtyDialogOpen) {
            getSpecialties(workspace.value).then((res) => {
                setData(res.data);
            });
        }
    }, [manageSpecialtyDialogOpen]);

    useEffect(() => {
        if (refreshSpecialtyTable) {
            setRefreshSpecialtyTable(false);
            getSpecialties(workspace.value).then((res) => {
                setData(res.data);
            });
        }
    }, [refreshSpecialtyTable]);

    const handleAddSpecialty = () => {
        setAddSpecialtyDialogOpen(true);
    };

    const handleRemoveSpecialty = (data) => {
        deleteSpecialty(workspace.value, data.Id).then(
            (res) => {
                setRefreshSpecialtyTable(true);
            },
            (err) => {
                setShowApiROErrorDialog(getErrorMessage(err));
            },
        );
    };

    const columns = [
        {
            header: 'Specialty',
            accessorKey: 'Specialty',
            size: 200,
            enableSorting: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
        },
        {
            header: '',
            accessorKey: 'actions',
            size: 50,
            enableSorting: false,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '10px', color: 'white', backgroundColor: '#369cd9' } },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px' } },
            Cell: ({ cell, row }) => {
                return (
                    !row.original.Assigned && (
                        <Box>
                            <IconButton
                                onClick={() => handleRemoveSpecialty(row.original)}
                                sx={{ padding: 0 }}
                                title='Delete Specialty'
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    )
                );
            },
        },
    ];

    return (
        <>
            <Dialog open={manageSpecialtyDialogOpen}>
                <DialogTitle style={{ padding: '5px 24px' }}>
                    Manage Specialty
                    <IconButton
                        aria-label='close'
                        onClick={() => setManageSpecialtyDialogOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ paddingTop: '5px' }}>
                    <div
                        id='specialityDiv'
                        style={{ width: '450px', background: 'white' }}
                    >
                        <MaterialReactTable
                            columns={columns}
                            data={data}
                            enableTopToolbar={true}
                            enableStickyHeader={true}
                            enableStickyFooter={true}
                            enableColumnFilters={false}
                            enablePagination={false}
                            enableBottomToolbar={false}
                            enableColumnActions={false}
                            enableFullScreenToggle={false}
                            enableDensityToggle={false}
                            enableColumnOrdering={false}
                            enableColumnResizing={false}
                            enableRowSelection={false}
                            enableHiding={false}
                            enableGlobalFilter={false}
                            positionToolbarAlertBanner='none'
                            initialState={{
                                density: 'compact',
                                sorting: [{ id: 'Specialty', desc: false }],
                            }}
                            muiTopToolbarProps={{
                                sx: { minHeight: '20px' },
                            }}
                            muiTableContainerProps={{
                                sx: { maxHeight: '500px' },
                            }}
                            renderTopToolbarCustomActions={({ table }) => (
                                <Box>
                                    <Button
                                        onClick={handleAddSpecialty}
                                        variant='outlined'
                                        title='Add Specialty'
                                        style={{ minWidth: '35px', padding: '0 10px' }}
                                    >
                                        + Specialty
                                    </Button>
                                </Box>
                            )}
                        />
                    </div>
                </DialogContent>
            </Dialog>
            <AddSpecialtytDialog
                workspace={workspace}
                setRefreshSpecialtyTable={setRefreshSpecialtyTable}
                addSpecialtyDialogOpen={addSpecialtyDialogOpen}
                setAddSpecialtyDialogOpen={setAddSpecialtyDialogOpen}
            />
        </>
    );
};

export const AddSpecialtytDialog = (props) => {
    const { workspace, addSpecialtyDialogOpen, setAddSpecialtyDialogOpen, setRefreshSpecialtyTable } = props;
    const { showApiROErrorDialog, setShowApiROErrorDialog } = useContext(GlobalROContext);

    const [specialty, setSpecialty] = useState('');

    useEffect(() => {
        if (addSpecialtyDialogOpen) {
            setSpecialty('');
        }
    }, [addSpecialtyDialogOpen]);

    const handleAddSpecialty = (mode) => {
        if (mode === 'CANCEL') {
            setAddSpecialtyDialogOpen(false);
        } else {
            let data = {
                specialty: specialty,
            };
            createSpecialty(workspace.value, data).then(
                (res) => {
                    setAddSpecialtyDialogOpen(false);
                    setRefreshSpecialtyTable(true);
                },
                (err) => {
                    if (!showApiROErrorDialog) {
                        setShowApiROErrorDialog(getErrorMessage(err));
                    }
                },
            );
        }
    };

    return (
        <Dialog
            open={addSpecialtyDialogOpen}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>Add Specialty</DialogTitle>
            <DialogContent>
                <FormControl variant='standard'>
                    <InputLabel>Name</InputLabel>
                    <TextField
                        sx={{ marginTop: '50px', minWidth: '380px' }}
                        variant='outlined'
                        value={specialty}
                        onChange={(e) => setSpecialty(e.target.value)}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleAddSpecialty('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleAddSpecialty('SAVE')}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
