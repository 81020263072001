import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 'devextreme/dist/css/dx.light.compact.css';
import { debounce } from '../../_helpers/common';
import { updateUserActivity, checkFunction, logout } from '../../_actions/Global.actions';
import Users from './Users';
import Roles from './Roles';
import Groups from './Groups';
import { GlobalROContext } from '../common';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const AccountManagement = () => {
    const { setShowWorkspaceList, setShowServiceDayList, setShowRoutePlanList, setShowWeekList, setShowFrequencyList, setShowServicePatternList } =
        useContext(GlobalROContext);

    const [tabIndex, setTabIndex] = useState(0);
    const [windowDimensions, setWindowDimensions] = useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        updateUserActivity({ ActionId: 901 });
        const debouncedHandleResize = debounce(function handleResize() {
            setWindowDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }, 1000);
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
            }
        };

        window.addEventListener('load', debouncedHandleResize);
        window.addEventListener('resize', debouncedHandleResize);
        window.addEventListener('keydown', handleEsc);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
            window.removeEventListener('load', debouncedHandleResize);
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    React.useEffect(() => {
        if (!checkFunction(904)) {
            window.token = '';
            window.ClientID = '';
            window.UserID = '';
            window.sessionStorage.clear();
            window.location.replace(window.location.origin);
        }
        setShowWorkspaceList(false);
        setShowServiceDayList(false);
        setShowRoutePlanList(false);
        setShowWeekList(false);
        setShowFrequencyList(false);
        setShowServicePatternList(false);
    }, []);

    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
        if (newValue === 0) {
            updateUserActivity({ ActionId: 901 });
        } else if (newValue === 1) {
            updateUserActivity({ ActionId: 902 });
        } else if (newValue === 2) {
            updateUserActivity({ ActionId: 903 });
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '1400px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}>
            <Tabs
                sx={{ borderBottom: 1, borderColor: 'divider' }}
                value={tabIndex}
                onChange={handleChange}
                variant='fullWidth'
            >
                <Tab
                    icon={tabIndex === 0 ? <span className={'FA_USER'} /> : <span className={'FA_USER_GR'} />}
                    style={{ whiteSpace: 'nowrap' }}
                    label='Users'
                    {...a11yProps(0)}
                />
                <Tab
                    icon={tabIndex === 1 ? <span className={'FA_USER_GEAR'} /> : <span className={'FA_USER_GEAR_GR'} />}
                    label='Roles'
                    {...a11yProps(1)}
                />
                <Tab
                    icon={tabIndex === 2 ? <span className={'FA_USERS'} /> : <span className={'FA_USERS_GR'} />}
                    label='Groups'
                    {...a11yProps(2)}
                />
            </Tabs>
            <Box
                role='tabpanel'
                id={`simple-tabpanel-${tabIndex}`}
                aria-labelledby={`simple-tab-${tabIndex}`}
            >
                <Box sx={{ p: 3 }}>
                    <Box>
                        {tabIndex === 0 ? <Users windowDimensions={windowDimensions} /> : null}
                        {tabIndex === 1 ? <Roles windowDimensions={windowDimensions} /> : null}
                        {tabIndex === 2 ? <Groups windowDimensions={windowDimensions} /> : null}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
