import React from 'react';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const ExportPanel = (props) => {
    const {
        siteMode,
        showExport,
        setShowExport,
        selectedFormat,
        handleSelectFormat,
        exportFormats,
        buttonLoading,
        handleDownloadExport,
        exportOptions,
        handleSetExportOptions,
        selectedServiceLocationIds,
    } = props;

    const shouldDisable = () => {
        if (siteMode === 'DISPATCHER') {
            if (buttonLoading) {
                return true;
            } else if (!selectedFormat.value) {
                return true;
            }
        } else if (siteMode === 'PLANNER') {
            if (buttonLoading) {
                return true;
            } else if (!selectedFormat.value) {
                return true;
            }
        }
        return false;
    };

    return (
        <div>
            {showExport && (
                <div id='exportDiv'>
                    <div style={{ float: 'left' }}>
                        <Typography
                            variant='body1'
                            gutterBottom
                        >
                            Export
                        </Typography>
                    </div>
                    <div
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() => {
                            setShowExport(!showExport);
                        }}
                    >
                        <span className='material-icons'>close</span>
                    </div>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isSearchable={true}
                                name='selectedExportFormat'
                                id='selectedExportFormat'
                                value={selectedFormat}
                                onChange={handleSelectFormat}
                                options={exportFormats}
                                placeholder='Select format...'
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    style={{ marginLeft: '5px', marginTop: '10px' }}
                                    control={
                                        <Checkbox
                                            checked={exportOptions.selectedRecords && selectedServiceLocationIds.length > 0}
                                            disabled={selectedServiceLocationIds.length === 0}
                                            onChange={(e) => handleSetExportOptions('selectedRecords', e.target.checked)}
                                        />
                                    }
                                    label='Selected Records'
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems='center'
                            justifyContent='center'
                            style={{ marginTop: '10px' }}
                        >
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant='contained'
                                    style={shouldDisable() ? { backgroundColor: '#eee' } : { backgroundColor: '#f77c2b' }}
                                    disabled={shouldDisable()}
                                    onClick={() => handleDownloadExport()}
                                >
                                    Download
                                </Button>
                                {buttonLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export const DispatchExportPanel = (props) => {
    const { showScenarioExport, dispatchExportOptions, handleSetDispatchExportOptions, selectedRaaSServiceLocationIds, exportFormats } = props;
    const dispatchExportFormat = exportFormats.filter((e) => e.value === 'csv' || e.value === 'gpx');

    return (
        <div>
            {showScenarioExport && (
                <div id='exportDiv'>
                    <div style={{ float: 'left' }}>
                        <Typography
                            variant='body1'
                            gutterBottom
                        >
                            Export
                        </Typography>
                    </div>
                    <div
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() => {
                            props.setShowScenarioExport(!props.showScenarioExport);
                        }}
                    >
                        <span className='material-icons'>close</span>
                    </div>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isSearchable={true}
                                name='selectedScenarioExportFormat'
                                id='selectedScenarioExportFormat'
                                value={props.selectedScenarioFormat}
                                onChange={(e) => {
                                    props.setSelectedScenarioFormat(e);
                                }}
                                options={dispatchExportFormat}
                                placeholder='Select format...'
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    style={{ marginLeft: '5px', marginTop: '10px' }}
                                    control={
                                        <Checkbox
                                            checked={dispatchExportOptions.selectedRecords && selectedRaaSServiceLocationIds.length > 0}
                                            disabled={selectedRaaSServiceLocationIds.length === 0}
                                            onChange={(e) => handleSetDispatchExportOptions('selectedRecords', e.target.checked)}
                                        />
                                    }
                                    label='Selected Records'
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems='center'
                            justifyContent='center'
                            style={{ marginTop: '10px' }}
                        >
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant='contained'
                                    style={
                                        props.selectedScenarioFormat?.value && props.selectedScenarioRoutes?.length > 0 && !props.buttonLoading
                                            ? { backgroundColor: '#f77c2b' }
                                            : { backgroundColor: '#eee' }
                                    }
                                    disabled={
                                        props.buttonLoading || !(props.selectedScenarioFormat?.value && props.selectedScenarioRoutes?.length > 0)
                                    }
                                    onClick={() => props.handleDownloadScenarioExport()}
                                >
                                    Download
                                </Button>
                                {props.buttonLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};
