import Basemap from '@arcgis/core/Basemap';
import WebTileLayer from '@arcgis/core/layers/WebTileLayer';
import { config } from '../config';

const baseTemplateBaseUrlV3 = `https://maps.hereapi.com/v3/base/mc/{level}/{col}/{row}/png8?apiKey=${config.HERE_API_KEY_V3}`;
const baseThumbnailUrlUrlV3 = `https://maps.hereapi.com/v3/base/mc/14/4769/6203/png8?apiKey=${config.HERE_API_KEY_V3}`;
const copyright = '&copy <a href="https://www.here.com/">HERE</a>';
const subDomains = ['1', '2', '3', '4'];

const mapBaseStreetLayer_V2 = new WebTileLayer({
    urlTemplate:
        'https://{subDomain}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day/{level}/{col}/{row}/256/png8?apiKey=' +
        config.HERE_API_KEY_V2,
    id: 'HEREStreet',
    subDomains,
    copyright,
});

export const HEREStreetBaseMap_V2 = new Basemap({
    baseLayers: [mapBaseStreetLayer_V2],
    title: 'HERE Street',
    id: 'HEREStreet',
    thumbnailUrl: 'https://1.base.maps.ls.hereapi.com/maptile/2.1/basetile/newest/normal.day/14/4769/6203/256/png8?apiKey=' + config.HERE_API_KEY_V2,
});

const HERESatelliteLayer_V2 = new WebTileLayer({
    urlTemplate:
        'https://{subDomain}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{level}/{col}/{row}/256/png8?apiKey=' +
        config.HERE_API_KEY_V2,
    id: 'HERESatellite',
    subDomains,
    copyright,
});

export const HERESatelliteBaseMap_V2 = new Basemap({
    baseLayers: [HERESatelliteLayer_V2],
    title: 'HERE Satellite',
    id: 'HERESatelliteLayer',
    thumbnailUrl:
        'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/basetile/newest/satellite.day/14/4769/6203/256/png8?apiKey=' + config.HERE_API_KEY_V2,
});

const HEREHybrideLayer_V2 = new WebTileLayer({
    urlTemplate:
        'https://{subDomain}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/hybrid.day/{level}/{col}/{row}/256/png8?apiKey=' +
        config.HERE_API_KEY_V2,
    id: 'HEREHybrid',
    subDomains,
    copyright,
});

export const HEREHybridBaseMap_V2 = new Basemap({
    baseLayers: [HEREHybrideLayer_V2],
    title: 'HERE Hybrid',
    id: 'HEREHybridLayer',
    thumbnailUrl:
        'https://1.aerial.maps.ls.hereapi.com/maptile/2.1/basetile/newest/hybrid.day/14/4769/6203/256/png8?apiKey=' + config.HERE_API_KEY_V2,
});

const mapBaseStreetLayer_V3 = new WebTileLayer({
    urlTemplate: baseTemplateBaseUrlV3,
    id: 'HEREStreet',
    copyright,
});

export const HEREStreetBaseMap_V3 = new Basemap({
    baseLayers: [mapBaseStreetLayer_V3],
    title: 'HERE Street',
    id: 'HEREStreet',
    thumbnailUrl: baseThumbnailUrlUrlV3,
});

const HERESatelliteLayer_V3 = new WebTileLayer({
    urlTemplate: `${baseTemplateBaseUrlV3}&style=satellite.day`,
    id: 'HERESatellite',
    copyright,
});

export const HERESatelliteBaseMap_V3 = new Basemap({
    baseLayers: [HERESatelliteLayer_V3],
    title: 'HERE Satellite',
    id: 'HERESatelliteLayer',
    thumbnailUrl: `${baseThumbnailUrlUrlV3}&style=satellite.day`,
});

const HEREHybrideLayer_V3 = new WebTileLayer({
    urlTemplate: `${baseTemplateBaseUrlV3}&style=explore.satellite.day`,
    id: 'HEREHybrid',
    copyright,
});

export const HEREHybridBaseMap_V3 = new Basemap({
    baseLayers: [HEREHybrideLayer_V3],
    title: 'HERE Hybrid',
    id: 'HEREHybridLayer',
    thumbnailUrl: `${baseThumbnailUrlUrlV3}&style=explore.satellite.day`,
});

//////////////////////////////
// WebTileLayer for traffic //
//////////////////////////////

export const HERETrafficLayer_V2 = new WebTileLayer({
    urlTemplate:
        'https://{subDomain}.traffic.maps.ls.hereapi.com/maptile/2.1/flowtile/newest/normal.day/{level}/{col}/{row}/256/png8?apiKey=' +
        config.HERE_API_KEY_V2,
    id: 'HERE Traffic Layer',
    subDomains,
    copyright,
    visible: false,
});

export const HERETrafficLayer_V3 = new WebTileLayer({
    urlTemplate: 'https://traffic.maps.hereapi.com/v3/flow/mc/{level}/{col}/{row}/png?style=explore.day&size=512&apiKey=' + config.HERE_API_KEY_V3,
    id: 'HERE Traffic Layer',
    copyright,
    visible: false,
});
