import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Box, IconButton, DialogActions, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import MaterialReactTable from 'material-react-table';

export const ManageServicePattern = (props) => {
    const {
        showServicePattern,
        workspace,
        serviceDay,
        siteMode,
        servicePatternData,
        handleServicePatternDialogClose,
        handleUpdateServicePatternDialogOpen,
        updateAvailability,
        handleRemoveServicePattern,
    } = props;

    const columns = [
        {
            header: 'Service Pattern',
            accessorKey: 'ServicePattern',
            size: 200,
            enableSorting: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            Cell: ({ cell, row }) => {
                let sp = cell.getValue();
                let pattern = { 1: 'M', 2: 'T', 3: 'W', 4: 'R', 5: 'F', 6: 'S', 7: 'U' };
                sp = sp.substring(1).replace(/[1-7]/gi, (s) => pattern[s]);
                return sp;
            },
        },
        {
            header: 'Available',
            accessorKey: 'Available',
            size: 50,
            enableSorting: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            Cell: ({ cell, row }) => {
                return (
                    <Checkbox
                        sx={{ padding: 0 }}
                        checked={cell.getValue()}
                        onChange={() => updateAvailability(row.original.Id, row.original)}
                    />
                );
            },
        },
        {
            header: '',
            accessorKey: 'actions',
            size: 50,
            enableSorting: false,
            muiTableHeadCellProps: { align: 'center', sx: { fontSize: '10px', color: 'white', backgroundColor: '#369cd9' } },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px' } },
            Cell: ({ cell, row }) => {
                let PD = servicePatternData.filter((s) => s.ServicePattern.substring(0, 1) === row.original.ServicePattern.substring(0, 1));
                if (PD.length > 1) {
                    return (
                        <Box>
                            <IconButton
                                onClick={() => handleRemoveServicePattern(row.original)}
                                sx={{ padding: 0 }}
                                title='Delete Service Pattern'
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            },
        },
    ];

    return (
        <Dialog
            id={'ManageServicePatternDialog'}
            open={showServicePattern}
            onClose={handleServicePatternDialogClose}
            aria-labelledby='draggable-dialog-title'
        >
            <DialogTitle style={{ padding: '5px 24px' }}>
                Manage Service Pattern
                <IconButton
                    aria-label='close'
                    onClick={handleServicePatternDialogClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent style={{ paddingTop: '5px' }}>
                <div
                    id='servicePatternDiv'
                    style={{ width: '450px', background: 'white' }}
                >
                    <MaterialReactTable
                        columns={columns}
                        data={servicePatternData}
                        enableTopToolbar={true}
                        enableStickyHeader={true}
                        enableStickyFooter={true}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableColumnOrdering={false}
                        enableColumnResizing={false}
                        enableRowSelection={false}
                        enableHiding={false}
                        enableGlobalFilter={false}
                        positionToolbarAlertBanner='none'
                        initialState={{
                            density: 'compact',
                            sorting: [{ id: 'ServicePattern', desc: false }],
                        }}
                        muiTopToolbarProps={{
                            sx: { minHeight: '20px' },
                        }}
                        muiTableContainerProps={{
                            sx: { maxHeight: '500px' },
                        }}
                        renderTopToolbarCustomActions={({ table }) => (
                            <Box>
                                <Button
                                    onClick={handleUpdateServicePatternDialogOpen}
                                    variant='outlined'
                                    title='Add Service Pattern'
                                    style={{ minWidth: '35px', padding: '0 10px' }}
                                >
                                    + Service Pattern
                                </Button>
                            </Box>
                        )}
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
};

export const UpdateServicePatternDialog = (props) => {
    const {
        updateServicePatternDialogOpen,
        handleUpdateServicePatternDialogClose,
        selectedServicePatternForAdd,
        setSelectedServicePatternForAdd,
        servicePatternData,
    } = props;

    const [rowSelection, setRowSelection] = React.useState({});

    const columns = [
        {
            header: 'Day',
            accessorKey: 'Day',
            size: 300,
            enableSorting: false,
            muiTableHeadCellProps: {
                align: 'center',
                sx: { borderRight: '1px solid #fff', fontSize: '10px', color: 'white', backgroundColor: '#369cd9' },
            },
            muiTableBodyCellProps: { align: 'center', sx: { fontSize: '10px', borderRight: '1px solid #ccc' } },
            muiTableFooterCellProps: { align: 'center', sx: { fontSize: '10px' } },
        },
    ];

    const data = [
        { Id: 1, Day: 'Monday' },
        { Id: 2, Day: 'Tuesday' },
        { Id: 3, Day: 'Wednesday' },
        { Id: 4, Day: 'Thursday' },
        { Id: 5, Day: 'Friday' },
        { Id: 6, Day: 'Saturday' },
        { Id: 7, Day: 'Sunday' },
    ];

    React.useEffect(() => {
        let selected = [];
        Object.keys(rowSelection).forEach((key, index) => {
            selected.push(Number(key));
        });
        setSelectedServicePatternForAdd(selected);
    }, [rowSelection]);

    React.useEffect(() => {
        if (updateServicePatternDialogOpen) {
            setRowSelection({});
        }
    }, [updateServicePatternDialogOpen]);

    const validForm = () => {
        let pattern = selectedServicePatternForAdd.toString().replace(/,/g, '');
        let newPattern = selectedServicePatternForAdd.length + pattern;
        let existPattern = servicePatternData.filter((s) => s.ServicePattern == newPattern);
        let hasDuplicate = existPattern.length > 0;
        return selectedServicePatternForAdd.length === 0 || hasDuplicate;
    };

    return (
        <Dialog
            open={updateServicePatternDialogOpen}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>Add Service Pattern</DialogTitle>
            <DialogContent>
                <div
                    id='servicePatternDiv'
                    style={{ background: 'white' }}
                >
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableTopToolbar={false}
                        enableStickyHeader={true}
                        enableStickyFooter={true}
                        enableColumnFilters={false}
                        enablePagination={false}
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableFullScreenToggle={false}
                        enableDensityToggle={false}
                        enableColumnOrdering={false}
                        enableColumnResizing={false}
                        enableRowSelection={true}
                        enableHiding={false}
                        positionToolbarAlertBanner='none'
                        initialState={{
                            density: 'compact',
                        }}
                        muiTableContainerProps={{
                            sx: { maxHeight: '300px' },
                        }}
                        state={{ rowSelection }}
                        onRowSelectionChange={setRowSelection}
                        getRowId={(originalRow) => originalRow.Id}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleUpdateServicePatternDialogClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={validForm() ? { backgroundColor: '#eee' } : { backgroundColor: '#16a8a6', color: '#fff' }}
                    disabled={validForm()}
                    onClick={() => handleUpdateServicePatternDialogClose('SAVE')}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
