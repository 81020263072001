import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

// assuming duration is never over 30 minutes
export const getPreOrPostTripDuration = (durationInSeconds) => {
    if (durationInSeconds === 0) return '00:00';
    const durationInMins = durationInSeconds / 60;
    return durationInMins >= 10 ? `00:${durationInMins}` : `00:0${durationInMins}`;
};

export const getTimeZoneValue = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatCellValueToLocal = (cellValue, formatString) => {
    return format(utcToZonedTime(new Date(`${cellValue} UTC`), getTimeZoneValue()), formatString);
};

export const formatFileTimeStamp = () => {
    return format(utcToZonedTime(new Date(), getTimeZoneValue()), 'yyyy-MM-dd-HH-mm-ss-SSS');
};

export const UTCToLocal = (cellValue, formatString) => {
    return format(utcToZonedTime(new Date(cellValue + ' UTC'), getTimeZoneValue()), formatString);
};
