import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Grid,
    TextField,
    MenuItem,
    FormControl,
    Select,
    Typography,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import IconButton from '@mui/material/IconButton';
import { CurrencyInput, DurationInput, FloatInput } from './ManageController';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReactSelect from 'react-select';
import { useTranslation } from 'react-i18next';

export const EditDialogue = (props) => {
    const {
        editDriverDialog,
        editTruckDialog,
        mode,
        title,
        updateForm,
        target,
        defaultName,
        payMethod,
        handlePayModeChange,
        handleEditClose,
        editingName,
        validForm,
        driverSpecialties,
        truckSpecialties,
        addDriverSpecialty,
        addTruckSpecialty,
        deleteDriverSpecialty,
        deleteTruckSpecialty,
        setManageSpecialtyDialogOpen,
        specialtyList,
        updateDriverSpecialties,
        updateTruckSpecialties,
    } = props;

    const { t } = useTranslation();

    return (
        <Dialog
            open={title === 'Driver' ? editDriverDialog : editTruckDialog}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitle>
                {title === 'Driver' ? t(`RoutePlans.Driver.Actions.${mode}`) : t(`RoutePlans.Truck.Actions.${mode}`)}{' '}
                {title === 'Driver' ? t('RoutePlans.Driver.Title') : t('RoutePlans.Truck.Title')}
            </DialogTitle>
            <DialogContent>
                {title === 'Driver' ? (
                    <DriverOptions
                        updateForm={updateForm}
                        payMethod={payMethod}
                        handlePayModeChange={handlePayModeChange}
                        mode={mode}
                        target={target}
                        defaultName={defaultName}
                        editingName={editingName}
                        driverSpecialties={driverSpecialties}
                        addDriverSpecialty={addDriverSpecialty}
                        deleteDriverSpecialty={deleteDriverSpecialty}
                        setManageSpecialtyDialogOpen={setManageSpecialtyDialogOpen}
                        specialtyList={specialtyList}
                        updateDriverSpecialties={updateDriverSpecialties}
                    />
                ) : (
                    <TruckOptions
                        target={target}
                        updateForm={updateForm}
                        defaultName={defaultName}
                        editingName={editingName}
                        mode={mode}
                        addTruckSpecialty={addTruckSpecialty}
                        truckSpecialties={truckSpecialties}
                        deleteTruckSpecialty={deleteTruckSpecialty}
                        setManageSpecialtyDialogOpen={setManageSpecialtyDialogOpen}
                        specialtyList={specialtyList}
                        updateTruckSpecialties={updateTruckSpecialties}
                    />
                )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleEditClose('CANCEL')}
                >
                    Cancel
                </Button>
                {validForm() && (
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => handleEditClose('SAVE')}
                    >
                        Save
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const DriverOptions = (props) => {
    const {
        updateForm,
        payMethod,
        handlePayModeChange,
        mode,
        target,
        defaultName,
        editingName,
        driverSpecialties,
        addDriverSpecialty,
        deleteDriverSpecialty,
        setManageSpecialtyDialogOpen,
        specialtyList,
        updateDriverSpecialties,
    } = props;

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(1);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={(e, v) => setActiveTab(v)}
                    value={activeTab}
                >
                    <Tab
                        label={t('RoutePlans.Driver.Form.General')}
                        value={1}
                    />
                    <Tab
                        label={t('RoutePlans.Driver.Form.Specialties')}
                        value={2}
                        disabled={target.DriverName === defaultName && mode !== 'New'}
                    />
                </Tabs>
            </Box>
            {activeTab === 1 && (
                <Box style={{ padding: '20px' }}>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            {mode === 'New' || mode === 'Copy' ? (
                                <TextField
                                    autoFocus
                                    margin='dense'
                                    id='DriverName'
                                    name='DriverName'
                                    label={t('RoutePlans.Driver.Form.Driver')}
                                    type='text'
                                    fullWidth
                                    variant='standard'
                                    onChange={updateForm}
                                />
                            ) : (
                                <TextField
                                    disabled={mode === 'Edit' && editingName === defaultName}
                                    autoFocus
                                    margin='dense'
                                    id='DriverName'
                                    name='DriverName'
                                    label={t('RoutePlans.Driver.Form.Driver')}
                                    type='text'
                                    fullWidth
                                    variant='standard'
                                    value={target.DriverName}
                                    onChange={updateForm}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <br />
                    <Grid
                        container
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={3}
                        >
                            <label style={{ display: 'block', marginLeft: '50px', marginRight: '10px' }}>{t('RoutePlans.Driver.Form.Pay')}:</label>
                        </Grid>
                        {payMethod === '1' && (
                            <Grid
                                item
                                xs={2}
                            >
                                <CurrencyInput
                                    placeholder='$0.00'
                                    type='text'
                                    id='CostPerHour'
                                    name='CostPerHour'
                                    value={target.CostPerHour}
                                    onChange={updateForm}
                                />
                            </Grid>
                        )}
                        {payMethod === '2' && (
                            <Grid
                                item
                                xs={2}
                            >
                                <CurrencyInput
                                    placeholder='$0.00'
                                    type='text'
                                    id='CostPerStop'
                                    name='CostPerStop'
                                    value={target.CostPerStop}
                                    onChange={updateForm}
                                />
                            </Grid>
                        )}
                        {payMethod === '3' && (
                            <Grid
                                item
                                xs={2}
                            >
                                <CurrencyInput
                                    placeholder='$0.00'
                                    type='text'
                                    id='CostPerPiece'
                                    name='CostPerPiece'
                                    value={target.CostPerPiece}
                                    onChange={updateForm}
                                />
                            </Grid>
                        )}
                        {payMethod === '4' && (
                            <Grid
                                item
                                xs={2}
                            >
                                <CurrencyInput
                                    placeholder='$0.00'
                                    type='text'
                                    id='CostPerDist'
                                    name='CostPerDist'
                                    value={target.CostPerDist}
                                    onChange={updateForm}
                                />
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={1}
                        >
                            <label style={{ display: 'block' }}>{t('RoutePlans.Driver.Form.Per')}</label>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                        >
                            <FormControl sx={{ m: 1, minWidth: 100 }}>
                                <Select
                                    value={payMethod}
                                    onChange={(e) => handlePayModeChange(e)}
                                    variant={'standard'}
                                    inputProps={{
                                        name: 'payBy',
                                        id: 'payBy',
                                    }}
                                >
                                    <MenuItem
                                        value={'1'}
                                        style={{ display: 'block', padding: '2px 5px' }}
                                    >
                                        {t('RoutePlans.Driver.Form.Hour')}
                                    </MenuItem>
                                    <MenuItem
                                        value={'2'}
                                        style={{ display: 'block', padding: '2px 5px' }}
                                    >
                                        {t('RoutePlans.Driver.Form.Stop')}
                                    </MenuItem>
                                    <MenuItem
                                        value={'3'}
                                        style={{ display: 'block', padding: '2px 5px' }}
                                    >
                                        {t('RoutePlans.Driver.Form.Demand')}
                                    </MenuItem>
                                    <MenuItem
                                        value={'4'}
                                        style={{ display: 'block', padding: '2px 5px' }}
                                    >
                                        {t('RoutePlans.Driver.Form.Distance')}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {payMethod === '1' && (
                        <Grid
                            container
                            direction='row'
                            justifyContent='flex-start'
                            alignItems='center'
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={3}
                            >
                                <label style={{ display: 'block', marginLeft: '10px', marginRight: '10px' }}>
                                    {t('RoutePlans.Driver.Form.Overtime')}:
                                </label>
                            </Grid>
                            <Grid
                                item
                                xs={2}
                            >
                                <CurrencyInput
                                    placeholder='$0.00'
                                    type='text'
                                    id='CostPerOtHour'
                                    name='CostPerOtHour'
                                    value={target.CostPerOtHour}
                                    onChange={updateForm}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {payMethod === '1' && (
                        <Grid
                            container
                            direction='row'
                            justifyContent='flex-start'
                            alignItems='center'
                            spacing={1}
                            style={{ marginTop: 0 }}
                        >
                            <Grid
                                item
                                xs={3}
                            >
                                <label style={{ display: 'block', marginLeft: '40px', marginRight: '10px' }}>
                                    {t('RoutePlans.Driver.Form.After')}:
                                </label>
                            </Grid>
                            <Grid
                                item
                                xs={2}
                            >
                                <DurationInput
                                    placeholder='00:00'
                                    type='text'
                                    id='CostOtStart'
                                    name='CostOtStart'
                                    value={target.CostOtStart}
                                    onChange={updateForm}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={1}
                            >
                                <label style={{ display: 'block' }}>{t('RoutePlans.Driver.Form.Hours')}</label>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            )}
            {activeTab === 2 && (
                <Box style={{ padding: '20px' }}>
                    <Grid container>
                        <Grid
                            container
                            item
                            xs={12}
                            style={{ marginBottom: '10px', textAlign: 'center' }}
                        >
                            <Grid
                                item
                                xs={11}
                                style={{ textAlign: 'left', paddingLeft: '15px' }}
                            >
                                <Typography
                                    variant='body1'
                                    style={{ marginTop: '5px', color: 'rgba(0, 0, 0, 0.54)', fontSize: '14px', fontWeight: 700 }}
                                >
                                    {t('RoutePlans.Driver.Form.Specialty')}
                                    <IconButton
                                        title={t('RoutePlans.Driver.Form.ManageSpecialty')}
                                        onClick={() => setManageSpecialtyDialogOpen(true)}
                                    >
                                        <ManageAccountsIcon />
                                    </IconButton>
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                style={{ textAlign: 'center' }}
                            >
                                <IconButton
                                    title={t('RoutePlans.Driver.Actions.New')}
                                    color='primary'
                                    className={'icon_button'}
                                    style={{ padding: 0, backgroundColor: '#fff', color: '#16a8a6' }}
                                    onClick={() => addDriverSpecialty()}
                                >
                                    <i className='RS_ADD' />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                        >
                            {driverSpecialties.map((ds) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={11}
                                            key={ds.Id}
                                        >
                                            <FormControl
                                                sx={{ m: 1, minWidth: 250 }}
                                                size='small'
                                            >
                                                <ReactSelect
                                                    isSearchable={true}
                                                    fullWidth
                                                    value={driverSpecialties.find((s) => s.Id === ds.Id)}
                                                    onChange={(e) => updateDriverSpecialties(e, ds.Id)}
                                                    options={specialtyList}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        height: '30px',
                                                        width: '250px',
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ textAlign: 'center', alignContent: 'center' }}
                                        >
                                            <IconButton
                                                title={t('RoutePlans.Driver.Actions.Delete')}
                                                color='primary'
                                                className={'icon_button'}
                                                style={{ padding: 0, marginTop: '0', backgroundColor: '#fff', color: '#16a8a6', textAlign: 'center' }}
                                                onClick={() => deleteDriverSpecialty(ds.Id)}
                                            >
                                                <i className='RS_DELETE' />
                                            </IconButton>
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

const TruckOptions = (props) => {
    const {
        target,
        updateForm,
        mode,
        defaultName,
        editingName,
        truckSpecialties,
        addTruckSpecialty,
        deleteTruckSpecialty,
        setManageSpecialtyDialogOpen,
        specialtyList,
        updateTruckSpecialties,
    } = props;
    const isDefaultTruck = editingName === defaultName;

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(1);

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    onChange={(e, v) => setActiveTab(v)}
                    value={activeTab}
                >
                    <Tab
                        label={t('RoutePlans.Truck.Form.General')}
                        value={1}
                    />
                    <Tab
                        label={t('RoutePlans.Truck.Form.Specialties')}
                        value={2}
                        disabled={target.TruckName === defaultName && mode !== 'New'}
                    />
                </Tabs>
            </Box>
            {activeTab === 1 && (
                <Box style={{ padding: '20px' }}>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            {mode === 'New' || mode === 'Copy' ? (
                                <TextField
                                    autoFocus
                                    margin='dense'
                                    id='TruckName'
                                    name='TruckName'
                                    label={t('RoutePlans.Truck.Form.Truck')}
                                    type='text'
                                    fullWidth
                                    variant='standard'
                                    onChange={updateForm}
                                />
                            ) : (
                                <TextField
                                    disabled={mode === 'Edit' && isDefaultTruck}
                                    autoFocus
                                    margin='dense'
                                    id='TruckName'
                                    name='TruckName'
                                    label={t('RoutePlans.Truck.Form.Truck')}
                                    type='text'
                                    fullWidth
                                    variant='standard'
                                    value={target.TruckName}
                                    onChange={updateForm}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                gutterBottom
                                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                            >
                                {t('RoutePlans.Truck.Form.Capacity')}
                            </Typography>
                            <FloatInput
                                placeholder='0.00'
                                type='text'
                                id='VehicleCapacity'
                                name='VehicleCapacity'
                                value={target.VehicleCapacity}
                                onChange={updateForm}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                gutterBottom
                                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                            >
                                {t('RoutePlans.Truck.Form.CostToOperateDaily')}
                            </Typography>
                            <CurrencyInput
                                placeholder='$0.00'
                                type='text'
                                id='CostFixed'
                                name='CostFixed'
                                value={target.CostFixed}
                                onChange={updateForm}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Typography
                                gutterBottom
                                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                            >
                                {t('RoutePlans.Truck.Form.CostToOperateDistance')}
                            </Typography>
                            <CurrencyInput
                                placeholder='$0.00'
                                type='text'
                                id='CostVariable'
                                name='CostVariable'
                                value={target.CostVariable}
                                onChange={updateForm}
                            />
                        </Grid>
                    </Grid>
                    {isDefaultTruck && (
                        <Grid
                            container
                            spacing={1}
                        >
                            <Grid
                                item
                                xs={12}
                            >
                                <Typography
                                    gutterBottom
                                    style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px' }}
                                >
                                    {t('RoutePlans.Truck.Form.CostToOperateHourly')}
                                </Typography>
                                <CurrencyInput
                                    placeholder='$0.00'
                                    type='text'
                                    id='CostPerHour'
                                    name='CostPerHour'
                                    value={target.CostPerHour}
                                    onChange={updateForm}
                                    maskOptions={{ integerLimit: 3, allowDecimal: true, decimalSymbol: '.', decimalLimit: 2 }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name='ServiceSide'
                                    onChange={updateForm}
                                    checked={target.ServiceSide === 'Same' ? true : false}
                                    disabled={isDefaultTruck && mode !== 'New'}
                                />
                            }
                            label={t('RoutePlans.Truck.Form.SameSide')}
                        />
                    </FormGroup>
                </Box>
            )}
            {activeTab === 2 && (
                <Box style={{ padding: '20px' }}>
                    <Grid container>
                        <Grid
                            container
                            item
                            xs={12}
                            style={{ marginBottom: '10px', textAlign: 'center' }}
                        >
                            <Grid
                                item
                                xs={11}
                                style={{ textAlign: 'left', paddingLeft: '15px' }}
                            >
                                <Typography
                                    variant='body1'
                                    style={{ marginTop: '5px', color: 'rgba(0, 0, 0, 0.54)', fontSize: '14px', fontWeight: 700 }}
                                >
                                    {t('RoutePlans.Truck.Form.Specialty')}
                                    <IconButton
                                        title={t('RoutePlans.Truck.Form.ManageSpecialty')}
                                        onClick={() => setManageSpecialtyDialogOpen(true)}
                                    >
                                        <ManageAccountsIcon />
                                    </IconButton>
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={1}
                                style={{ textAlign: 'center' }}
                            >
                                <IconButton
                                    title={t('RoutePlans.Truck.Actions.New')}
                                    color='primary'
                                    className={'icon_button'}
                                    style={{ padding: 0, backgroundColor: '#fff', color: '#16a8a6' }}
                                    onClick={() => addTruckSpecialty()}
                                >
                                    <i className='RS_ADD' />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                        >
                            {truckSpecialties.map((ds) => {
                                return (
                                    <>
                                        <Grid
                                            item
                                            xs={11}
                                            key={ds.Id}
                                        >
                                            <FormControl
                                                sx={{ m: 1, minWidth: 250 }}
                                                size='small'
                                            >
                                                <ReactSelect
                                                    isSearchable={true}
                                                    fullWidth
                                                    value={truckSpecialties.find((s) => s.Id === ds.Id)}
                                                    onChange={(e) => updateTruckSpecialties(e, ds.Id)}
                                                    options={specialtyList}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                        height: '30px',
                                                        width: '250px',
                                                    }}
                                                />
                                            </FormControl>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={1}
                                            style={{ textAlign: 'center', alignContent: 'center' }}
                                        >
                                            <IconButton
                                                title={t('RoutePlans.Truck.Actions.Delete')}
                                                color='primary'
                                                className={'icon_button'}
                                                style={{ padding: 0, marginTop: '0', backgroundColor: '#fff', color: '#16a8a6', textAlign: 'center' }}
                                                onClick={() => deleteTruckSpecialty(ds.Id)}
                                            >
                                                <i className='RS_DELETE' />
                                            </IconButton>
                                        </Grid>
                                    </>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};
