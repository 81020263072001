import React from 'react';
import Select from 'react-select';
import Grid from '@mui/material/Grid';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export const ReportPanel = (props) => {
    const {
        siteMode,
        showReport,
        selectedReport,
        reportTemplates,
        separateReports,
        buttonLoading,
        setShowReport,
        handleSelectReport,
        handleSeparateReports,
        handleDownloadReport,
    } = props;

    const shouldDisable = () => {
        if (siteMode === 'DISPATCHER') {
            if (buttonLoading) {
                return true;
            } else if (!selectedReport.value) {
                return true;
            }
            // } else if (selectedRoutes && selectedRoutes.length === 0) {
            //     return true;
            // }
        } else if (siteMode === 'PLANNER') {
            if (buttonLoading) {
                return true;
            } else if (!selectedReport.value) {
                return true;
            }
            // } else if (selectedProjectRoutes && selectedProjectRoutes.length === 0) {
            //     return true;
            // }
        }
        return false;
    };

    return (
        <div>
            {showReport && (
                <div id='reportDiv'>
                    <div style={{ float: 'left' }}>
                        <Typography
                            variant='body1'
                            gutterBottom
                        >
                            Report
                        </Typography>
                    </div>
                    <div
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() => {
                            setShowReport(!showReport);
                        }}
                    >
                        <span className='material-icons'>close</span>
                    </div>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isSearchable={true}
                                name='selectedReport'
                                id='selectedReport'
                                value={selectedReport}
                                onChange={handleSelectReport}
                                options={reportTemplates}
                                placeholder='Select report...'
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    style={{ marginLeft: '5px', marginTop: '10px' }}
                                    control={
                                        <Checkbox
                                            checked={separateReports}
                                            onChange={handleSeparateReports}
                                        />
                                    }
                                    label='Separate Reports'
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems='center'
                            justifyContent='center'
                            style={{ marginTop: '10px' }}
                        >
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant='contained'
                                    style={shouldDisable() ? { backgroundColor: '#eee' } : { backgroundColor: '#f77c2b' }}
                                    disabled={shouldDisable()}
                                    onClick={() => handleDownloadReport()}
                                >
                                    Download
                                </Button>
                                {buttonLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export const DispatchReportPanel = (props) => {
    const {
        showScenarioReport,
        selectedScenarioReport,
        reportTemplates,
        separateScenarioReports,
        selectedScenarioRoutes,
        buttonLoading,
        setShowScenarioReport,
        setSelectedScenarioReport,
        setSeparateScenarioReports,
        handleDownloadScenarioReport,
    } = props;

    const shouldDisable = () => {
        if (buttonLoading) {
            return true;
        } else if (!selectedScenarioReport.value) {
            return true;
        } else if (selectedScenarioRoutes && selectedScenarioRoutes.length === 0) {
            return true;
        }
        return false;
    };

    return (
        <div>
            {showScenarioReport && (
                <div id='reportDiv'>
                    <div style={{ float: 'left' }}>
                        <Typography
                            variant='body1'
                            gutterBottom
                        >
                            Report
                        </Typography>
                    </div>
                    <div
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={() => {
                            setShowScenarioReport(!showScenarioReport);
                        }}
                    >
                        <span className='material-icons'>close</span>
                    </div>
                    <Grid
                        container
                        spacing={0}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <Select
                                className='basic-single'
                                classNamePrefix='select'
                                isSearchable={true}
                                name='selectedScenarioReport'
                                id='selectedScenarioReport'
                                value={selectedScenarioReport}
                                onChange={(e) => {
                                    setSelectedScenarioReport(e);
                                }}
                                options={reportTemplates}
                                placeholder='Select report...'
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    style={{ marginLeft: '5px', marginTop: '10px' }}
                                    control={
                                        <Checkbox
                                            checked={separateScenarioReports}
                                            onChange={(e) => {
                                                setSeparateScenarioReports(e.target.checked);
                                            }}
                                        />
                                    }
                                    label='Separate Reports'
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems='center'
                            justifyContent='center'
                            style={{ marginTop: '10px' }}
                        >
                            <Box sx={{ m: 1, position: 'relative' }}>
                                <Button
                                    variant='contained'
                                    style={shouldDisable() ? { backgroundColor: '#eee' } : { backgroundColor: '#f77c2b' }}
                                    disabled={shouldDisable()}
                                    onClick={() => handleDownloadScenarioReport()}
                                >
                                    Download
                                </Button>
                                {buttonLoading && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    );
};
