import React from 'react';
import { config } from '../../_helpers/config';
import { checkFunction } from '../../_actions/Global.actions';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import RuleIcon from '@mui/icons-material/Rule';
import MaterialReactTable from 'material-react-table';

export const StreetMode = (props) => {
    const { selStreetMaintenanceMode, setSelStreetMaintenanceMode } = props;
    return (
        <div id='streetModeDiv'>
            <ToggleButtonGroup
                orientation='vertical'
                exclusive
                value={selStreetMaintenanceMode}
                onChange={(e, v) => setSelStreetMaintenanceMode(v)}
            >
                {checkFunction(218) && (
                    <ToggleButton
                        value={config.ALLEY_ASSIGNMENTS}
                        title='Click on a street to toggle alley assignments'
                    >
                        Alley Assignments
                    </ToggleButton>
                )}
                {checkFunction(202) && (
                    <ToggleButton
                        value={config.CLOSED_STREETS}
                        title='Click on a street to switch its closed off status'
                    >
                        Closed Streets
                    </ToggleButton>
                )}
                {checkFunction(210) && (
                    <ToggleButton
                        value={config.SERVICE_SIDES}
                        title='Click on a street to edit its service side'
                    >
                        Service Sides
                    </ToggleButton>
                )}
                {checkFunction(205) && (
                    <ToggleButton
                        value={config.HERETURNEXCEPTION}
                        title='Click on a street to toggle turn restriction'
                    >
                        Turn Restrictions
                    </ToggleButton>
                )}
            </ToggleButtonGroup>
        </div>
    );
};

export const DeleteTurnExceptionDialog = (props) => {
    return (
        <Dialog
            open={props.openUserTurnExceptionDeleteDialog}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>Delete User Turn Exception</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>Are you sure you want to delete selected User Turn Exception ?</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => props.handleUserTurnExceptionDeleteClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => props.handleUserTurnExceptionDeleteClose('DELETE')}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const AlleyAssignmentTable = (props) => {
    const { alleyAssignmentsData, handleValidAlleyAssignments, handleZoomToAlleyAssignment, handleDeleteAlleyAssignment } = props;
    return (
        <div id='alleyAssignmentTable'>
            <MaterialReactTable
                columns={[
                    {
                        header: '',
                        accessorKey: 'actions',
                        size: 50,
                        muiTableHeadCellProps: { align: 'center', sx: { borderRight: '1px solid #fff', fontSize: '10px' } },
                        muiTableBodyCellProps: { align: 'center', sx: { borderRight: '1px solid #ccc', fontSize: '10px' } },
                        Cell: ({ cell, row }) => {
                            return (
                                <Box>
                                    <IconButton
                                        onClick={() => handleZoomToAlleyAssignment(row.original.Id)}
                                        sx={{ padding: 0 }}
                                        title={'Show on map'}
                                    >
                                        <i
                                            className='RS_ZOOM'
                                            style={{ fontSize: '16px' }}
                                        />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => handleDeleteAlleyAssignment(row.original.Id)}
                                        sx={{ padding: 0 }}
                                        title={'Delete'}
                                    >
                                        <i
                                            className='RS_DELETE'
                                            style={{ fontSize: '16px' }}
                                        />
                                    </IconButton>
                                </Box>
                            );
                        },
                    },
                    {
                        header: 'Invalid',
                        accessorKey: 'Invalid',
                        size: 20,
                        muiTableHeadCellProps: { align: 'center', sx: { borderRight: '1px solid #fff', fontSize: '10px' } },
                        muiTableBodyCellProps: { align: 'center', sx: { borderRight: '1px solid #ccc', fontSize: '10px' } },
                        Cell: ({ cell, row }) => {
                            return row.original.Invalid ? <span style={{ color: 'red' }}>Yes</span> : <span>No</span>;
                        },
                    },
                    {
                        header: 'Street',
                        accessorKey: 'NameStreet',
                        size: 100,
                        muiTableHeadCellProps: { align: 'center', sx: { borderRight: '1px solid #fff', fontSize: '10px' } },
                        muiTableBodyCellProps: { align: 'center', sx: { borderRight: '1px solid #ccc', fontSize: '10px' } },
                        Cell: ({ cell, row }) => {
                            if (row.original.NameStreet) {
                                return <span style={row.original.Invalid ? { color: 'red' } : {}}>{row.original.NameStreet}</span>;
                            } else {
                                return <span style={row.original.Invalid ? { color: 'red' } : {}}>Street</span>;
                            }
                        },
                    },
                    {
                        header: 'Service From',
                        accessorKey: 'NameAlley',
                        size: 100,
                        muiTableHeadCellProps: { align: 'center', sx: { borderRight: '1px solid #fff', fontSize: '10px' } },
                        muiTableBodyCellProps: { align: 'center', sx: { borderRight: '1px solid #ccc', fontSize: '10px' } },
                        Cell: ({ cell, row }) => {
                            if (row.original.NameAlley) {
                                return <span style={row.original.Invalid ? { color: 'red' } : {}}>{row.original.NameAlley}</span>;
                            } else {
                                return <span style={row.original.Invalid ? { color: 'red' } : {}}>Alley</span>;
                            }
                        },
                    },
                ]}
                data={alleyAssignmentsData}
                enableTopToolbar={true}
                enableStickyHeader={true}
                enableSorting={true}
                enableColumnFilters={false}
                enablePagination={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnOrdering={false}
                enableColumnResizing={false}
                enableRowSelection={false}
                enableHiding={false}
                enableFilterMatchHighlighting={false}
                positionToolbarAlertBanner='none'
                initialState={{ density: 'compact', showGlobalFilter: true, sorting: [{ id: 'Invalid', desc: true }] }}
                muiTableContainerProps={{
                    sx: { maxHeight: '250px', minHeight: '250px', overflowX: 'hidden' },
                }}
                muiTopToolbarProps={{
                    sx: {
                        height: '40px',
                        minHeight: '40px',
                    },
                }}
                muiTableHeadCellProps={{
                    sx: {
                        flex: '0 0 auto',
                        fontWeight: 'normal',
                        fontSize: '12px',
                        padding: 0,
                        backgroundColor: 'transparent',
                    },
                }}
                muiTableBodyCellProps={{
                    sx: {
                        flex: '0 0 auto',
                        fontWeight: 'normal',
                        fontSize: '12px',
                        padding: 0,
                    },
                }}
                muiSearchTextFieldProps={{
                    InputProps: {
                        sx: { fontSize: '12px', height: '30px' },
                    },
                    variant: 'outlined',
                }}
                muiTableProps={{
                    sx: { tableLayout: 'fixed' },
                }}
                renderTopToolbarCustomActions={({ table }) => {
                    return (
                        <div id={'AlleyAssignmentToolbar'}>
                            <span style={{ fontSize: '18px', fontWeight: 500, lineHeight: 1.5, marginLeft: '20px' }}>Alley Assignments</span>
                            <Button
                                variant='outlined'
                                title='Check invalid assignments'
                                style={{ position: 'absolute', top: '10px', left: '200px', padding: 0, margin: 0 }}
                                onClick={() => handleValidAlleyAssignments()}
                            >
                                <RuleIcon />
                            </Button>
                        </div>
                    );
                }}
            />
        </div>
    );
};

export const DeleteAlleyAssignmentsDialog = (props) => {
    const { openAlleyAssignmentsDeleteDialog, handleAlleyAssignmentsDeleteClose } = props;
    return (
        <Dialog
            open={openAlleyAssignmentsDeleteDialog}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>Delete Alley Assignment</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>Are you sure you want to delete selected alley assignment ?</DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => handleAlleyAssignmentsDeleteClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => handleAlleyAssignmentsDeleteClose('DELETE')}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};
