import { HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import { config } from '../_helpers/config';

export const signalRService = {
    connection: null,

    startConnection: (workspace, project) => {
        signalRService.connection = new HubConnectionBuilder()
            .withUrl(`${config.ROApi}notificationHub`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .build();

        signalRService.connection
            .start()
            .then(() => {
                signalRService.connection.invoke('SubscribeSandbox', workspace, project);
                console.log('SignalR connection established.');
            })
            .catch((err) => {
                console.error('Error starting SignalR connection:', err);
            });
    },

    onReceiveMessage: (callback) => {
        signalRService.connection.on('SubscribeSandbox', (message) => {
            callback(message);
        });
    },

    disconnect: () => {
        signalRService.connection.stop().catch((err) => alert('disconnect' + err));
    },
};
