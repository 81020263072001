import React from 'react';
import { IconButton, Grid, Typography, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, DialogContentText } from '@mui/material';
import MaterialReactTable from 'material-react-table';

export const NeighborhoodClusterTable = (props) => {
    const {
        routePlanId,
        zoneMode,
        setZoneMode,
        handleSaveAssignment,
        handleSaveSequence,
        bottomHeight,
        sequenceMode,
        setSequenceMode,
        selectedNeighborhoodClusters,
        handleNewNeighborhood,
        handleDeleteSelectedNeighborhood,
    } = props;

    const [rowSelection, setRowSelection] = React.useState({});

    const lock = React.useRef(false);

    let columns = [];

    if (routePlanId) {
        columns = [
            {
                header: 'Name',
                accessorKey: 'Name',
                size: 200,
                muiTableHeadCellProps: { align: 'center', sx: { borderLeft: '1px solid #ccc' } },
                muiTableBodyCellProps: { sx: { borderLeft: '1px solid #ccc' } },
                sortingFn: 'alphanumeric',
            },
            {
                header: 'Label',
                accessorKey: 'Label',
                size: 200,
                muiTableHeadCellProps: { align: 'center', sx: { borderLeft: '1px solid #ccc' } },
                muiTableBodyCellProps: { sx: { borderLeft: '1px solid #ccc' } },
                sortingFn: 'alphanumeric',
            },

            {
                header: 'Route',
                accessorKey: 'Route',
                size: 200,
                muiTableHeadCellProps: { align: 'center', sx: { borderLeft: '1px solid #ccc' } },
                muiTableBodyCellProps: { sx: { borderLeft: '1px solid #ccc' } },
                sortingFn: 'alphanumeric',
            },
            {
                header: 'Priority',
                accessorKey: 'Priority',
                size: 150,
                muiTableHeadCellProps: { align: 'center', sx: { borderLeft: '1px solid #ccc' } },
                muiTableBodyCellProps: { sx: { padding: '0 16px', borderLeft: '1px solid #ccc' } },
                sortingFn: 'alphanumeric',
                Cell: ({ cell, row }) => {
                    if (cell.getValue() === 'F') {
                        return 'First';
                    } else if (cell.getValue() === 'L') {
                        return 'Last';
                    } else if (cell.getValue() === 'S') {
                        return 'Sequential';
                    } else {
                        return 'Unspecified';
                    }
                },
            },
            {
                header: 'Sequence',
                accessorKey: 'Sequence',
                size: 150,
                muiTableHeadCellProps: { align: 'center', sx: { borderLeft: '1px solid #ccc' } },
                muiTableBodyCellProps: { sx: { padding: '0 16px', borderLeft: '1px solid #ccc' } },
                sortingFn: 'alphanumeric',
                Cell: ({ cell, row }) => {
                    return cell.getValue() === 0 ? '' : cell.getValue();
                },
            },
        ];
    } else {
        columns = [
            {
                header: 'Name',
                accessorKey: 'Name',
                size: 400,
                muiTableHeadCellProps: { align: 'center', sx: { borderLeft: '1px solid #ccc' } },
                muiTableBodyCellProps: { sx: { borderLeft: '1px solid #ccc' } },
                sortingFn: 'alphanumeric',
            },
            {
                header: 'Label',
                accessorKey: 'Label',
                size: 800,
                muiTableHeadCellProps: { align: 'center', sx: { borderLeft: '1px solid #ccc' } },
                muiTableBodyCellProps: { sx: { borderLeft: '1px solid #ccc' } },
                sortingFn: 'alphanumeric',
            },
        ];
    }

    if (zoneMode === 'VIEW') {
        columns.push({
            header: '',
            accessorKey: 'actions',
            enableColumnFilter: false,
            enableSorting: false,
            size: 120,
            muiTableHeadCellProps: { align: 'center', sx: { borderLeft: '1px solid #ccc' } },
            muiTableBodyCellProps: { align: 'center', sx: { padding: '0 16px', borderLeft: '1px solid #ccc' } },
            Cell: ({ cell, row }) => {
                if (row.original.Id) {
                    return (
                        <div style={{ whiteSpace: 'nowrap' }}>
                            <IconButton
                                title='Zoom to'
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => props.handleZoomToNeighborhoodCluster(row.original.Id)}
                            >
                                <i className='RS_ZOOM' />
                            </IconButton>
                            <IconButton
                                title='Edit'
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => props.handleEditNeighborhoodCluster(row.original.Id)}
                            >
                                <i className='RS_EDIT' />
                            </IconButton>
                            <IconButton
                                title='Reshape'
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#002e6d', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => props.handleReshapeNeighborhoodCluster(row.original.Id)}
                            >
                                <i className='RS_RESHAPE_ZONE' />
                            </IconButton>
                            <IconButton
                                title='Delete'
                                color='primary'
                                className={'icon_button'}
                                style={{ color: '#16a8a6', marginLeft: '5px', padding: '0', fontSize: '18px' }}
                                onClick={() => props.handleDeleteNeighborhoodCluster(row.original.Id)}
                            >
                                <i className='RS_DELETE' />
                            </IconButton>
                        </div>
                    );
                }
            },
        });
    }

    React.useEffect(() => {
        if (!lock.current) {
            let selected = [];
            Object.keys(rowSelection).forEach((key, index) => {
                selected.push(Number(key));
            });
            props.handleDrawNeighborhoodClusters(selected);
        } else {
            lock.current = false;
        }
    }, [rowSelection]);

    React.useEffect(() => {
        if (zoneMode === 'VIEW') {
            if (selectedNeighborhoodClusters) {
                let selected = {};
                props.data.forEach((value) => {
                    if (selectedNeighborhoodClusters.includes(value.Id)) {
                        selected[value.Id] = true;
                    }
                });
                if (JSON.stringify(selected) != JSON.stringify(rowSelection)) {
                    lock.current = true;
                    setRowSelection(selected);
                }
            }
        }
    }, [selectedNeighborhoodClusters]);

    return (
        <div
            id='NeighborhoodsTable'
            style={{ maxWidth: '100%', padding: '5px', height: bottomHeight }}
            className={routePlanId ? (zoneMode === 'VIEW' ? 'NeighborhoodsTable-EXP' : 'NeighborhoodsTable-RDC') : 'NeighborhoodsTable-REG'}
        >
            <MaterialReactTable
                columns={columns}
                data={props.data}
                enableTopToolbar={true}
                enableStickyHeader={true}
                enableColumnFilters={false}
                enablePagination={false}
                enableBottomToolbar={false}
                enableColumnActions={false}
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableColumnOrdering={false}
                enableColumnResizing={false}
                enableRowSelection={zoneMode === 'VIEW'}
                enableHiding={false}
                selectAllMode='all'
                positionToolbarAlertBanner='none'
                initialState={{
                    density: 'compact',
                    showGlobalFilter: true,
                    sorting: [{ id: 'Name', desc: false }],
                }}
                state={{
                    rowSelection,
                    columnOrder: zoneMode === 'VIEW' ? ['mrt-row-select', columns.map((c) => c.accessorKey)] : [columns.map((c) => c.accessorKey)],
                    columnVisibility:
                        zoneMode === 'VIEW'
                            ? {
                                  'mrt-row-select': true,
                              }
                            : {
                                  'mrt-row-select': false,
                              },
                }}
                displayColumnDefOptions={{
                    'mrt-row-select': {
                        enableHiding: true,
                    },
                }}
                muiTableContainerProps={{
                    sx: { maxHeight: bottomHeight - 100 },
                }}
                onRowSelectionChange={setRowSelection}
                getRowId={(originalRow) => originalRow.Id}
                muiSelectCheckboxProps={({ row }) => ({
                    title: null,
                    disabled: zoneMode !== 'VIEW',
                })}
                muiSelectAllCheckboxProps={({ row }) => ({
                    title: null,
                    disabled: zoneMode !== 'VIEW',
                })}
                renderTopToolbarCustomActions={({ table }) => {
                    return (
                        <div id={'NeighborhoodToolbar'}>
                            <span style={{ fontSize: '18px', fontWeight: 500, lineHeight: 1.5, marginLeft: '20px' }}>Zones</span>
                            {zoneMode === 'VIEW' && (
                                <IconButton
                                    title='New'
                                    color='primary'
                                    style={{ position: 'absolute', top: 0, left: '200px', padding: 0 }}
                                    onClick={() => handleNewNeighborhood()}
                                >
                                    <i className='RS_ADD' />
                                </IconButton>
                            )}
                            {zoneMode === 'VIEW' && selectedNeighborhoodClusters.length > 0 && (
                                <IconButton
                                    title='Delete selected zones'
                                    color='primary'
                                    style={{ position: 'absolute', top: 0, left: '250px', padding: 0 }}
                                    onClick={() => handleDeleteSelectedNeighborhood()}
                                >
                                    <i className='RS_DELETE' />
                                </IconButton>
                            )}
                            {routePlanId && zoneMode === 'VIEW' && (
                                <Button
                                    variant='outlined'
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        left: '300px',
                                        padding: '0 5px',
                                        border: '1px solid #ccc',
                                        borderRadius: 5,
                                    }}
                                    onClick={() => setZoneMode('ASSIGN')}
                                >
                                    Assign
                                </Button>
                            )}
                            {routePlanId && zoneMode === 'VIEW' && (
                                <Button
                                    variant='outlined'
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        left: '400px',
                                        padding: '0 5px',
                                        border: '1px solid #ccc',
                                        borderRadius: 5,
                                    }}
                                    onClick={() => setZoneMode('SEQUENCE')}
                                >
                                    Set Priority and Sequence
                                </Button>
                            )}
                            {routePlanId && zoneMode === 'ASSIGN' && (
                                <Button
                                    variant='outlined'
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '450px',
                                        padding: '0 5px',
                                        border: '1px solid #ccc',
                                        borderRadius: 5,
                                    }}
                                    onClick={() => handleSaveAssignment('SAVE')}
                                >
                                    SAVE
                                </Button>
                            )}
                            {routePlanId && zoneMode === 'ASSIGN' && (
                                <Button
                                    variant='outlined'
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '350px',
                                        padding: '0 5px',
                                        border: '1px solid #ccc',
                                        borderRadius: 5,
                                    }}
                                    onClick={() => handleSaveAssignment('CANCEL')}
                                >
                                    EXIT
                                </Button>
                            )}
                            {routePlanId && zoneMode === 'SEQUENCE' && (
                                <Button
                                    variant='outlined'
                                    style={
                                        sequenceMode === 'FIRST'
                                            ? {
                                                  backgroundColor: 'green',
                                                  color: 'white',
                                                  position: 'absolute',
                                                  top: '5px',
                                                  left: '250px',
                                                  padding: '0 5px',
                                                  border: '1px solid #ccc',
                                                  borderRadius: 5,
                                              }
                                            : {
                                                  position: 'absolute',
                                                  top: '5px',
                                                  left: '250px',
                                                  padding: '0 5px',
                                                  border: '1px solid #ccc',
                                                  borderRadius: 5,
                                              }
                                    }
                                    onClick={() => {
                                        sequenceMode === 'FIRST' ? setSequenceMode(null) : setSequenceMode('FIRST');
                                    }}
                                >
                                    ASSIGN FIRST
                                </Button>
                            )}
                            {routePlanId && zoneMode === 'SEQUENCE' && (
                                <Button
                                    variant='outlined'
                                    style={
                                        sequenceMode === 'LAST'
                                            ? {
                                                  backgroundColor: 'red',
                                                  color: 'white',
                                                  position: 'absolute',
                                                  top: '5px',
                                                  left: '375px',
                                                  padding: '0 5px',
                                                  border: '1px solid #ccc',
                                                  borderRadius: 5,
                                              }
                                            : {
                                                  position: 'absolute',
                                                  top: '5px',
                                                  left: '375px',
                                                  padding: '0 5px',
                                                  border: '1px solid #ccc',
                                                  borderRadius: 5,
                                              }
                                    }
                                    onClick={() => {
                                        sequenceMode === 'LAST' ? setSequenceMode(null) : setSequenceMode('LAST');
                                    }}
                                >
                                    ASSIGN LAST
                                </Button>
                            )}
                            {routePlanId && zoneMode === 'SEQUENCE' && (
                                <Button
                                    variant='outlined'
                                    style={
                                        sequenceMode === 'SEQUENCE'
                                            ? {
                                                  backgroundColor: 'yellow',
                                                  position: 'absolute',
                                                  top: '5px',
                                                  left: '495px',
                                                  padding: '0 5px',
                                                  border: '1px solid #ccc',
                                                  borderRadius: 5,
                                              }
                                            : {
                                                  position: 'absolute',
                                                  top: '5px',
                                                  left: '495px',
                                                  padding: '0 5px',
                                                  border: '1px solid #ccc',
                                                  borderRadius: 5,
                                              }
                                    }
                                    onClick={() => {
                                        sequenceMode === 'SEQUENCE' ? setSequenceMode(null) : setSequenceMode('SEQUENCE');
                                    }}
                                >
                                    ASSIGN SEQUENCE
                                </Button>
                            )}
                            {routePlanId && zoneMode === 'SEQUENCE' && (
                                <Button
                                    variant='outlined'
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '450px',
                                        padding: '0 5px',
                                        border: '1px solid #ccc',
                                        borderRadius: 5,
                                    }}
                                    onClick={() => handleSaveSequence('SAVE')}
                                >
                                    SAVE
                                </Button>
                            )}
                            {routePlanId && zoneMode === 'SEQUENCE' && (
                                <Button
                                    variant='outlined'
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '350px',
                                        padding: '0 5px',
                                        border: '1px solid #ccc',
                                        borderRadius: 5,
                                    }}
                                    onClick={() => handleSaveSequence('CANCEL')}
                                >
                                    EXIT
                                </Button>
                            )}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export const NeighborhoodClusterDialog = (props) => {
    return (
        <Dialog
            open={props.openNeighborhoodClusterDialog}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            autoFocus
                            margin='dense'
                            id='Name'
                            name='Name'
                            label='Name'
                            type='text'
                            fullWidth
                            variant='standard'
                            value={props.targetNeighborhoodData.Name}
                            onChange={props.updateNeighborhoodForm}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <TextField
                            margin='dense'
                            id='Label'
                            name='Label'
                            label='Label'
                            type='text'
                            fullWidth
                            variant='standard'
                            value={props.targetNeighborhoodData.Label}
                            onChange={props.updateNeighborhoodForm}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => props.handleNeighborhoodClusterDialogClose('CANCEL')}
                >
                    Cancel
                </Button>

                {
                    <Button
                        style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                        onClick={() => props.handleNeighborhoodClusterDialogClose('SAVE')}
                    >
                        Save
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export const ReshapeNeighborhoodClusterDialog = (props) => {
    return (
        <div>
            <Dialog
                aria-labelledby='customized-dialog-title'
                fullWidth={true}
                maxWidth={'xs'}
                scroll={'paper'}
                open={props.reshapeDialogOpen}
            >
                <DialogTitle id='customized-dialog-title'>RESHAPE ZONE</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to reshape this zone?
                        <br />
                    </Typography>
                </DialogContent>
                <DialogActions style={{ textAlign: 'center', display: 'block' }}>
                    <Button
                        onClick={() => props.handleReshapeClose('SAVE')}
                        color='primary'
                        style={{ backgroundColor: '#16a8a6', color: '#fff', width: '100px' }}
                    >
                        Yes
                        <i
                            className='material-icons'
                            style={{ marginLeft: '10px' }}
                        >
                            check_circle
                        </i>
                    </Button>
                    <Button
                        onClick={() => props.handleReshapeClose('BACK')}
                        color='primary'
                        style={{ backgroundColor: '#ee8a1d', color: '#fff', width: '100px' }}
                    >
                        Back
                        <i
                            className='material-icons'
                            style={{ marginLeft: '10px' }}
                        >
                            highlight_off
                        </i>
                    </Button>
                    <Button
                        onClick={() => props.handleReshapeClose('CANCEL')}
                        color='primary'
                        style={{ backgroundColor: '#ee8a1d', color: '#fff', width: '100px' }}
                    >
                        Cancel
                        <i
                            className='material-icons'
                            style={{ marginLeft: '10px' }}
                        >
                            highlight_off
                        </i>
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export const DeleteNeighborhoodClusterDialog = (props) => {
    const { targetNeighborhoodData, selectedNeighborhoodClusters, openDeleteNeighborhoodClusterDialog } = props;
    return (
        <Dialog
            open={openDeleteNeighborhoodClusterDialog}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>Delete Zone</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {targetNeighborhoodData && targetNeighborhoodData.Name && (
                        <span>
                            Are you sure you want to delete zone: <b>{targetNeighborhoodData.Name}</b> ?
                        </span>
                    )}
                    {!targetNeighborhoodData.Name && selectedNeighborhoodClusters && selectedNeighborhoodClusters.length > 0 && (
                        <span>
                            Are you sure you want to delete <b>{selectedNeighborhoodClusters.length}</b> zone(s) ?
                        </span>
                    )}
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                    style={{ backgroundColor: '#ee8a1d', color: '#fff' }}
                    onClick={() => props.handleDeleteClose('CANCEL')}
                >
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#16a8a6', color: '#fff' }}
                    onClick={() => props.handleDeleteClose('DELETE')}
                >
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const WarningDialog = (props) => {
    return (
        <div>
            <Dialog
                aria-labelledby='customized-dialog-title'
                fullWidth={true}
                maxWidth={'sm'}
                scroll={'paper'}
                open={props.warningDialogOpen}
            >
                <DialogTitle id='customized-dialog-title'>WARNING</DialogTitle>
                <DialogContent>
                    <Typography>Changes you made will not be saved. Are you sure you wish to discard the changes?</Typography>
                </DialogContent>
                <DialogActions style={{ textAlign: 'center', display: 'block' }}>
                    <Button
                        onClick={() => props.handleStay()}
                        color='primary'
                        style={{ backgroundColor: '#16a8a6', color: '#fff', width: '100px' }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => props.handleLeave()}
                        color='primary'
                        style={{ backgroundColor: '#ee8a1d', color: '#fff', width: '100px' }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
