import React from 'react';
import { SplitRoutes } from './SplitRoutes';
import { CreateRoutes } from './CreateRoutes';
import { SavedSceranio } from './SavedScenario';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export const DispatchViewTab = (props) => {
    const {
        workspace,
        selDispatchTool,
        serviceDayList,
        splitFormData,
        splitSeqList,
        dispatchFacilityList,
        splitLoadIndicatorVisible,
        splitStartTime,
        defSplitFormData,
        updateSplitFormData,
        setSplitFormData,
        setSplitLoadIndicatorVisible,
        createRoutesFormData,
        routePlanList,
        createRoutesLoadIndicatorVisible,
        updateCreateRoutesFormData,
        setCreateRoutesLoadIndicatorVisible,
        scenarioList,
        selectedScenarioId,
        selectedScenarioRoutes,
        scenarioRouteList,
        showScenarioSequenceLabel,
        showScenarioTravelPathLabel,
        bottomPaneGrid,
        bottomPaneVisibility,
        showScenarioReport,
        showScenarioExport,
        showScenarioTravelPathLabelBtnTooltip,
        travelPathLabelTooltip,
        selScenarioTravelPathRoute,
        selScenarioSequenceRoute,
        setShowScenarioTravelPathLabel,
        selScenarioMapToolChangeHandler,
        setSelectedScenarioId,
        setSelectedScenarioRoutes,
        setShowScenarioSequenceLabel,
        refreshScenarioList,
        setSelDispatchTool,
        setSplitStartTime,
        refreshSplitSeqList,
        setOpenMapAlert,
        setMapAlertTitle,
        setMapAlertMessage,
        toggleScenarioTravelPathLayer,
        toggleScenarioSequenceLayer,
        numberCreating,
        routeNumberList,
        handleZoomToScenarioRoute,
        handleZoomToSelectedScenarioRoutes,
    } = props;

    return (
        <div
            id='dispatchPane'
            style={{ width: '100%' }}
        >
            <ToggleButtonGroup
                style={{ paddingTop: '5px' }}
                exclusive
                value={selDispatchTool}
                onChange={() => setSelDispatchTool('SplitRoute')}
            >
                <ToggleButton
                    value='SplitRoute'
                    title='Split Route'
                    style={
                        selDispatchTool === 'SplitRoute'
                            ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 18, padding: '5px' }
                            : { fontSize: 18, padding: '5px', filter: 'grayscale(1)' }
                    }
                >
                    <span className='RS_SPLIT' />
                </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
                style={{ paddingTop: '5px' }}
                exclusive
                value={selDispatchTool}
                onChange={() => setSelDispatchTool('CreateRoutes')}
            >
                <ToggleButton
                    value='CreateRoutes'
                    title='Create Routes'
                    style={
                        selDispatchTool === 'CreateRoutes'
                            ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 18, padding: '5px' }
                            : { fontSize: 18, padding: '5px', filter: 'grayscale(1)' }
                    }
                >
                    <span className='RS_ABSORB' />
                </ToggleButton>
            </ToggleButtonGroup>
            <ToggleButtonGroup
                style={{ float: 'right', paddingTop: '5px' }}
                exclusive
                value={selDispatchTool}
                onChange={() => setSelDispatchTool('SavedScenarios')}
            >
                <ToggleButton
                    value='SavedScenarios'
                    title='Saved Scenarios'
                    style={
                        selDispatchTool == 'SavedScenarios'
                            ? { backgroundColor: '#e6e6e6', color: '#f77c2b', fontSize: 18, padding: '5px' }
                            : { fontSize: 18, padding: '5px', filter: 'grayscale(1)' }
                    }
                >
                    <span className='RS_DISPATCH_SCENARIO' />
                </ToggleButton>
            </ToggleButtonGroup>
            {selDispatchTool === 'SplitRoute' && (
                <SplitRoutes
                    workspace={workspace}
                    serviceDayList={serviceDayList}
                    splitFormData={splitFormData}
                    splitSeqList={splitSeqList}
                    dispatchFacilityList={dispatchFacilityList}
                    splitLoadIndicatorVisible={splitLoadIndicatorVisible}
                    splitStartTime={splitStartTime}
                    defSplitFormData={defSplitFormData}
                    updateSplitFormData={updateSplitFormData}
                    setSplitFormData={setSplitFormData}
                    setSplitLoadIndicatorVisible={setSplitLoadIndicatorVisible}
                    refreshScenarioList={refreshScenarioList}
                    setSelDispatchTool={setSelDispatchTool}
                    setSplitStartTime={setSplitStartTime}
                    refreshSplitSeqList={refreshSplitSeqList}
                    setOpenMapAlert={setOpenMapAlert}
                    setMapAlertTitle={setMapAlertTitle}
                    setMapAlertMessage={setMapAlertMessage}
                />
            )}
            {selDispatchTool === 'CreateRoutes' && (
                <CreateRoutes
                    workspace={workspace}
                    serviceDayList={serviceDayList}
                    createRoutesFormData={createRoutesFormData}
                    routePlanList={routePlanList}
                    createRoutesLoadIndicatorVisible={createRoutesLoadIndicatorVisible}
                    updateCreateRoutesFormData={updateCreateRoutesFormData}
                    setCreateRoutesLoadIndicatorVisible={setCreateRoutesLoadIndicatorVisible}
                    refreshScenarioList={refreshScenarioList}
                    setSelDispatchTool={setSelDispatchTool}
                    setOpenMapAlert={setOpenMapAlert}
                    setMapAlertTitle={setMapAlertTitle}
                    setMapAlertMessage={setMapAlertMessage}
                    routeNumberList={routeNumberList}
                    numberCreating={numberCreating}
                />
            )}
            {selDispatchTool === 'SavedScenarios' && (
                <SavedSceranio
                    scenarioList={scenarioList}
                    selectedScenarioId={selectedScenarioId}
                    selectedScenarioRoutes={selectedScenarioRoutes}
                    scenarioRouteList={scenarioRouteList}
                    showScenarioSequenceLabel={showScenarioSequenceLabel}
                    showScenarioTravelPathLabel={showScenarioTravelPathLabel}
                    bottomPaneGrid={bottomPaneGrid}
                    bottomPaneVisibility={bottomPaneVisibility}
                    showScenarioReport={showScenarioReport}
                    showScenarioExport={showScenarioExport}
                    showScenarioTravelPathLabelBtnTooltip={showScenarioTravelPathLabelBtnTooltip}
                    travelPathLabelTooltip={travelPathLabelTooltip}
                    selScenarioTravelPathRoute={selScenarioTravelPathRoute}
                    selScenarioSequenceRoute={selScenarioSequenceRoute}
                    setShowScenarioTravelPathLabel={setShowScenarioTravelPathLabel}
                    selScenarioMapToolChangeHandler={selScenarioMapToolChangeHandler}
                    setSelectedScenarioId={setSelectedScenarioId}
                    setSelectedScenarioRoutes={setSelectedScenarioRoutes}
                    setShowScenarioSequenceLabel={setShowScenarioSequenceLabel}
                    toggleScenarioTravelPathLayer={toggleScenarioTravelPathLayer}
                    toggleScenarioSequenceLayer={toggleScenarioSequenceLayer}
                    handleZoomToScenarioRoute={handleZoomToScenarioRoute}
                    handleZoomToSelectedScenarioRoutes={handleZoomToSelectedScenarioRoutes}
                />
            )}
        </div>
    );
};
