import React from 'react';
import { postDispatch } from '../../_actions/MapPage.actions';
import { SelectBox } from 'devextreme-react';
import {
    Grid,
    FormControlLabel,
    Button,
    Typography,
    CircularProgress,
    Box,
    Radio,
    RadioGroup as MuiRadioGroup,
    Switch as MuiSwitch,
} from '@mui/material';

export const CreateRoutes = (props) => {
    const {
        workspace,
        serviceDayList,
        createRoutesFormData,
        routePlanList,
        createRoutesLoadIndicatorVisible,
        updateCreateRoutesFormData,
        setCreateRoutesLoadIndicatorVisible,
        refreshScenarioList,
        setSelDispatchTool,
        setOpenMapAlert,
        setMapAlertTitle,
        setMapAlertMessage,
        routeNumberList,
        numberCreating,
    } = props;

    const handleCreateRoutes = () => {
        if (!createRoutesLoadIndicatorVisible) {
            setCreateRoutesLoadIndicatorVisible(true);
            createRoutesFormData.WorkspaceID = workspace.value;
            postDispatch('Create', createRoutesFormData).then(
                (res) => {
                    if (res.status === 200) {
                        setCreateRoutesLoadIndicatorVisible(false);
                        refreshScenarioList(true);
                        setSelDispatchTool('SavedScenarios');
                    } else {
                        setCreateRoutesLoadIndicatorVisible(false);
                    }
                },
                (err) => {
                    console.log(err);
                    setCreateRoutesLoadIndicatorVisible(false);
                    setOpenMapAlert(true);
                    setMapAlertTitle('Error');
                    setMapAlertMessage(`${JSON.stringify(err.response.data)}`);
                },
            );
        }
    };

    const checkRouteCount = () => {
        const plan = routePlanList.find((s) => s.value === createRoutesFormData.RoutePlan);
        if (plan) {
            if (plan.RouteCount < 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    return (
        <div style={{ maxWidth: '100%', paddingTop: '5px', height: '278px' }}>
            <Grid
                container
                spacing={'5px'}
                style={{ paddingTop: '5px' }}
            >
                <Grid
                    item
                    xs={4}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Service Day:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <SelectBox
                        dataSource={serviceDayList}
                        disabled={true}
                        searchEnabled={true}
                        searchMode={'contains'}
                        placeholder='Select service day...'
                        displayExpr='label'
                        valueExpr='value'
                        width='186px'
                        value={createRoutesFormData.ServiceDay}
                        onValueChanged={(e) => {
                            updateCreateRoutesFormData('ServiceDay', e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                >
                    <Typography
                        variant='body1'
                        gutterBottom
                        sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '12px!important' }}
                    >
                        Route Plan:{' '}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <SelectBox
                        dataSource={routePlanList}
                        disabled={false}
                        searchEnabled={true}
                        searchMode={'contains'}
                        placeholder='Select route plan ...'
                        displayExpr='label'
                        valueExpr='value'
                        width='186px'
                        value={createRoutesFormData.RoutePlan}
                        onValueChanged={(e) => {
                            updateCreateRoutesFormData('RoutePlan', e.value);
                        }}
                    />
                </Grid>
                <Grid
                    item
                    xs={7}
                >
                    <span style={{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.54)', fontFamily: '"Roboto","Helvetica","Arial",sans-serif' }}>
                        Method:
                    </span>
                    <MuiRadioGroup
                        name='method'
                        value={createRoutesFormData.GenerateMethod}
                        onChange={(e) => updateCreateRoutesFormData('GenerateMethod', e.target.value)}
                        style={{ marginLeft: '30px', marginTop: '10px' }}
                    >
                        <FormControlLabel
                            value='Time'
                            sx={{ mb: '5px' }}
                            control={<Radio style={{ fontSize: 10, padding: 0 }} />}
                            label={<span style={{ fontSize: 12, marginLeft: '5px' }}>Route Time</span>}
                        />
                        <FormControlLabel
                            value='Number'
                            sx={{ mb: '5px' }}
                            control={<Radio style={{ fontSize: 10, padding: 0 }} />}
                            label={<span style={{ fontSize: 12, marginLeft: '5px' }}>Number</span>}
                        />
                        <FormControlLabel
                            value='Full'
                            sx={{ mb: '5px' }}
                            control={
                                <Radio
                                    disabled={checkRouteCount()}
                                    style={{ fontSize: 10, padding: 0 }}
                                />
                            }
                            label={<span style={{ fontSize: 12, marginLeft: '5px' }}>Full Fleet</span>}
                        />
                    </MuiRadioGroup>
                </Grid>

                <Grid
                    item
                    xs={5}
                >
                    <FormControlLabel
                        style={{ height: '20px', marginTop: '28px', marginBottom: '8px' }}
                        disabled={createRoutesFormData.GenerateMethod === 'Number' || checkRouteCount()}
                        checked={createRoutesFormData.Zones === 'Off' ? false : true}
                        control={<MuiSwitch color='primary' />}
                        label={<span style={{ fontSize: 12 }}>Zones</span>}
                        labelPlacement='start'
                        onChange={(e) => updateCreateRoutesFormData('Zones', e.target.checked)}
                    />
                    {createRoutesFormData.GenerateMethod === 'Number' && (
                        <SelectBox
                            dataSource={routeNumberList}
                            searchEnabled={true}
                            searchMode={'contains'}
                            displayExpr='label'
                            valueExpr='value'
                            width='100px'
                            value={createRoutesFormData.NumRoutes}
                            acceptCustomValue={true}
                            onCustomItemCreating={numberCreating}
                            onValueChanged={(e) => {
                                updateCreateRoutesFormData('NumRoutes', e.value);
                            }}
                        />
                    )}
                </Grid>

                <Grid
                    item
                    xs={12}
                    style={{ textAlign: 'center', marginTop: '10px' }}
                >
                    <Box sx={{ m: 1, position: 'relative' }}>
                        <Button
                            variant='contained'
                            style={createRoutesLoadIndicatorVisible ? { backgroundColor: 'eee' } : { backgroundColor: '#f77c2b' }}
                            disabled={createRoutesLoadIndicatorVisible}
                            onClick={() => handleCreateRoutes()}
                        >
                            Create
                        </Button>
                        {createRoutesLoadIndicatorVisible && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};
