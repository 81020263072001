import React from 'react';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import TextArea from 'devextreme-react/text-area';
import TextBox from 'devextreme-react/text-box';
import { RODialog, RODialogLabel, ROOrangeButton, ROGreenButton } from '../../../common';

const AddEditGroups = (props) => {
    return (
        <RODialog
            maxWidth='xs'
            fullWidth={true}
            open={props.open}
            dialogTitle={props.title}
            dialogContent={
                <DialogContent>
                    <Grid
                        container
                        spacing={'10px'}
                        style={{ paddingTop: '10px' }}
                    >
                        <Grid
                            item
                            xs={12}
                        >
                            <RODialogLabel>Group Name *</RODialogLabel>
                            <TextBox
                                name='Name'
                                inputAttr={{ autocomplete: 'new-name' }}
                                defaultValue={props.groupTarget.Name}
                                onValueChanged={(e) => {
                                    props.handleChange('Name', e.value);
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <RODialogLabel>Description</RODialogLabel>
                            <TextArea
                                name='Description'
                                height={60}
                                defaultValue={props.groupTarget.Description}
                                onValueChanged={(e) => {
                                    props.handleChange('Description', e.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            }
            dialogActions={
                <DialogActions style={{ justifyContent: 'right' }}>
                    {props.groupTarget.Name && (
                        <ROGreenButton
                            onClick={() => {
                                props.handleClick('SAVE');
                            }}
                        >
                            {' '}
                            Save
                        </ROGreenButton>
                    )}
                    <ROOrangeButton
                        onClick={() => {
                            props.handleClick('CANCEL');
                        }}
                    >
                        Cancel
                    </ROOrangeButton>
                </DialogActions>
            }
        />
    );
};

export default AddEditGroups;
